.desktopNav {
	position: fixed;
	left: 0;
	top: 100%;
	width: 100vh;
	transform: rotate(-90deg);
	transform-origin: top left;
	z-index: 10;
}

.desktopNav .overlay {
	position: absolute;
	z-index: 1;
	width: 100%;
	height: 100%;
	box-shadow: inset 0 0 15px lightgray;
}

.desktopNav ul {
	position: relative;
	display: inline-flex;
	width: 100%;
	height: 47px;
	flex-direction: row-reverse;
	justify-content: center;
	margin: 0;
	padding: 0;
}

.desktopNav li {
	/* display: flex; */
	position: relative;
	z-index: 2;
	list-style-type: none;
	box-shadow: 0px -12px 12px lightgray;
	border-top-left-radius: 12px;
	border-top-right-radius: 12px;
	margin: 0px 3px;
	transition: 0.25s ease-in-out;
}

.desktopNav a {
	text-decoration: none;
	display: block;
	outline: none;
}

.desktopNav a:focus {
	outline: none;
}

.desktopNav a:focus > .tab {
	outline: 2px solid var(--orange);
}

.desktopNav .tab {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 225px;
	height: 50px;
	outline: none;
}

.desktopNav .icon {
	transform: rotate(90deg);
	margin-right: 20px;
	width: 20px;
	height: 20px;
}

.desktopNav .linkText {
	font-family: 'roboto', 'Helvetica', sans-serif;
	font-stretch: condensed;
	font-size: 15px;
	color: var(--light-grey);
}

.desktopNav li:hover {
	z-index: 10;
	background: white;
	transition: 0.25s ease-in-out;
}

.desktopNav li:hover .linkText {
	color: var(--medium-grey);
}

.desktopNav .selected {
	background: white;
	border-top-left-radius: 12px;
	border-top-right-radius: 12px;
}

.desktopNav .underline {
	display: none;
	position: absolute;
	transform: rotate(90deg);
	/* top: 10%; */
	top: 0%;
	transition: 0.25s ease-in-out;
	/* width: 90px; */
	height: 90px;
}

.desktopNav .selected .underline {
	display: block;
}

.desktopNav .selected .linkText {
	color: var(--medium-grey);
}

@media screen and (max-height: 950px) {
	.desktopNav .tab {
		width: 175px;
	}
}

@media screen and (max-width: 575px) {
	.desktopNav {
		display: none;
	}
}
