.itemDetailsOuter {
	position: relative;
	margin: 0 65px;
}

.itemDetailsOuter .heading {
	display: flex;
	flex-direction: column;
	position: absolute;
	top: -24px;
}

.itemDetailsOuter .heading h1 {
	font-family: 'caviarDreams', 'Arial', sans-serif;
	font-weight: bold;
	font-size: clamp(15px, 0.994vw, 20px);
	color: #767676;
	letter-spacing: -0.61px;
	text-transform: uppercase;
	margin-bottom: 25px;
}

.itemViewButtons {
	display: flex;
	/* margin-bottom: 40px; */
}

.itemViewButtons button {
	padding: 10px 20px;
	color: #646464;
	font-family: 'bradHITC', cursive;
	font-size: clamp(15px, 1.04vw, 20px);
	font-weight: bold;
	letter-spacing: -0.32px;
	text-transform: uppercase;
	border: 2px solid transparent;
	margin-right: 30px;
}

.itemViewButtons button:focus,
.itemViewButtons button.selected {
	box-sizing: border-box;
	border: 2px dashed #202020;
	border-radius: 4px;
}

.itemViewButtons button img {
	display: inline-block;
	vertical-align: middle;
}

.itemViewButtons button img.mobileIcon {
	height: 24px;
}
.itemViewButtons button img.desktopIcon {
	height: 21px;
}

.itemViewButtons p {
	display: inline;
	padding-left: 20px;
}

.itemDetailsInner {
	display: flex;
	justify-content: space-evenly;
	position: inherit;
	top: 115px;
	padding-bottom: 30px;
	padding-right: 25px;
	max-height: 800px;
}

.itemDetailsInner.mobile {
	margin-bottom: 55px;
}

.itemDetailsInner .content {
	position: relative;
	top: 0px;
	width: 40%;
	margin-right: 5%;
	font-family: 'caviarDreams', 'Arial', sans-serif;
	font-weight: bold;
	font-size: clamp(15px, 0.994vw, 20px);
	color: #767676;
	letter-spacing: -0.61px;
}

.itemDetailsOuter .heading h1,
.itemDetailsInner h2 {
	color: #767676;
	font-family: 'roboto', 'Helvetica', sans-serif;
	font-weight: bold;
	font-size: clamp(15px, 1.46vw, 28px);
	letter-spacing: -0.91px;
}

.itemDetailsInner h2 {
	font-size: clamp(20px, 2.24vw, 43px);
	letter-spacing: -1.39px;
	margin-top: 20px;
	margin-bottom: 20px;
}

.itemDetailsInner .content > h2 {
	display: block;
}

.itemDetailsInner .description {
	margin-left: 80px;
	margin-bottom: 80px;
}

.itemDetailsInner .description .text {
	margin: 35px 0 25px 0;
	max-height: 225px;
	min-height: 150px;
	height: 210px;
	overflow-y: auto;
	padding-right: 40px;
}

.itemDetailsInner .description .mobileH2 {
	display: none;
}

.itemDetailsInner .description .text .packageDetails {
	display: none;
	margin-bottom: 25px;
}

.itemDetailsInner .description .packageDetails {
	display: block;
}

.itemDetailsInner .description p {
	font-family: 'caviarDreams', 'Arial', sans-serif;
	font-weight: normal;
	line-height: 26px;
}

.itemDetailsInner .packageDetails div {
	margin-bottom: 15px;
}

.itemDetailsInner .consultationBtn {
	display: flex;
	justify-content: center;
}

.itemDetailsInner .consultationBtn > button {
	height: 55px;
	max-width: 380px;
	min-width: 150px;
	width: 19.8vw;
	border-radius: 3px;
	background-color: #e4a500;
	font-family: 'roboto', 'Helvetica', sans-serif;
	font-size: clamp(15px, 1.04vw, 20px);
	color: #ffffff;
	letter-spacing: -0.33px;
}

.itemDetailsInner .imgCarouselDesktop {
	position: relative;
	top: -75px;
	width: 55%;
}

.itemDetailsInner .imgCarouselMobile {
	position: relative;
	top: -165px;
	right: -60px;
	width: 55%;
	/* margin-right: 40px; */
}

@media all and (min-width: 1920px) {
	.itemDetailsOuter {
		max-width: 1800px;
	}
}

@media all and (max-width: 1820px) {
	.itemDetailsInner .description .text {
		height: 200px;
	}
}

@media all and (max-width: 1750px) {
	.itemDetailsInner .description .text {
		height: 187px;
	}
}

@media all and (max-width: 1700px) {
	.itemDetailsInner .description .text {
		height: 180px;
	}
}

@media all and (max-width: 1650px) {
	.itemDetailsInner .description {
		margin-bottom: 70px;
	}
}

@media all and (max-width: 1600px) {
	.itemDetailsInner .description {
		margin-left: 50px;
		margin-bottom: 60px;
	}
}

@media all and (max-width: 1550px) {
	.itemDetailsInner .description .text {
		margin: 25px 0 25px 0;
	}
}

@media all and (max-width: 1500px) {
	.itemDetailsInner .description .text {
		margin: 50px 0 70px 0;
		height: 210px;
	}

	.itemDetailsInner .description .text .packageDetails {
		display: block;
	}

	.itemDetailsInner .description .packageDetails {
		display: none;
	}
}

@media all and (max-width: 1450px) {
	.itemDetailsInner .description .text {
		margin: 48px 0 62px 0;
	}
}

@media all and (max-width: 1420px) {
	.itemDetailsInner {
		top: 95px;
	}

	.itemDetailsInner .imgCarouselDesktop {
		top: -55px;
	}

	.itemDetailsInner .description .text {
		margin-bottom: 55px;
	}
}

@media all and (max-width: 1400px) {
	.itemDetailsInner .description {
		margin-bottom: 50px;
	}
	.itemDetailsInner .description .text {
		margin-bottom: 40px;
	}
}

@media all and (max-width: 1370px) {
	.itemViewButtons button {
		margin-right: 20px;
	}

	.itemViewButtons p {
		padding-left: 12px;
	}

	.itemDetailsInner .description {
		margin-bottom: 50px;
	}
}

@media all and (max-width: 1320px) {
	.itemDetailsInner .description .text {
		margin-top: 35px;
	}
}

@media all and (max-width: 1300px) {
	.itemDetailsInner .description {
		margin-bottom: 45px;
	}

	.itemDetailsInner .description .text {
		margin-top: 30px;
	}
}

@media all and (max-width: 1279px) {
	/* resize to tablet view */
	.itemDetailsOuter {
		margin: auto;
		max-width: 854px;
	}

	.itemDetailsOuter .heading {
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		position: static;
		width: 100%;
		margin: 20px 0 40px;
	}

	.itemDetailsOuter.mobile .heading {
		margin: 20px 0 25px;
	}

	.itemDetailsOuter .heading h1 {
		margin: 0;
		font-size: 20px;
		/* font-size: clamp(20px, 2vw, 20px); */
	}

	.itemViewButtons button {
		padding: 10px 10px;
		font-size: 17px;
		border: none;
		border-bottom: 1px dashed transparent;
	}

	.itemViewButtons button:last-of-type {
		margin-right: 0;
	}

	.itemViewButtons button:focus,
	.itemViewButtons button.selected {
		border: none;
		border-bottom: 1px dashed #202020;
		border-radius: 0;
	}

	.itemDetailsInner {
		/* top: 230px; */
		flex-direction: column-reverse;
		justify-content: flex-end;
		align-items: center;
		position: static;
		margin-top: 20px;
		max-height: none;
		padding: 0;
	}

	.itemDetailsInner.mobile {
		flex-direction: row;
		margin-top: 35px;
		/* margin-bottom: 0; */
	}

	.itemDetailsInner .content {
		width: 100%;
		margin-right: auto;
		margin-left: auto;
		text-align: center;
		margin: 40px 0;
	}

	.itemDetailsInner.mobile .content {
		width: 45%;
		margin: 0;
		margin-right: 10vw;
	}

	.itemDetailsInner .content > h2 {
		display: none;
	}

	.itemDetailsInner .description {
		margin: 0;
	}

	.itemDetailsInner.mobile .description {
		text-align: left;
	}

	.itemDetailsInner .description .text {
		padding: 0;
	}

	.itemDetailsInner .description .mobileH2 {
		display: inline;
	}

	.itemDetailsInner h2 {
		font-size: 35px;
		/* font-size: clamp(30px, 3vw, 43px); */
	}

	.itemDetailsInner .description .text .packageDetails {
		font-size: 17px;
	}

	/* .itemDetailsInner .description .text .packageDetails {
		width: 30%;
		margin: auto;
		text-align: left;
	}

	.itemDetailsInner .description .text .packageDetails div {
		display: flex;
		justify-content: space-between;
		align-items: center;
		text-align: left;
	} */

	.itemDetailsInner .description .text {
		height: auto;
		max-height: none;
		min-height: none;
		overflow-y: unset;
	}

	.itemDetailsInner .description p {
		text-align: left;
		font-size: 16px;
	}

	.itemDetailsInner .consultationBtn {
		margin-bottom: 25px;
	}

	.itemDetailsInner .consultationBtn > button {
		height: 50px;
		max-width: none;
		min-width: 275px;
		width: 600px;
	}

	.itemDetailsInner .imgCarouselDesktop {
		width: 100%;
		position: static;
	}

	.itemDetailsInner .imgCarouselMobile {
		top: 0;
		right: 0;
	}

	/* .consultationBtnMobile {
		display: flex;
		justify-content: center;
	}

	.consultationBtnMobile > button {
		height: 55px;
		max-width: 380px;
		min-width: 150px;
		width: 19.8vw;
		border-radius: 3px;
		background-color: #e4a500;
		font-family: 'roboto', 'Helvetica', sans-serif;
		font-size: clamp(15px, 1.04vw, 20px);
		color: #ffffff;
		letter-spacing: -0.33px;
	} */
}

@media all and (max-width: 950px) {
	.itemDetailsOuter {
		margin: 0 25px;
	}

	.itemDetailsOuter .heading h1 {
		font-size: clamp(16px, 2.56vw, 20px);
	}

	.itemViewButtons button {
		font-size: clamp(14px, 2vw, 17px);
	}

	.itemDetailsInner h2 {
		font-size: clamp(22px, 3.69vw, 43px);
	}

	.itemDetailsInner .description .text .packageDetails {
		font-size: clamp(16px, 1.8vw, 17px);
	}

	.itemDetailsInner .description p {
		font-size: clamp(15px, 1.8vw, 16px);
	}

	.itemDetailsInner .consultationBtn > button {
		width: clamp(275px, 60vw, 600px);
	}
}

@media all and (max-width: 890px) {
	.itemDetailsInner.mobile .content {
		margin-right: 8vw;
	}
}

@media all and (max-width: 750px) {
	.itemDetailsInner.mobile .content {
		margin-right: 25px;
	}
}

@media all and (max-width: 700px) {
	.itemViewButtons button:first-of-type {
		margin-right: 5px;
	}
}

@media all and (max-width: 685px) {
	.itemDetailsInner.mobile {
		flex-direction: column-reverse;
	}

	.itemDetailsInner.mobile .content {
		margin: 0;
		margin-top: 65px;
		width: 90%;
	}

	.itemDetailsInner .content > h2 {
		display: block;
	}

	.itemDetailsInner.mobile .description {
		text-align: center;
	}

	.itemDetailsInner .description .mobileH2 {
		display: none;
	}

	.itemDetailsInner .description .text {
		margin-bottom: 30px;
	}

	.itemDetailsInner .description .text .packageDetails {
		display: none;
	}

	.itemDetailsInner .description .packageDetails {
		display: block;
		margin-bottom: 30px;
	}

	.itemDetailsInner .consultationBtn > button {
		width: clamp(275px, 83vw, 600px);
	}

	.itemDetailsInner .imgCarouselMobile {
		width: 100%;
	}
}

@media all and (max-width: 575px) {
	.itemDetailsOuter {
		margin: 100px 25px 70px;
	}

	.itemDetailsOuter .heading {
		margin-bottom: 20px;
	}

	.itemDetailsOuter .heading h1 {
		text-transform: capitalize;
		color: #242424;
	}

	.itemViewButtons p {
		display: none;
	}

	.itemViewButtons button {
		color: var(--light-grey);
	}

	.itemViewButtons button:focus,
	.itemViewButtons button.selected {
		border: none;
	}

	.itemViewButtons button:first-of-type {
		margin-right: 0;
	}

	.itemViewButtons button:first-of-type::after {
		content: '•';
		margin-left: 20px;
	}

	.itemDetailsInner .content {
		margin-bottom: 10px;
	}

	.itemDetailsInner .description p {
		text-align: center;
	}

	.itemDetailsInner .consultationBtn button {
		min-width: 271px;
	}
}

@media all and (max-width: 350px) {
	.itemDetailsOuter .heading h1 {
		font-size: 16px;
	}

	.itemViewButtons button:first-of-type {
		padding: 5px;
	}

	.itemViewButtons button:first-of-type::after {
		margin-left: 15px;
	}

	.itemViewButtons button img.mobileIcon {
		height: 20px;
	}

	.itemViewButtons button img.desktopIcon {
		height: 18px;
	}
}
