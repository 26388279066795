.quoteTool {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 0 30px;
	flex-grow: 1;
	width: calc(100% - 60px);
}

.hidden {
	display: none !important;
}

.nextButton {
	display: flex;
	justify-content: center;
	align-items: center;
	max-width: 550px;
	width: 100%;
	margin-top: auto;
	padding: 30px 0;
	border-top: 2px solid var(--light-divider-grey);
}

.next {
	font-size: 23px;
	font-family: 'bradHITC', cursive;
	color: var(--dark);
	font-weight: bold;
	line-height: 20px;
	max-width: 200px;
}

.requestConsultation {
	font-size: 23px;
	font-family: 'bradHITC', cursive;
	color: var(--dark);
	font-weight: bold;
	line-height: 20px;
	padding: 20px;
}

/* Category Selections */

.categories {
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	width: 100%;
	max-width: 550px;
	padding-bottom: 16px;
	border-bottom: 2px solid var(--light-divider-grey);
	margin: 36px 0 0;
}

.products {
	display: flex;
	/* justify-content: space-evenly; */
	align-items: center;
	width: 100%;
	max-width: 550px;
	overflow-x: scroll;
	padding-bottom: 16px;
	border-bottom: 2px solid var(--light-divider-grey);
	margin: 36px 0 0;
}

.categories .hiddenRadioSelection input[type='radio'],
.products .hiddenRadioSelection input[type='radio'] {
	display: none;
}

.categories .hiddenRadioSelection label,
.products .hiddenRadioSelection label {
	font-family: 'bradHITC', cursive;
	color: var(--medium-grey);
	cursor: pointer;
	font-size: 22px;
}

.categories .hiddenRadioSelection input[type='radio']:checked + label,
.products .hiddenRadioSelection input[type='radio']:checked + label {
	color: var(--orange);
}

.products .hiddenRadioSelection {
	margin: 0 30px;
	white-space: nowrap;
}

.categories .dotSeparator,
.products .dotSeparator {
	width: 3px;
	min-width: 3px;
	height: 3px;
	max-height: 3px;
	border-radius: 50%;
	background-color: var(--dark-grey);
}

/* Category Descriptions */

.descriptions {
	display: flex;
	flex-grow: 1;
	align-items: center;
	min-height: 60vh;
	max-width: 550px;
	padding: 0;
	font-family: 'Handlee';
	color: var(--medium-grey);
}

.descriptions .detailedContent {
	text-align: left;
	font-size: 22px;
}

.descriptions .description {
	position: relative;
	padding: 40px 0;
}

.detailedContent .title {
	display: inline;
	padding-right: 30px;
	font-size: 40px;
	vertical-align: bottom;
	color: var(--dark-grey);
}

.detailedContent .details {
	display: inline;
	font-size: 18px;
	letter-spacing: 1px;
	color: #787878;
}

.separator {
	padding: 0px 20px;
	display: none;
}

.separator + span {
	display: block;
}

.highlighted {
	color: var(--orange);
}

.priceRange {
	margin-top: 25px;
	font-size: 18px;
	letter-spacing: 1px;
}

.priceRange .highlighted {
	white-space: nowrap;
}

.basic {
	display: block;
}

.plus,
.premium {
	/* display: none; */
}

.noSelection {
	font-family: inherit;
	font-size: 18px;
	width: 100%;
}

/* Products and selections */

.productsAndSelections {
	display: flex;
	flex-grow: 1;
	width: 100%;
	max-width: 550px;
}

.productsAndSelections .backButton {
	position: fixed;
	top: 0;
	left: 75px;
	width: 100px;
}

.backButton .backButtonImage {
	position: relative;
	width: 100%;
	z-index: 1;
}

.backButton .backButtonText {
	position: absolute;
	z-index: 2;
	top: 6px;
	left: 50%;
	transform: translateX(-50%);
	outline: none;

	color: white;
	font-weight: bold;
	font-size: 16px;
	font-family: 'bradHITC', cursive;
}

/* Add on Checkboxes */

.addOnWrapper {
	display: flex;
	flex-direction: column;
	max-width: 550px;
	flex-grow: 1;
}

.addOns {
	max-width: 250px;
	margin: 55px auto;
	/* padding: 55px; */
	font-family: 'caviarDreams', 'Arial', sans-serif;
	/* height: 100px; */
	padding-bottom: 55px;
	margin-bottom: 0;
	/* border-bottom: 2px solid var(--light-divider-grey); */
}

.curved {
	display: block;
	width: 350px;
	position: relative;
	left: -50px;
	margin-top: -8px;
}

.curved > img {
	width: 100%;
}

/* Add On Description */

.addOnDescriptions {
	max-width: 550px;
	padding: 40px 0;
	border: 2px solid var(--light-divider-grey);
	border-left: none;
	border-right: none;
	border-bottom: none;
	/* border-top: none; */
}

.addOnDescriptions .addOnTitle {
	font-size: 24px;
	line-height: 25px;
	letter-spacing: -0.29px;
	font-family: 'bradHITC', cursive;
	margin-bottom: 18px;
	font-weight: bold;
	color: var(--medium-grey);
}

.addOnDescriptions .description {
	font-size: 14px;
	line-height: 30px;
	font-family: 'caviarDreams', 'Arial', sans-serif;

	color: var(--medium-grey);
}

/* Price range / Quote Amount */

.quoteAndDisclaimer {
	margin: 0;
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	justify-content: center;
	/* max-width: 450px; */
	padding: 40px 15px;
	align-items: center;
	border: 2px solid var(--light-divider-grey);
	border-left: none;
	border-right: none;
}

.quoteAmount {
	color: var(--medium-grey);
	font-family: 'caviarDreams', 'Arial', sans-serif;
	text-align: center;
	font-size: 18px;
	margin-bottom: 16px;
	line-height: 21px;
}

.disclaimer {
	text-align: center;
	color: var(--medium-grey);
	font-family: 'caviarDreams', 'Arial', sans-serif;
	line-height: 30px;
	max-width: 450px;
	font-size: 14px;
}

.cta {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 20px 0px;
	margin-top: auto;
}

.cta .requestConsultation {
	font-size: 23px;
	font-family: 'bradHITC', cursive;
	color: var(--dark);
	font-weight: bold;
	line-height: 20px;
	padding: 20px;
}

@media all and (min-width: 600px) and (max-width: 991px) {
	.quoteTool {
		min-height: calc(100vh - 126px);
	}
}

@media screen and (max-width: 575px) {
	.backButton {
		display: none;
	}

	.quoteTool {
		margin: 75px 25px 0;
		width: calc(100% - 50px);
	}

	.description .title {
		font-size: 30px;
	}

	.description .details,
	.description .priceRange {
		font-size: 16px;
	}
}

@media screen and (min-width: 575px) {
	.addOns {
		max-width: 290px;
	}

	.curved {
		left: -30px;
	}
}

@media screen and (max-width: 365px) {
	.curved {
		width: 290px;
		left: -20px;
	}
}
