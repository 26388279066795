.monthlyPackages {
	margin: 0 50px;
}

.monthlyPackages h1 {
	color: #767676;
	font-family: 'roboto', 'Helvetica', sans-serif;
	font-weight: bold;
	font-size: 27px;
	letter-spacing: -0.91px;
	line-height: 42px;
	margin: 0;
	outline: none;
}

.carouselPackages {
	background: linear-gradient(180deg, #fdfdfd 0%, #f3f3f3 100%);
}

.dotCircle {
	border: 2px solid var(--medium-grey);
	border-radius: 50px;
	width: 3px;
	height: 3px;
	background-color: transparent;
}

#dots button {
	background-color: transparent;
}

#dots button:focus {
	border: none;
}

#dots button .dotCircle.dotSelected {
	border-color: var(--orange);
	background-color: var(--orange);
}

.packages {
	display: flex;
	align-items: center;
	overflow: auto;
	padding: 46px;
	background: linear-gradient(180deg, #fdfdfd 0%, #f3f3f3 100%);
}

.monthlyPackages .disclaimer {
	color: #a2a2a2;
	font-family: 'roboto', 'Helvetica', sans-serif;
	font-stretch: condensed;
	font-size: 13px;
	letter-spacing: -0.38px;
	line-height: 18px;
	text-align: center;
}

@media all and (min-height: 1000px) {
	.monthlyPackages {
		margin-top: 25px;
	}

	.packages {
		height: 70vh;
	}

	.carouselPackages {
		display: flex;
		align-items: center;
		height: 900px;
	}
}
/* 
@media all and (min-height: 1000px) and (max-width: 768px) {
	.carouselPackages {
		height: 800px;
	}
}

@media all and (min-height: 1000px) and (min-width: 1024px) {
	.packages {
		height: 75vh;
	}
} */

@media all and (min-height: 1600px) {
	.monthlyPackages {
		margin-top: 50px;
	}

	.packages {
		height: 75vh;
	}
}

@media all and (max-width: 1229px) {
	.monthlyPackages {
		margin: 0 30px;
	}
}

@media all and (max-width: 1149px) {
	.monthlyPackages h1 {
		margin: 20px 0;
	}
}

@media all and (min-width: 1024px) {
	.packages {
		justify-content: center;
	}
}

@media all and (max-width: 1023px) {
	.monthlyPackages {
		margin: 0 50px;
	}

	.carouselPackages {
		padding-top: 50px;
		padding-left: 0;
		padding-right: 0;
	}
}

@media all and (max-width: 575px) {
	.monthlyPackages {
		margin: 80px 8px;
	}

	.carouselPackages {
		padding-top: 25px;
		padding-left: 0;
		padding-right: 0;
	}

	.monthlyPackages h1 {
		display: none;
	}

	.monthlyPackages .disclaimer {
		position: absolute;
		bottom: 55px;
		left: 50%;
		transform: translateX(-50%);
		width: 100%;
		margin: 0 auto;
		font-size: 11px;
	}
}

/* @media all and (max-height: 670px) and (max-width: 575px) {
	.carouselPackages {
		height: 585px;
	}
} */
