.monthly-packages .swiper-container {
	/* height: 100%; */
	padding-top: 0;
	padding-bottom: 30px;
}

.monthly-packages .swiper-pagination.swiper-pagination-bullets {
	top: 195px;
	right: 50%;
	transform: translate(50%);
	display: flex;
	flex-direction: row;
}

.monthly-packages .swiper-slide {
	min-width: 190px;
}

@media all and (max-width: 1023px) {
	/*TODO: Find a fix for this style - shouldn't need left: -20px to center the slides*/
	.monthly-packages ul.swiper-wrapper {
		left: -20px;
	}
}

@media all and (max-width: 575px) {
	.monthly-packages .swiper-container {
		padding-bottom: 0px;
	}

	.monthly-packages .swiper-pagination.swiper-pagination-bullets {
		top: 85px;
	}

	.monthly-packages .swiper-pagination-bullet {
		width: 7px;
		height: 7px;
	}
}

/* @media all and (max-height: 670px) and (max-width: 575px) {
	.monthly-packages .swiper-pagination.swiper-pagination-bullets {
		top: 78px;
	}
} */
