.homePage {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	font-family: 'caviarDreams', 'Arial', sans-serif;
	min-height: 63vh;
	/* padding-bottom: 150px; */
}

.wrapper p {
	padding: 5px 20px;
	max-width: 450px;
	color: var(--light-grey);
	letter-spacing: -0.51px;
	line-height: 26px;
	z-index: -1;
	outline: none;
}

.wrapper .quoteToolLinks {
	margin-top: 55px;
	margin-bottom: 55px;
}

.wrapper a {
	text-decoration: none;
	color: var(--medium-grey);
	font-size: 27px;
	font-family: 'bradHITC', cursive;
	font-weight: 700;
}

a:hover {
	color: var(--dark-grey);
}

/* a:hover {
  color: var(--light-grey);
}

a:hover > span {
  color: var(--medium-grey);
} */

.spacer {
	height: 15px;
}

.lineSeparator {
	width: 180px;
}

@media all and (max-height: 600px) {
	.wrapper .quoteToolLinks {
		margin-bottom: 100px;
	}
}

@media all and (max-width: 575px) {
	.homePage {
		margin: auto;
		padding-bottom: 15px;
		margin-top: 130px;
	}

	.wrapper p {
		padding: 5px 50px;
	}

	.wrapper a {
		font-size: 24px;
	}

	.lineSeparator {
		width: 245px;
	}
}
