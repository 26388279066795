.desktopItem {
	padding: 70px 0;
}

.desktopItem .content {
	position: absolute;
	top: 250px;
	left: 360px;
	width: 43%;
	margin: 25px auto;
	font-family: 'caviarDreams', 'Arial', sans-serif;
	font-weight: bold;
	z-index: 10;
}

.desktopItem .contentWrapper {
	width: 90%;
}

.desktopItem .contentWrapper h1 {
	text-transform: uppercase;
	color: var(--dark-grey);
	font-size: 28px;
	letter-spacing: -0.65px;
	line-height: 50px;
	margin: 0;
}

.desktopItem .contentWrapper p {
	font-family: 'handleeRegular', cursive;
	font-weight: normal;
	font-size: 18px;
	letter-spacing: 0.44px;
	line-height: 26px;
	margin: 5px 0 15px;
}

.desktopItem .portfolioLink {
	display: flex;
	justify-content: flex-end;
}

.desktopItem .portfolioLink button {
	font-family: 'caviarDreams', 'Arial', sans-serif;
	font-weight: bold;
	font-size: 17px;
	letter-spacing: -0.58px;
	line-height: 26px;
}

.desktopItem .portfolioLink button:hover {
	color: var(--orange);
}

.desktopItem .portfolioLink button:focus {
	color: var(--orange);
	border: none;
}

.desktopItem .collage {
	height: 515px;
}

.desktopItem .collage img {
	height: 162px;
	width: 288px;
	background-color: #dcdbdb;
}

.desktopItem .collage img:nth-of-type(1),
.desktopItem .collage img:nth-of-type(4) {
	position: absolute;
	bottom: 75px;
	height: 519.62px;
	width: 240px;
	background-color: #dcdbdb;
	transform: rotate(6deg);
	box-shadow: 2px 3px 9px 0 rgba(150, 150, 150, 0.5);
}

.desktopItem .collage img:nth-of-type(1) {
	left: 90px;
}

.desktopItem .collage img:nth-of-type(4) {
	right: 75px;
}

.desktopItem .collage img:nth-of-type(2) {
	position: absolute;
	bottom: 42px;
	left: 337px;
	transform: rotate(3deg);
	z-index: 11;
	box-shadow: 2px 0 9px 0 rgba(134, 134, 134, 0.5);
}

.desktopItem .collage img:nth-of-type(3) {
	position: absolute;
	bottom: 40px;
	right: 330px;
	transform: rotate(11deg);
	z-index: 10;
	box-shadow: 0 2px 10px 0 rgba(34, 34, 34, 0.5);
}

.desktopItem .collage img:nth-of-type(5) {
	position: absolute;
	top: 55px;
	right: 312px;
	transform: rotate(14deg);
	box-shadow: 0 2px 7px 0 rgba(179, 179, 179, 0.5);
}

.desktopItem .collage img:nth-of-type(6) {
	position: absolute;
	top: 60px;
	left: 347px;
	transform: rotate(-11deg);
	z-index: -1;
	box-shadow: 0 2px 8px 0 rgba(206, 206, 206, 0.5);
}

@media all and (max-width: 1536px) {
	.desktopItem .content {
		top: 212px;
		left: 260px;
		transition: none;
	}

	.desktopItem .contentWrapper h1 {
		font-size: 20px;
	}

	.desktopItem .contentWrapper p {
		width: 100%;
		font-size: 16px;
		line-height: 25px;
		margin-bottom: 15px;
	}

	.desktopItem .portfolioLink button {
		font-size: 16px;
	}

	.desktopItem .collage img:nth-of-type(1) {
		height: 390.49px;
		width: 175px;
		bottom: 165px;
		left: 55px;
		transition: none;
	}

	.desktopItem .collage img:nth-of-type(2) {
		height: 110.59px;
		width: 210px;
		bottom: 142px;
		left: 230px;
		transition: none;
	}

	.desktopItem .collage img:nth-of-type(3) {
		height: 110.59px;
		width: 210px;
		bottom: 140px;
		right: 245px;
		transition: none;
	}

	.desktopItem .collage img:nth-of-type(4) {
		height: 390.49px;
		width: 175px;
		bottom: 165px;
		right: 55px;
		transition: none;
	}

	.desktopItem .collage img:nth-of-type(5) {
		height: 110.59px;
		width: 210px;
		top: 95px;
		right: 226px;
		transition: none;
	}

	.desktopItem .collage img:nth-of-type(6) {
		height: 110.59px;
		width: 210px;
		top: 100px;
		left: 246px;
		transition: none;
	}
}

@media all and (max-width: 1095px) and (min-width: 991px) {
	.desktopItem .collage img:nth-of-type(1) {
		left: 27px;
		/* transition: none; */
		transition: all 0.25s ease-in-out;
	}

	.desktopItem .collage img:nth-of-type(2) {
		left: 200px;
		/* transition: none; */
		transition: all 0.25s ease-in-out;
	}

	.desktopItem .collage img:nth-of-type(3) {
		right: 215px;
		/* transition: none; */
		transition: all 0.25s ease-in-out;
	}

	.desktopItem .collage img:nth-of-type(4) {
		right: 28px;
		/* transition: none; */
		transition: all 0.25s ease-in-out;
	}

	.desktopItem .collage img:nth-of-type(5) {
		right: 198px;
		/* transition: none; */
		transition: all 0.25s ease-in-out;
	}

	.desktopItem .collage img:nth-of-type(6) {
		left: 215px;
		/* transition: none; */
		transition: all 0.25s ease-in-out;
	}

	.desktopItem .content {
		width: 44%;
		left: 237px;
		/* transition: none; */
		transition: all 0.25s ease-in-out;
	}
}

@media all and (max-width: 991px) {
	.desktopItem .content {
		top: 265px;
		left: 170px;
		width: 40%;
	}

	.desktopItem .contentWrapper h1 {
		font-size: 20px;
	}

	.desktopItem .contentWrapper p {
		width: 100%;
		font-size: 18px;
		line-height: 25px;
		margin-left: 25px;
		margin-bottom: 25px;
	}

	.desktopItem .portfolioLink button {
		font-size: 16px;
	}

	.desktopItem .collage {
		height: 600px;
	}

	.desktopItem .collage img:nth-of-type(1) {
		display: none;
		transition: all 0.25s ease-in-out;
	}

	.desktopItem .collage img:nth-of-type(2) {
		transform: rotate(-3deg);
		bottom: 70px;
		left: 235px;
		z-index: 10;
		transition: all 0.25s ease-in-out;
		box-shadow: none;
	}

	.desktopItem .collage img:nth-of-type(3) {
		transform: rotate(-5deg);
		bottom: 70px;
		right: 251px;
		transition: all 0.25s ease-in-out;
	}

	.desktopItem .collage img:nth-of-type(4) {
		transform: rotate(1deg);
		height: 360.49px;
		top: 200px;
		right: 157px;
		z-index: 15;
		transition: all 0.25s ease-in-out;
	}

	.desktopItem .collage img:nth-of-type(5) {
		transform: rotate(-8deg);
		top: 92px;
		right: 203px;
		transition: all 0.25s ease-in-out;
	}

	.desktopItem .collage img:nth-of-type(6) {
		transform: rotate(-2deg);
		top: 80px;
		left: 291px;
		transition: all 0.25s ease-in-out;
	}
}

@media all and (max-width: 768px) {
	.desktopItem .content {
		left: 165px;
	}

	.desktopItem .collage img:nth-of-type(2) {
		left: 215px;
	}

	.desktopItem .collage img:nth-of-type(3) {
		right: 271px;
	}

	.desktopItem .collage img:nth-of-type(4) {
		right: 177px;
	}

	.desktopItem .collage img:nth-of-type(5) {
		right: 223px;
	}

	.desktopItem .collage img:nth-of-type(6) {
		left: 271px;
	}
}

@media all and (max-width: 690px) {
	.desktopItem .content {
		left: 200px;
		top: 250px;
	}

	.desktopItem .contentWrapper h1 {
		font-size: 18px;
	}

	.desktopItem .contentWrapper p {
		font-size: 16px;
		line-height: 24px;
		margin-left: 20px;
	}

	.desktopItem .portfolioLink button {
		margin-right: 35px;
	}

	.desktopItem .collage img:nth-of-type(2) {
		height: 100.59px;
		width: 195px;
		bottom: 95px;
		left: 225px;
	}

	.desktopItem .collage img:nth-of-type(3) {
		height: 100.59px;
		width: 195px;
		right: 295px;
		bottom: 95px;
	}

	.desktopItem .collage img:nth-of-type(4) {
		height: 345.59px;
		width: 160px;
		right: 205px;
	}

	.desktopItem .collage img:nth-of-type(5) {
		height: 100.59px;
		width: 195px;
		top: 95px;
		right: 250px;
	}

	.desktopItem .collage img:nth-of-type(6) {
		height: 100.59px;
		width: 195px;
		top: 80px;
		left: 290px;
	}
}
