.itemCarouselDesktop {
	max-width: 854px;
	padding: 20px;
	margin-right: auto;
	margin-left: auto;
	border-radius: 5px;
	background-color: #ffffff;
	box-shadow: 0 2px 11px 4px #f5f5f5;
	position: relative;
}

/* swiper */
.itemCarouselDesktop .thumbnails {
	position: relative;
}

.itemCarouselDesktop .thumbImgOuter {
	width: 100%;
}

.itemCarouselDesktop .thumbImgInner {
	width: 100%;
	height: 100%;
	object-fit: cover;
	overflow: hidden;
}

.itemCarouselDesktop .slideContainer {
	width: 100%;
}

.itemCarouselDesktop .slideImg {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.itemCarouselDesktop .thumbnails p {
	margin: 0;
	margin-top: 8px;
	color: #787878;
	text-align: center;
	font-family: 'roboto', 'Helvetica', sans-serif;
	font-size: 17px;
	letter-spacing: -0.48px;
	line-height: 23px;
}

@media all and (max-width: 1279px) {
	.itemCarouselDesktop {
		padding: 0;
	}

	.itemCarouselDesktop .thumbnails {
		display: none;
	}

	/* display pagination */
}

@media all and (max-width: 991px) {
	.itemCarouselDesktop {
		max-width: none;
	}
}
