.portfolioContainer {
	font-family: 'caviarDreams', 'Arial', sans-serif;
	font-weight: bold;
	margin: 0;
	padding-top: 50px;
}

.portfolioContainer:focus {
	outline: none;
}

/* .portfolioContainer ul.options {
	display: inline-flex;
	padding: 0;
	margin: 40px 0 0 150px;
}

.portfolioContainer .options > li {
	list-style-type: none;
	padding-right: 50px;
	font-size: 20px;
	letter-spacing: -0.32px;
	line-height: 28px;
}

.portfolioContainer .options > li a {
	text-decoration: none;
}

.portfolioContainer .options > li a:active,
.portfolioContainer .options > li a:visited {
	color: var(--medium-grey);
}

.portfolioContainer .options > li a:hover {
	color: var(--dark-grey);
}

.portfolioContainer .options > li a:focus {
	color: var(--orange);
} */

.mobilePortfolioItems {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

@media all and (min-height: 1200px) {
	.portfolioContainer {
		display: flex;
		flex-direction: column;
	}

	/* .portfolioContainer ul.options {
		margin-top: 100px;
	} */

	.desktopPortfolioItems {
		display: flex;
		flex-direction: column;
		align-items: center;
		height: 830px;
	}
}

/* @media all and (min-width: 3840px) {
	.portfolioContainer ul.options {
		margin-left: 350px;
	}
} */

@media all and (min-width: 3840px) and (min-height: 2160px) {
	/* .portfolioContainer ul.options {
		margin-left: 350px;
		margin-top: 200px;
	} */

	.desktopPortfolioItems {
		height: 1000px;
	}
}

/* @media all and (max-width: 1536px) {
	.portfolioContainer ul.options {
		margin-left: 100px;
	}

	.portfolioContainer .options > li {
		font-size: 18px;
	}
} */

@media all and (max-width: 991px) {
	.portfolioContainer {
		display: flex;
		justify-content: center;
		padding-top: 0;
	}

	/* .portfolioContainer ul.options {
		display: flex;
		justify-content: center;
		margin: 40px 0 0 0;
	}

	.portfolioContainer .options > li:last-of-type {
		padding: 0;
	} */
}

@media all and (max-width: 575px) {
	.portfolioContainer {
		margin-top: 100px;
	}

	/* .portfolioContainer ul.options {
		margin-bottom: 10px;
	}

	.portfolioContainer .options > li {
		color: #242424;
		font-family: 'caviarDreams', 'Arial', sans-serif;
		font-weight: bold;
		font-size: 15px;
		letter-spacing: -0.19px;
		line-height: 17px;
	} */
}

/* @media all and (max-width: 500px) {
	.portfolioContainer ul.options {
		display: flex;
		justify-content: flex-start;
		margin-left: 35px;
	}

	.portfolioContainer .options > li {
		padding-right: 40px;
	}

	.portfolioContainer .options > li:last-of-type {
		padding: 0;
	}
} */
