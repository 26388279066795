.cornerContainer {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.greyCorner {
  position: relative;
  height: 100%;
}

.greyCorner img {
  position: absolute;
  right: 0;
  bottom: 0;
  max-width: 350px;
  width: 100%;
}

@media screen and (max-width: 1500px) {
  .cornerContainer {
    position: absolute;
    right: 0;
    bottom: 0;
    max-width: 250px;
    width: 100%;
  }
}

/* @media screen and (max-width: 1025px) { */
@media screen and (max-width: 1260px) {
  .cornerContainer {
    display: none;
  }
}
