.mobileItem {
	padding: 20px;
}

.mobileItem .projectLink {
	width: 100%;
}

.mobileItem .projectLink:focus {
	border: none;
}

.mobileItem .collage {
	margin: 32px 0;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
}

.mobileItem .collage img {
	/* height: 305.45px;
  width: 140px; */
	height: 325.45px;
	width: 160px;
	background: lightgray;
	border-radius: 5%;
}

.mobileItem .collage img:nth-child(1) {
	position: absolute;
	left: 55px;
}

.mobileItem .collage img:nth-child(2) {
	transform: rotate(-3deg);
	z-index: 1;
}

.mobileItem .collage img:nth-child(3) {
	transform: rotate((1deg));
	position: absolute;
	right: 55px;
}

.mobileItem .content {
	padding: 0 55px 25px;
}

.mobileItem .content h1 {
	margin: 0;
	color: #242424;
	font-family: 'caviarDreams', 'Arial', sans-serif;
	font-weight: bold;
	font-size: 15px;
	letter-spacing: -0.22px;
	line-height: 19px;
	text-transform: uppercase;
}

.mobileItem .content p {
	color: #767676;
	font-family: 'roboto', 'Helvetica', sans-serif;
	font-size: 15px;
	letter-spacing: -0.18px;
	line-height: 25px;
}

.mobileItem .spacer:nth-of-type(1) {
	height: 7px;
	width: 100vw;
	transform: scaleY(1);
	background: linear-gradient(180deg, rgba(238, 238, 238, 0) 0%, #e4e4e4 100%);
}

.mobileItem .spacer:nth-of-type(2) {
	height: 7px;
	width: 100vw;
	transform: rotate(-1deg);
	background: linear-gradient(180deg, rgba(238, 238, 238, 0) 0%, #dedede 100%);
}

@media all and (max-width: 550px) {
	.mobileItem .collage img:nth-child(1) {
		left: 50px;
	}

	.mobileItem .collage img:nth-child(3) {
		right: 50px;
	}

	.mobileItem .content {
		padding: 0 50px 25px;
	}
}

@media all and (max-width: 525px) {
	.mobileItem .collage img:nth-child(1) {
		left: 40px;
	}

	.mobileItem .collage img:nth-child(3) {
		right: 40px;
	}

	.mobileItem .content {
		padding: 0 40px 25px;
	}
}

@media all and (max-width: 500px) {
	.mobileItem .collage img:nth-child(1) {
		left: 25px;
	}

	.mobileItem .collage img:nth-child(3) {
		right: 25px;
	}

	.mobileItem .content {
		padding: 0 25px 25px;
	}
}
