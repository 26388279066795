.item-carousel-mobile .swiper-container {
	position: static;
	padding: 0;
	margin: 0;
	border-radius: 11% / 5%;
	height: 100%;
}

.item-carousel-mobile .swiper-wrapper {
	margin: 0;
}

.item-carousel-mobile .swiper-slide img {
	height: 100%;
	width: 100%;
	object-fit: cover;
}

.item-carousel-mobile .swiper-button-prev {
	position: absolute;
	top: 50%;
	left: -90px;
}

.item-carousel-mobile .swiper-button-next {
	position: absolute;
	top: 50%;
	right: -90px;
}

.item-carousel-mobile .swiper-pagination.swiper-pagination-bullets {
	top: 22%;
	right: -65px;
}

.item-carousel-mobile .swiper-pagination-bullet {
	width: 12px;
	height: 12px;
	background: transparent;
	border: 3px solid black;
	opacity: 0.3;
}

.item-carousel-mobile .swiper-pagination-bullet-active {
	background-color: var(--orange);
	opacity: 1;
	border: 3px solid var(--orange);
}

@media all and (max-width: 1279px) and (min-width: 686px) {
	.item-carousel-mobile .swiper-container .swiper-pagination.swiper-pagination-bullets {
		flex-direction: row;
		top: auto;
		bottom: 0;
		right: 0;
		width: 241px;
		background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
		justify-content: center;
		padding: 9px 0;
		border-bottom-right-radius: 25px;
		border-bottom-left-radius: 25px;
	}

	.item-carousel-mobile .swiper-container .swiper-pagination .swiper-pagination-bullet {
		width: 8px;
		height: 8px;
		background-color: white;
		border: none;
	}

	.item-carousel-mobile .swiper-button-prev,
	.item-carousel-mobile .swiper-button-next {
		display: none;
	}
}

@media all and (max-width: 685px) {
	.item-carousel-mobile .swiper-container .swiper-pagination.swiper-pagination-bullets {
		flex-direction: row;
		top: auto;
		bottom: -65px;
		right: 50%;
		transform: translate(50%);
	}

	.item-carousel-mobile .swiper-container .swiper-pagination .swiper-pagination-bullet {
		width: 10px;
		height: 10px;
		border: 2px solid black;
	}

	.item-carousel-mobile .swiper-container .swiper-pagination .swiper-pagination-bullet-active {
		border: 2px solid var(--orange);
	}

	.item-carousel-mobile .swiper-button-prev {
		left: -60px;
	}

	.item-carousel-mobile .swiper-button-next {
		right: -60px;
	}
}

@media all and (max-width: 575px) {
	.item-carousel-mobile .swiper-container .swiper-pagination .swiper-pagination-bullet {
		width: 8px;
		height: 8px;
	}

	.item-carousel-mobile .swiper-container .swiper-pagination.swiper-pagination-bullets {
		bottom: -55px;
	}
}
