/* App styles */
.App {
	min-height: 100vh;
	overflow: hidden;
	position: relative;
	margin-left: 50px;
}

.App-header {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding-top: 40px;
	background-color: white;
	width: 100%;
	font-family: 'roboto', 'Helvetica', sans-serif;
	font-weight: bold;
	font-size: 10px;
	color: var(--medium-grey);
}

/* .App-content.coverScreen {
	display: flex;
	align-items: center;
	flex-direction: column;
	min-height: calc(100vh - 128px);
} */

.App-content.center {
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: calc(100vh - 128px);
}

@media all and (max-width: 1279px) {
	.App-content.portfolio {
		display: block;
	}
}

.App-content.centerHeight {
	min-height: calc(100vh - 256px);
}

.App-content.quote-tool {
	flex-direction: column;
}

.App-content button {
	border: none;
	background-color: transparent;
}

.App-content a {
	text-decoration: none;
	display: block;
	outline: none;
}

/* Focus state */
.App-content a:focus,
.App-content a:focus span {
	outline: none;
	color: var(--orange);
}

.App-content button:focus {
	outline: none;
	border: 2px solid var(--orange);
}

/* Hover state */

.App-content button:hover,
.App-content a:hover {
	cursor: pointer;
}

@media screen and (min-width: 991px) {
	.App-content.coverScreen {
		display: block;
	}
}

@media screen and (min-width: 576px) {
	.App-header.leftAlign {
		align-items: flex-start;
		padding-left: 66px;
	}
}

@media screen and (max-width: 575px) {
	.App {
		margin-left: 0;
	}

	.App-content.center {
		min-height: calc(100vh - 50px);
		margin-bottom: 50px;
	}

	.App-header {
		padding-top: 0;
	}
}
