.desktop-portfolio-items {
	width: 1250px;
	margin-right: auto;
	margin-left: auto;
}

.desktop-portfolio-items .swiper-container {
	overflow: visible;
	width: 100%;
}

.desktop-portfolio-items .swiper-pagination.swiper-pagination-bullets {
	flex-direction: row;
	top: auto;
	bottom: 35px;
	left: 45%;
}

@media all and (min-width: 992px) {
	.desktop-portfolio-items .swiper-pagination.swiper-pagination-bullets {
		display: none;
	}
}

@media all and (max-width: 1536px) {
	.desktop-portfolio-items {
		width: 900px;
	}
}

@media all and (max-width: 1095px) and (min-width: 991px) {
	.desktop-portfolio-items {
		width: 840px;
	}

	.desktop-portfolio-items .swiper-slide {
		margin-right: 5px;
	}
}
