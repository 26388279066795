.itemCarouselMobile {
	display: flex;
	justify-content: center;
	position: relative;
	flex-grow: 1;
	height: calc(100% + 200px);
}

img.iPhoneNotch {
	z-index: 10;
	position: absolute;
	top: 1.8%;
	width: 175px;
}

.itemCarouselMobile .iPhoneLayer1 {
	position: absolute;
	filter: drop-shadow(0 5px 30px #aeaeae);
	height: 100%;
	display: flex;
	justify-content: center;
	width: 100%;
}

.itemCarouselMobile .iPhoneLayer2 {
	position: absolute;
	height: 100%;
	display: flex;
	justify-content: center;
	width: 100%;
}

.iPhoneLayer1 > img,
.iPhoneLayer2 > img {
	height: 100%;
}

.itemCarouselMobile .mainImgOuter {
	height: 100%;
}

.itemCarouselMobile .mainImgInner {
	height: 100%;
}

@media all and (max-width: 1326px) {
	.itemCarouselMobile img.iPhoneNotch {
		width: 170px;
	}
}

@media all and (max-width: 1279px) {
	.itemCarouselMobile {
		height: 550px;
	}

	.itemCarouselMobile img.iPhoneNotch {
		width: 145px;
	}

	.itemCarouselMobile .iPhoneLayer1 {
		filter: drop-shadow(10px 15px 15px #aeaeae);
	}
}

@media all and (max-width: 685px) {
	.itemCarouselMobile {
		height: 115vw;
	}

	.itemCarouselMobile img.iPhoneNotch {
		width: 30vw;
	}
}

@media all and (max-width: 575px) {
	.itemCarouselMobile {
		height: 130vw;
	}

	.itemCarouselMobile img.iPhoneNotch {
		width: 33vw;
	}
}
