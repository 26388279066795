.overlay {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(circle, #ffffff 0%, rgba(0, 0, 0, 0.5) 100%);
  z-index: 9999;
  /* cursor: pointer; */
}

.content {
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 2px 12px 2px rgba(50, 50, 50, 0.5);
  max-height: 655px;
  max-width: 436px;
  padding: 0.5rem;
  position: relative;
}

button.closeBtn {
  position: absolute;
  right: 5px;
  width: 40px;
  height: 40px;
  padding: 10px;
}

button.closeBtn:hover {
  cursor: default;
}

button.closeBtn.mobile img {
  width: 9px;
  height: 9px;
}

.content.desktop {
  padding: 1rem;
}

button.closeBtn.desktop {
  right: 15px;
}
