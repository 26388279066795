/* TODO: Position in the center of the page when there's enough space */
.quoteTool {
	position: relative;
	display: grid;
	grid-template-columns: 0.8fr 1px 1.2fr 1px minmax(200px, 1fr);
	align-items: center;

	min-height: 63vh;
	margin: 0 auto;
	padding: 60px 15px;

	max-width: 1500px;

	font-family: 'caviarDreams', 'Arial', sans-serif;
}

/** Accordions and products under accordions  */

.categories {
	padding: 0 80px;
	padding-left: 30px;
	min-width: 200px;
}

.categories .products {
	font-family: 'caviarDreams', 'Arial', sans-serif;
	padding: 10px;
	max-width: 200px;
	text-align: right;
	font-size: 22px;
	font-weight: normal;
}

.products input {
	display: none;
}

.products .productSelection {
	font-family: 'caviarDreams', 'Arial', sans-serif;
	display: block;
	margin: 10px;
	font-weight: 300;
	color: var(--divider-grey);
	white-space: nowrap;
	text-align: right;
	font-size: 22px;
}

.products input[type='checkbox']:checked + .productSelection {
	color: var(--orange);
}

.products .productSelection:hover,
.products .productSelection:active {
	font-family: 'caviarDreams', 'Arial', sans-serif;
	color: var(--dark-grey);
}

/** Divider */

.divider {
	align-self: stretch;
	width: 1px;
	min-width: 1px;
	background-color: var(--divider-grey);
}

/** Descriptions (before any product is selected) */

.descriptions {
	position: relative;
	grid-column-start: 3;
	grid-column-end: 6;

	/* max-width: 850px; */
	padding-right: 75px;
	padding-left: 75px;

	color: var(--medium-grey);
	font-family: 'Handlee', sans-serif;
}

.descriptions .description {
	position: relative;
}

.description.basic {
	height: 280px;
	transform: rotate(-1deg);
}

.description.plus {
	transform: rotate(4deg);
	height: 230px;
}

.description.premium {
	transform: rotate(-3deg);
}

.descriptions .detailedContent {
	text-align: left;
	font-size: 22px;
}

.detailedContent .title {
	display: inline;
	vertical-align: bottom;
	padding-right: 30px;

	font-size: 40px;
	color: var(--dark-grey);
}

.detailedContent .details {
	display: inline;
	font-size: 18px;
	letter-spacing: 1px;
}

.descriptions .highlighted {
	color: var(--orange);
}

.descriptions .priceRange {
	margin-top: 25px;
	font-size: 18px;
	letter-spacing: 1px;
}

.descriptions .separator {
	padding: 0px 20px;
}

/** Add On Wrapper (Second Column) */

.addOnWrapper {
	display: flex;
	flex-direction: column;
	padding: 50px 80px;
	min-width: 290px;
	min-height: 800px;
	justify-content: center;
}

.addOnWrapper .addOns {
	margin-bottom: 30px;
	padding: 0 10%;
	position: relative;
}

.addOns .curved {
	width: 120%;
	position: relative;
	top: -10px;
	left: -10%;
}

.curved > img {
	width: 100%;
}

.addOnWrapper .quoteAmount {
	margin: 30px 0px;
	padding-left: 15%;

	color: var(--divider-grey);
	font-size: 23px;
	line-height: 1.5;
}

.addOnWrapper .disclaimer {
	margin: 20px 0px;
	color: var(--divider-grey);
	font-size: 19px;
	line-height: 25px;
}

.addOnWrapper .cta {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 40px;
}

.addOnWrapper .cta img {
	max-width: 100%;
	display: block;
	align-self: center;
}

.addOnWrapper .cta .requestConsultation {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 80px;
	margin-top: 20px;
}

.requestConsultation > .requestLink {
	font-family: 'bradHITC', cursive;
	font-size: 26px;
	color: var(--medium-grey);
}

/** Add On Description (Third Column)  */

.addOnDescriptions {
	padding: 0 80px;
	padding-right: 30px;
}

.addOnDescriptions .addOnTitle {
	font-family: 'bradHITC', cursive;
	font-size: 30px;
	margin-bottom: 80px;
	color: var(--medium-grey);
}

.addOnDescriptions .description {
	font-size: 22px;
	margin-bottom: 120px;
	color: var(--divider-grey);
	line-height: 30px;
}

.addOnDescriptions .addOnIcon img {
	width: 100%;
}

.hidden {
	display: none !important;
}

/** Breakpoints */

@media all and (max-width: 1300px) {
	.categories {
		padding: 0 30px;
	}

	.descriptions {
		padding: 0 30px;
	}

	.addOnWrapper {
		padding: 20px 30px;
	}

	.addOnWrapper .addOns {
		padding: 0 8%;
	}

	.addOnDescriptions {
		padding: 0 30px;
	}

	.addOnWrapper .quoteAmount {
		padding-left: 0;
		text-align: center;
	}

	.addOnDescriptions .description {
		font-size: 19px;
		line-height: 25px;
	}
}

@media all and (max-width: 1110px) {
	.addOnWrapper .addOns {
		padding: 0 5%;
	}
}
