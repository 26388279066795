.container {
	display: block;
}

.desktopLogo {
	display: block;
	margin-left: auto;
	margin-right: auto;
	height: 55px;
	pointer-events: none;
}

/* TODO: mobile starts at 425px or 550px? */
@media screen and (max-width: 575px) {
	.container {
		display: none;
	}
}
