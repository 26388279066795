.container,
.spacer {
	display: none;
}

.container div {
	display: flex;
	justify-content: flex-end;
	transform: skew(0deg, 2.5deg);
	transform: skew(0deg, 2.5deg);
	-webkit-transform: skew(0deg, 2.5deg);
	-moz-transform: skew(0deg, 2.5deg);
	-o-transform: skew(0deg, 2.5deg);
	-ms-transform: skew(0deg, 2.5deg);
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
}

.container button {
	border: none;
	background-color: transparent;
	padding: 0;
	display: flex;
	justify-content: flex-end;
	width: 100%;
}

.mobileHeaderIcon {
	position: relative;
	top: 2px;
	/* left: -10px; TODO: only apply to back button over 320px */
	height: 40px;
	width: 40px;
}

.mobileHeaderIcon > button {
	height: 40px;
	padding: 0;
	display: flex;
	justify-content: center;
	align-items: center;
}

.mobileHeaderIcon > button.backBtn {
	position: relative;
	left: -10px;
}

.logo {
	width: 40px;
}

.headerTitle {
	position: relative;
	top: 3px;
	padding-top: 7px;
}

h1.header {
	font-family: 'caviarDreams', 'Arial', sans-serif;
	font-weight: bold;
	font-size: 16px;
	letter-spacing: -0.23px;
	line-height: 21px;
	text-transform: uppercase;
	color: #3e3e3e;
	margin: 0 auto;
}

.mobileHeaderMoreInfo {
	position: relative;
	top: 11px;
	width: 40px;
	height: 40px;
	padding-left: 12px;
}

.mobileHeaderMoreInfo > button {
	height: 40px;
	padding: 0;
	display: flex;
	justify-content: center;
	align-items: center;
}

@media screen and (max-width: 575px) {
	.container {
		display: flex;
		position: fixed;
		top: -15px;
		align-items: center;
		justify-content: space-between;
		box-sizing: border-box;
		padding: 10px 25px;
		padding-right: 10px;
		min-width: 103%;
		height: 75px;
		background-color: #fafafa;
		z-index: 15;
		transform: skew(0deg, -2.5deg);
		transform: skew(0deg, -2.5deg);
		-webkit-transform: skew(0deg, -2.5deg);
		-moz-transform: skew(0deg, -2.5deg);
		-o-transform: skew(0deg, -2.5deg);
		-ms-transform: skew(0deg, -2.5deg);
		-webkit-backface-visibility: hidden;
		backface-visibility: hidden;
		box-shadow: 0 -10px 19px -14px #bfbebe inset;
	}

	.spacer {
		/* add gradient */
		display: block;
		height: 25px; /*50px*/
		width: 100%;
		background-color: white;
		position: fixed;
		top: 40px;
		z-index: 14;
		transform: skew(0deg, -2.5deg);
	}
}

@media screen and (max-width: 320px) {
	.container {
		padding: 10px 20px;
	}

	.mobileHeaderIcon > button.backBtn {
		left: 0px;
	}
}
