.meetingSteps {
	box-sizing: border-box;
	margin-left: auto;
	margin-right: auto;
	max-width: 450px;
	padding: 25px 50px 15px;
}

.meetingSteps h1 {
	font-family: 'caviarDreams', 'Arial', sans-serif;
	line-height: 28px;
	letter-spacing: -0.32px;
	margin-left: 62px;
	margin-bottom: 5px;
	font-size: 22px;
	color: var(--medium-grey);
	outline: none;
}

.meetingSteps .step {
	display: flex;
	max-width: 500px;
	padding: 30px 0;
	font-family: 'handleeRegular', cursive;
	font-size: 17px;
	letter-spacing: 0.5px;
	line-height: 23px;
	color: #939393;
}

.meetingSteps .circle {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 34px;
	height: 34px;
	margin: 5px 25px 0 0;
	border: 3px solid #939393;
	border-radius: 50%;
	text-align: center;
	color: #939393;
}

.ctaMessage {
	margin: auto;
	max-width: 330px;
	width: 100%;
}

.ctaMessage p {
	font-family: 'robotoLight';
	font-size: 15px;
	text-align: center;
}

.ctaBtns {
	padding: 5px 0 25px;
}

.ctaBtns .linkButton {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 5px;
	margin: 10px auto;
	border: 2px solid var(--medium-grey);
	border-radius: 3px;
	height: 50px;
	max-width: 330px;
	width: 100%;
	font-family: 'roboto', 'Helvetica', sans-serif;
	font-weight: bold;
	font-size: 16px;
}

.ctaBtns .linkButton:visited {
	color: #242424;
}

.ctaBtns .linkButton:hover,
.ctaBtns .linkButton:focus {
	background-color: var(--orange);
	color: white;
	border-color: var(--orange);
}

.modalContentContainer {
	margin: auto;
	width: 230px;
	height: 277px;
	font-family: 'robotoLight', cursive;
	font-size: 15px;
	font-weight: 300px;
	color: #787878;
	line-height: 13px;
	letter-spacing: -0.43px;
	text-align: center;
}

.modalContentContainer img.envelope {
	margin-top: 60px;
	margin-bottom: 25px;
	height: 62.82px;
	width: 77px;
}

.modalContentContainer p {
	width: 80%;
	margin: auto;
}

.modalContentContainer .depthLine {
	margin: 35px 5px;
}

.modalContentContainer .depthLine hr {
	margin: 0;
	height: 1px;
	background-color: #d8d8d8;
	border: none;
}

.modalContentContainer .depthLine hr:first-of-type {
	background-color: #eeeeee;
}

.modalContentContainer .depthLine p {
	margin-top: 25px;
	font-family: 'roboto', 'Helvetica', sans-serif;
	color: #796868;
	font-size: 12px;
	font-weight: 100;
	letter-spacing: -0.35px;
	line-height: 14px;
}

@media screen and (min-width: 1261px) {
	.meetingSteps.desktop {
		margin-right: 0;
		margin-left: 0;
		padding: 0;
	}
	.ctaMessage.desktop,
	.ctaBtns.desktop {
		display: none;
	}
}

@media screen and (max-width: 575px) {
	.meetingSteps {
		margin-top: 80px;
		padding-top: 20px;
	}

	.ctaBtns {
		padding: 15px 0 80px;
	}
}

@media screen and (max-width: 425px) {
	.meetingSteps h1 {
		margin-left: 0;
		margin-bottom: 15px;
		text-align: center;
	}

	.meetingSteps .step {
		padding: 25px 0;
	}

	.meetingSteps .circle {
		width: 30px;
		height: 30px;
	}

	.ctaMessage,
	.ctaBtns .linkButton {
		width: 90%;
	}
}

@media screen and (max-width: 350px) {
	.meetingSteps {
		padding: 20px 35px 15px;
	}

	.meetingSteps h1 {
		margin-bottom: 10px;
		text-align: left;
	}

	.meetingSteps .circle {
		width: 25px;
		height: 25px;
		margin: 5px 20px 0 0;
		border-width: 2px;
	}
}
