.pageFlipFooter {
	position: absolute;
	bottom: 0;
	height: 252px;
	width: 100%;
	z-index: 1;
}

.pageFlip {
	position: relative;
	height: 100%;
}

.pageFlip img {
	position: absolute;
	right: 0;
	bottom: 0;
	max-width: 515px;
	width: 85%;
}

.pageFlip a {
	position: absolute;
	right: 2%;
	bottom: 2%;
}

.question {
	display: inline-block;
	text-align: right;
	font-family: 'bradHITC', cursive;
	font-size: 17px;
	font-weight: 700;
	color: var(--dark-grey);
	margin-right: 20px;
	line-height: 1.7;
}

.questionMark {
	font-family: 'bradHITC', cursive;
	font-size: 75px;
	font-weight: 700;
	color: var(--dark-grey);
}

@media screen and (max-height: 650px) and (max-width: 1024px) {
	.pageFlip img {
		max-width: 400px;
	}

	.pageFlip a {
		bottom: 0;
	}

	.question {
		font-size: 16px;
		line-height: 1.5;
		margin-right: 15px;
	}

	.questionMark {
		font-size: 65px;
	}
}

@media screen and (max-width: 850px) {
	.pageFlip img {
		max-width: 400px;
	}

	.pageFlip a {
		bottom: 0;
	}

	.question {
		font-size: 16px;
		line-height: 1.5;
		margin-right: 15px;
	}

	.questionMark {
		font-size: 65px;
	}
}

@media screen and (max-width: 575px) {
	.pageFlipFooter {
		display: none;
	}
}
