.mobileNav {
	display: none;
}

.mobileNavSpacer {
	/* add gradient? */
	position: fixed;
	bottom: 0;
	margin-bottom: -8px;
	width: 100%;
	height: 8px;
	background-color: white;
}

.mobileNavContainer {
	background-color: #f3f3f3; /* #f1eeee */
}

.mobileNav ul {
	position: relative;
	display: inline-flex;
	width: 100%;
	height: 50px;
	flex-direction: row-reverse;
	justify-content: center;
	margin: 0;
	padding: 0;
}

.mobileNav li {
	position: relative;
	z-index: 2;
	list-style-type: none;
	box-shadow: 0 -14px 13px -20px grey inset;
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
	margin: 0.5px;
	width: 80%;
	background-color: white;
}

.mobileNav a {
	text-decoration: none;
	display: block;
	outline: none;
}

.mobileNav .tab {
	display: flex;
	justify-content: space-around;
	align-items: center;
	height: 49px;
}

.mobileNav .icon,
.mobileNav .selectedIcon {
	transform: rotate(270deg);
	width: 15px;
	height: 15px;
}

.mobileNav .icon.contact,
.mobileNav .selectedIcon.contact {
	width: 13px;
	height: 13px;
}

.mobileNav .selectedIcon {
	display: none;
}

.mobileNav .selected .selectedIcon {
	display: block;
}

.mobileNav .selected .icon {
	display: none;
}

.mobileNav .selected {
	background: white;
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
	box-shadow: 0 8px 0px 0px white, 0 0px 0px 0px white, 5px 0 9px -4px #d6d6d6, -5px 0 9px -4px #d6d6d6;
}

@media screen and (max-width: 575px) {
	.mobileNav {
		display: block;
		position: fixed;
		margin-bottom: -50px;
		left: 100%;
		bottom: 0;
		width: 100vw;
		transform: rotate(-180deg);
		transform-origin: top left;
		z-index: 11;
	}
}
