.contactPage {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 50px;
	min-height: 72vh;
	font-family: 'caviarDreams', 'Arial', sans-serif;
	color: var(--light-grey);
	padding-bottom: 84px;
	z-index: -1;
}

.wrapper {
	display: flex;
	justify-content: space-evenly;
	width: 1350px;
	margin: auto;
}

.contactForm {
	display: flex;
	align-items: center;
	color: var(--medium-grey);
	font-family: 'roboto', 'Helvetica', sans-serif;
	font-weight: bold;
	font-size: 14px;
}

/* Change the autofill color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	box-shadow: 0 0 0 35px white inset;
	-webkit-text-fill-color: var(--dark-grey);
}

.textInputs {
	display: flex;
	margin-bottom: 20px;
}

/* input[type='text']::placeholder {
  font-family: 'roboto', 'Helvetica', sans-serif;
  font-weight: bold;
  font-size: 14px;
} */

input[type='text'],
input[type='email'] {
	border: none;
	border-bottom: 2px solid #c6c6c6;
	width: 100%;
	box-sizing: border-box;
	padding-left: 10px;
	padding-bottom: 15px;
	margin-bottom: 5px;
	font-family: 'roboto', 'Helvetica', sans-serif;
	font-weight: bold;
	font-size: 14px;
	color: var(--dark-grey);
}

input[type='text']:focus,
input[type='email']:focus {
	outline: none;
	border-bottom: 2px solid var(--orange);
}

input[type='text'].invalid:focus,
input[type='email'].invalid:focus {
	outline: none;
	border-bottom: 2px solid rgb(170, 2, 2);
}

.inputContainer {
	position: relative;
	width: 250px;
	margin-top: 5px;
}

.inputContainer:nth-child(1) {
	margin-right: 50px;
}

.inputContainer label {
	position: absolute;
	margin-left: 10px;
	color: #797575;
}

.emailInput .inputContainer {
	width: 100%;
}

.textAreaContainer {
	position: relative;
}

.textAreaContainer label {
	position: absolute;
	margin-left: 12px;
	margin-top: 55px;
	color: #797575;
}

.textArea {
	box-sizing: border-box;
	width: 100%;
	height: 250px;
	padding: 10px;
	margin-top: 45px;
	border: 2px solid #c6c6c6;
	resize: none;
	font-family: 'roboto', 'Helvetica', sans-serif;
	font-weight: bold;
	font-size: 14px;
	color: var(--dark-grey);
}

textArea:focus {
	outline: none;
	border: 2px solid var(--orange);
}

textArea.invalid:focus {
	outline: none;
	border: 2px solid rgb(170, 2, 2);
}

.checkboxes div {
	color: var(--medium-grey);
	padding: 5px 0;
	font-family: 'roboto', 'Helvetica', sans-serif;
	font-size: 12px;
}

.checkboxes label {
	display: flex;
	align-items: center;
}

input[type='checkbox']:focus {
	outline: 1px solid var(--orange);
}

input[type='checkbox'].invalid:focus {
	outline: 1px solid rgb(170, 2, 2);
}

.privacyPolicy:link,
.privacyPolicy:visited,
.privacyPolicy:hover {
	color: #2a2a2a;
}

.privacyPolicy:focus {
	color: var(--orange);
}

/* input[type='checkbox'] {
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  border-radius: 0px;
  border: 1px solid #979797;
} */

.separator {
	display: none;
}

.submitLine {
	display: block;
	margin: 30px auto;
	width: 350px;
	height: 2px;
	background-color: #c6c6c6;
}

input[type='submit'] {
	border: none;
	background-color: white;
	width: 100%;
	font-family: 'bradHITC', cursive;
	font-size: 25px;
	font-weight: 700;
	color: #4d4d4d;
}

input[type='submit']:hover {
	cursor: pointer;
}

input[type='submit']:focus {
	outline: none;
	color: var(--orange);
}

.divider {
	width: 1px;
	height: 625px;
	background-color: #979797;
}

.meetingSteps {
	max-width: 450px;
	margin-top: 30px;
}

/* h1 {
	margin-left: 62px;
	margin-bottom: 5px;
	font-size: 22px;
	color: var(--medium-grey);
} */

.error {
	font-family: 'roboto', 'Helvetica', sans-serif;
	font-size: 10px;
	color: rgb(170, 2, 2);
}

.emailInput .error {
	position: absolute;
}

.required {
	color: rgb(170, 2, 2);
}

.modalContentContainer {
	margin: auto;
	font-family: 'roboto', 'Helvetica', sans-serif;
	font-weight: bold;
	font-size: 18px;
	color: #787878;
	line-height: 23px;
	letter-spacing: -0.52px;
	text-align: center;
}

.modalContentContainer img.envelope {
	margin-top: 85px;
	margin-bottom: 40px;
}

.modalContentContainer p {
	width: 80%;
	margin: auto;
	outline: none;
}

.modalContentContainer .depthLine {
	margin: 54px 0;
}

.modalContentContainer .depthLine hr {
	margin: 0;
	height: 1px;
	background-color: #d8d8d8;
	border: none;
}
.modalContentContainer .depthLine hr:first-of-type {
	background-color: #eeeeee;
}

.modalContentContainer .depthLine p {
	margin-top: 25px;
	font-family: 'roboto', 'Helvetica', sans-serif;
	color: #787878;
	font-size: 15px;
	font-weight: 300;
	letter-spacing: -0.43px;
	line-height: 18px;
}

.modalContentContainer .linkButton {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 72px;
	width: 380px;
	margin: auto;
	margin-bottom: 55px;
	background-color: #f8f8f8;
	border: none;
	font-family: 'roboto', 'Helvetica', sans-serif;
	font-weight: bold;
	color: #242424;
	font-size: 17px;
	letter-spacing: 0.11px;
	line-height: 20px;
	text-align: center;
}

.modalContentContainer .linkButton:visited {
	color: #242424;
}

.modalContentContainer .linkButton:hover,
.modalContentContainer .linkButton:focus {
	background-color: var(--orange);
	color: white;
	border-color: var(--orange);
}

.modalContentContainer .linkButton:first-of-type {
	margin-bottom: 23px;
}

.srOnly {
	position: absolute;
	left: -10000px;
	top: auto;
	width: 1px;
	height: 1px;
	overflow: hidden;
}

@media screen and (max-width: 1260px) {
	.divider,
	.meetingSteps {
		display: none;
	}
}

@media screen and (max-width: 768px) {
	form {
		width: 90%;
		margin: auto;
	}

	.textArea {
		margin-top: 60px;
	}

	.textAreaContainer label {
		margin-top: 70px;
	}

	.submitLine {
		width: inherit;
		margin-top: 60px;
	}
}

@media screen and (max-width: 640px) {
	form {
		width: 85%;
		margin: auto;
	}
}

@media screen and (max-width: 620px) {
	form {
		width: 75%;
		margin: auto;
	}
}

@media screen and (max-width: 575px) {
	.contactPage {
		margin-top: 120px;
	}

	.wrapper {
		max-width: 85%;
		min-height: 660px;
	}

	.contactForm {
		width: 100%;
		margin: 0;
	}

	form {
		width: 100%;
	}

	.textInputs {
		flex-direction: column;
		margin: 0;
	}

	.inputContainer {
		width: 100%;
		margin-top: 25px;
	}

	.inputContainer:nth-child(1) {
		margin-right: 0;
	}

	.textAreaContainer {
		margin-top: 20px;
	}

	.textAreaContainer label {
		margin-top: 22px;
	}

	input[type='text'],
	input[type='email'] {
		padding-bottom: 20px;
		font-family: 'roboto', 'Helvetica', sans-serif;
		font-stretch: condensed;
	}

	.textArea {
		margin: 10px 0 25px;
		border: none;
		border-bottom: 2px solid #c6c6c6;
		font-family: 'roboto', 'Helvetica', sans-serif;
		font-stretch: condensed;
	}

	textArea:focus {
		outline: none;
		border: none;
		border-bottom: 2px solid var(--orange);
	}

	textArea.invalid:focus {
		outline: none;
		border: none;
		border-bottom: 2px solid rgb(170, 2, 2);
	}

	input[type='checkbox'] {
		margin-right: 15px;
	}

	label {
		font-family: 'roboto', 'Helvetica', sans-serif;
		font-stretch: condensed;
	}

	.endOfForm {
		display: flex;
		justify-content: center;
		position: relative;
		left: 50%;
		width: 125%;
		bottom: -25px;
		padding: 5vw;
		padding-top: 40px;
		padding-bottom: 35px;
		transform: skew(0deg, -2.5deg) translateX(-50%);
		-webkit-backface-visibility: hidden;
		backface-visibility: hidden;
		box-shadow: 0 -20px 12px -12px #e0dfdf78;
	}

	.endOfForm .mobileContainer {
		transform: skew(0deg, 2.5deg);
		-webkit-backface-visibility: hidden;
		backface-visibility: hidden;
		width: 100%;
		box-sizing: border-box;
	}

	.mobileContainer .submitLine {
		display: none;
	}

	.mobileContainer .checkboxes {
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: left;
		padding: 0 50px;
	}

	.mobileContainer .checkboxes label {
		margin-bottom: 5px;
	}

	.mobileContainer .separator {
		display: block;
		margin-right: auto;
		margin-left: auto;
		margin-top: 30px;
		margin-bottom: 20px;
		width: 60%;
	}
}
