.flexContainer {
	padding: 0 12px;
}

.packageContainer {
	box-sizing: content-box;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	max-width: 200px;
	padding: 20px;
	padding-top: 25px;
	background-color: white;
	border-radius: 25px;
	box-shadow: 0px 65px 34px -50px #d8d8d8;
}

.flexContainer:first-of-type {
	padding-left: 50px;
}

.flexContainer:last-of-type {
	padding-right: 50px;
}

.packageContainer .packageName {
	font-family: 'bradHITC', cursive;
	color: #4a4a4a;
	font-size: 22px;
	font-weight: bold;
	letter-spacing: -0.56px;
	line-height: 28px;
	text-transform: uppercase;
	text-align: center;
	margin: 0;
}

.packageImg {
	margin: 15px auto;
	display: flex;
	align-items: center;
	justify-content: center;
}

.price {
	display: flex;
	justify-content: center;
	align-items: flex-start;
	color: #4a4a4a;
	font-family: 'caviarDreams', 'Arial', sans-serif;
	font-weight: bold;
	font-size: 15px;
	letter-spacing: -0.38px;
	line-height: 19px;
}

.price .currency {
	text-transform: uppercase;
}

.price .amount {
	padding: 5px;
	font-family: 'roboto', 'Helvetica', sans-serif;
	font-size: 34px;
	font-weight: bold;
	letter-spacing: -0.86px;
	line-height: 40px;
}

.price .frequency {
	position: relative;
	bottom: -20px;
}

.divider {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 20px;
	margin-bottom: 25px;
}

.divider img {
	width: 200px;
}

.packageDetails {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 80%;
	margin-left: auto;
	color: #9b9b9b;
	font-family: 'dosisSemiBold', Arial, Helvetica, sans-serif;
	font-size: 16px;
	letter-spacing: -0.43px;
	line-height: 21px;
}

.packageDetails ul {
	list-style: none;
	padding-left: 0.5em;
	text-indent: -1.7em;
}

.packageDetails ul li {
	padding-bottom: 10px;
}

.packageDetails ul li:before {
	content: '✓';
	padding-right: 15px;
	font-weight: 600;
}

.packageContainer button {
	border: 2px solid var(--orange);
	padding: 13px;
	color: black;
	font-weight: bold;
	max-width: 180px;
	width: 100%;
	margin: 15px;
	margin-right: auto;
	margin-left: auto;
}

.packageContainer button:focus,
.packageContainer button:hover {
	background-color: var(--orange);
	color: white;
	font-weight: bold;
}

@media all and (max-width: 1194px) {
	.divider img {
		max-width: 190px;
	}
}

@media all and (max-width: 1154px) {
	.divider img {
		max-width: 170px;
	}
}

@media all and (max-width: 1069px) {
	.flexContainer {
		padding-left: 10px;
		padding-right: 10px;
	}
}

@media all and (max-width: 1049px) {
	.flexContainer {
		padding-left: 8px;
		padding-right: 8px;
	}
}

@media all and (max-width: 1034px) {
	.flexContainer {
		padding-left: 6px;
		padding-right: 6px;
	}
}

@media all and (min-width: 1024px) {
	.flexContainer:first-of-type {
		padding-left: 12px;
	}

	.flexContainer:last-of-type {
		padding-right: 12px;
	}
}

@media all and (max-width: 1023px) {
	.flexContainer {
		padding-left: 0;
		padding-right: 0;
		padding-bottom: 0;
	}

	.flexContainer:first-of-type {
		padding-left: 0;
	}

	.flexContainer:last-of-type {
		padding-right: 0;
	}

	.packageContainer {
		min-width: 190px;
	}

	.divider img {
		max-width: 190px;
	}
}

@media all and (max-width: 575px) {
	.flexContainer,
	.flexContainer:first-of-type,
	.flexContainer:last-of-type {
		padding: 0;
		padding-bottom: 34px;
	}
}

/* TODO: fix iphone 6/7/8 + carousel centering at 665px */
/* @media all and (max-height: 670px) and (max-width: 575px) {
	.packageContainer {
		min-width: 180px;
		padding-top: 13px;
		padding-bottom: 21px;
	}

	.packageContainer .packageName {
		font-size: 20px;
	}

	.packageImg {
		height: 100px;
	}

	.price {
		font-size: 12px;
	}

	.price .amount {
		font-size: 32px;
	}

	.price .frequency {
		font-size: 12px;
	}

	.divider {
		margin-top: 10px;
		margin-bottom: 20px;
	}

	.divider img {
		max-width: 180px;
	}

	.packageDetails {
		width: 90%;
		font-size: 14px;
	}

	.packageContainer button {
		padding: 11px;
		max-width: 170px;
	}
} */
