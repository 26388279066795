@import url(https://fonts.googleapis.com/css2?family=Handlee&display=swap);
* {
	box-sizing: border-box;
}

body {
	margin: 0;
	font-family: 'caviarDreams', 'Arial', sans-serif;
}

/* App styles */
.App {
	min-height: 100vh;
	overflow: hidden;
	position: relative;
	margin-left: 50px;
}

.App-header {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-justify-content: center;
	        justify-content: center;
	padding-top: 40px;
	background-color: white;
	width: 100%;
	font-family: 'roboto', 'Helvetica', sans-serif;
	font-weight: bold;
	font-size: 10px;
	color: var(--medium-grey);
}

/* .App-content.coverScreen {
	display: flex;
	align-items: center;
	flex-direction: column;
	min-height: calc(100vh - 128px);
} */

.App-content.center {
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
	min-height: calc(100vh - 128px);
}

@media all and (max-width: 1279px) {
	.App-content.portfolio {
		display: block;
	}
}

.App-content.centerHeight {
	min-height: calc(100vh - 256px);
}

.App-content.quote-tool {
	-webkit-flex-direction: column;
	        flex-direction: column;
}

.App-content button {
	border: none;
	background-color: transparent;
}

.App-content a {
	text-decoration: none;
	display: block;
	outline: none;
}

/* Focus state */
.App-content a:focus,
.App-content a:focus span {
	outline: none;
	color: var(--orange);
}

.App-content button:focus {
	outline: none;
	border: 2px solid var(--orange);
}

/* Hover state */

.App-content button:hover,
.App-content a:hover {
	cursor: pointer;
}

@media screen and (min-width: 991px) {
	.App-content.coverScreen {
		display: block;
	}
}

@media screen and (min-width: 576px) {
	.App-header.leftAlign {
		-webkit-align-items: flex-start;
		        align-items: flex-start;
		padding-left: 66px;
	}
}

@media screen and (max-width: 575px) {
	.App {
		margin-left: 0;
	}

	.App-content.center {
		min-height: calc(100vh - 50px);
		margin-bottom: 50px;
	}

	.App-header {
		padding-top: 0;
	}
}

.MobileNav_mobileNav__2f4ST {
	display: none;
}

.MobileNav_mobileNavSpacer__164uo {
	/* add gradient? */
	position: fixed;
	bottom: 0;
	margin-bottom: -8px;
	width: 100%;
	height: 8px;
	background-color: white;
}

.MobileNav_mobileNavContainer__2aOgG {
	background-color: #f3f3f3; /* #f1eeee */
}

.MobileNav_mobileNav__2f4ST ul {
	position: relative;
	display: -webkit-inline-flex;
	display: inline-flex;
	width: 100%;
	height: 50px;
	-webkit-flex-direction: row-reverse;
	        flex-direction: row-reverse;
	-webkit-justify-content: center;
	        justify-content: center;
	margin: 0;
	padding: 0;
}

.MobileNav_mobileNav__2f4ST li {
	position: relative;
	z-index: 2;
	list-style-type: none;
	box-shadow: 0 -14px 13px -20px grey inset;
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
	margin: 0.5px;
	width: 80%;
	background-color: white;
}

.MobileNav_mobileNav__2f4ST a {
	text-decoration: none;
	display: block;
	outline: none;
}

.MobileNav_mobileNav__2f4ST .MobileNav_tab__1idma {
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: space-around;
	        justify-content: space-around;
	-webkit-align-items: center;
	        align-items: center;
	height: 49px;
}

.MobileNav_mobileNav__2f4ST .MobileNav_icon__2MTqb,
.MobileNav_mobileNav__2f4ST .MobileNav_selectedIcon__1rLgX {
	-webkit-transform: rotate(270deg);
	        transform: rotate(270deg);
	width: 15px;
	height: 15px;
}

.MobileNav_mobileNav__2f4ST .MobileNav_icon__2MTqb.MobileNav_contact__2y472,
.MobileNav_mobileNav__2f4ST .MobileNav_selectedIcon__1rLgX.MobileNav_contact__2y472 {
	width: 13px;
	height: 13px;
}

.MobileNav_mobileNav__2f4ST .MobileNav_selectedIcon__1rLgX {
	display: none;
}

.MobileNav_mobileNav__2f4ST .MobileNav_selected__DNwLS .MobileNav_selectedIcon__1rLgX {
	display: block;
}

.MobileNav_mobileNav__2f4ST .MobileNav_selected__DNwLS .MobileNav_icon__2MTqb {
	display: none;
}

.MobileNav_mobileNav__2f4ST .MobileNav_selected__DNwLS {
	background: white;
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
	box-shadow: 0 8px 0px 0px white, 0 0px 0px 0px white, 5px 0 9px -4px #d6d6d6, -5px 0 9px -4px #d6d6d6;
}

@media screen and (max-width: 575px) {
	.MobileNav_mobileNav__2f4ST {
		display: block;
		position: fixed;
		margin-bottom: -50px;
		left: 100%;
		bottom: 0;
		width: 100vw;
		-webkit-transform: rotate(-180deg);
		        transform: rotate(-180deg);
		-webkit-transform-origin: top left;
		        transform-origin: top left;
		z-index: 11;
	}
}

.DesktopNav_desktopNav__2zUOl {
	position: fixed;
	left: 0;
	top: 100%;
	width: 100vh;
	-webkit-transform: rotate(-90deg);
	        transform: rotate(-90deg);
	-webkit-transform-origin: top left;
	        transform-origin: top left;
	z-index: 10;
}

.DesktopNav_desktopNav__2zUOl .DesktopNav_overlay__36atd {
	position: absolute;
	z-index: 1;
	width: 100%;
	height: 100%;
	box-shadow: inset 0 0 15px lightgray;
}

.DesktopNav_desktopNav__2zUOl ul {
	position: relative;
	display: -webkit-inline-flex;
	display: inline-flex;
	width: 100%;
	height: 47px;
	-webkit-flex-direction: row-reverse;
	        flex-direction: row-reverse;
	-webkit-justify-content: center;
	        justify-content: center;
	margin: 0;
	padding: 0;
}

.DesktopNav_desktopNav__2zUOl li {
	/* display: flex; */
	position: relative;
	z-index: 2;
	list-style-type: none;
	box-shadow: 0px -12px 12px lightgray;
	border-top-left-radius: 12px;
	border-top-right-radius: 12px;
	margin: 0px 3px;
	transition: 0.25s ease-in-out;
}

.DesktopNav_desktopNav__2zUOl a {
	text-decoration: none;
	display: block;
	outline: none;
}

.DesktopNav_desktopNav__2zUOl a:focus {
	outline: none;
}

.DesktopNav_desktopNav__2zUOl a:focus > .DesktopNav_tab__2ptAT {
	outline: 2px solid var(--orange);
}

.DesktopNav_desktopNav__2zUOl .DesktopNav_tab__2ptAT {
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
	width: 225px;
	height: 50px;
	outline: none;
}

.DesktopNav_desktopNav__2zUOl .DesktopNav_icon__2T7Oq {
	-webkit-transform: rotate(90deg);
	        transform: rotate(90deg);
	margin-right: 20px;
	width: 20px;
	height: 20px;
}

.DesktopNav_desktopNav__2zUOl .DesktopNav_linkText__1I9im {
	font-family: 'roboto', 'Helvetica', sans-serif;
	font-stretch: condensed;
	font-size: 15px;
	color: var(--light-grey);
}

.DesktopNav_desktopNav__2zUOl li:hover {
	z-index: 10;
	background: white;
	transition: 0.25s ease-in-out;
}

.DesktopNav_desktopNav__2zUOl li:hover .DesktopNav_linkText__1I9im {
	color: var(--medium-grey);
}

.DesktopNav_desktopNav__2zUOl .DesktopNav_selected__kM75I {
	background: white;
	border-top-left-radius: 12px;
	border-top-right-radius: 12px;
}

.DesktopNav_desktopNav__2zUOl .DesktopNav_underline__2auoC {
	display: none;
	position: absolute;
	-webkit-transform: rotate(90deg);
	        transform: rotate(90deg);
	/* top: 10%; */
	top: 0%;
	transition: 0.25s ease-in-out;
	/* width: 90px; */
	height: 90px;
}

.DesktopNav_desktopNav__2zUOl .DesktopNav_selected__kM75I .DesktopNav_underline__2auoC {
	display: block;
}

.DesktopNav_desktopNav__2zUOl .DesktopNav_selected__kM75I .DesktopNav_linkText__1I9im {
	color: var(--medium-grey);
}

@media screen and (max-height: 950px) {
	.DesktopNav_desktopNav__2zUOl .DesktopNav_tab__2ptAT {
		width: 175px;
	}
}

@media screen and (max-width: 575px) {
	.DesktopNav_desktopNav__2zUOl {
		display: none;
	}
}

.MobileHeader_container__3K_Oe,
.MobileHeader_spacer__uyN5s {
	display: none;
}

.MobileHeader_container__3K_Oe div {
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: flex-end;
	        justify-content: flex-end;
	transform: skew(0deg, 2.5deg);
	transform: skew(0deg, 2.5deg);
	-webkit-transform: skew(0deg, 2.5deg);
	-moz-transform: skew(0deg, 2.5deg);
	-o-transform: skew(0deg, 2.5deg);
	-ms-transform: skew(0deg, 2.5deg);
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
}

.MobileHeader_container__3K_Oe button {
	border: none;
	background-color: transparent;
	padding: 0;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: flex-end;
	        justify-content: flex-end;
	width: 100%;
}

.MobileHeader_mobileHeaderIcon__3COD7 {
	position: relative;
	top: 2px;
	/* left: -10px; TODO: only apply to back button over 320px */
	height: 40px;
	width: 40px;
}

.MobileHeader_mobileHeaderIcon__3COD7 > button {
	height: 40px;
	padding: 0;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
}

.MobileHeader_mobileHeaderIcon__3COD7 > button.MobileHeader_backBtn__2vhR_ {
	position: relative;
	left: -10px;
}

.MobileHeader_logo__31ciw {
	width: 40px;
}

.MobileHeader_headerTitle__4fxt3 {
	position: relative;
	top: 3px;
	padding-top: 7px;
}

h1.MobileHeader_header__3-4A4 {
	font-family: 'caviarDreams', 'Arial', sans-serif;
	font-weight: bold;
	font-size: 16px;
	letter-spacing: -0.23px;
	line-height: 21px;
	text-transform: uppercase;
	color: #3e3e3e;
	margin: 0 auto;
}

.MobileHeader_mobileHeaderMoreInfo__1zMoW {
	position: relative;
	top: 11px;
	width: 40px;
	height: 40px;
	padding-left: 12px;
}

.MobileHeader_mobileHeaderMoreInfo__1zMoW > button {
	height: 40px;
	padding: 0;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
}

@media screen and (max-width: 575px) {
	.MobileHeader_container__3K_Oe {
		display: -webkit-flex;
		display: flex;
		position: fixed;
		top: -15px;
		-webkit-align-items: center;
		        align-items: center;
		-webkit-justify-content: space-between;
		        justify-content: space-between;
		box-sizing: border-box;
		padding: 10px 25px;
		padding-right: 10px;
		min-width: 103%;
		height: 75px;
		background-color: #fafafa;
		z-index: 15;
		transform: skew(0deg, -2.5deg);
		transform: skew(0deg, -2.5deg);
		-webkit-transform: skew(0deg, -2.5deg);
		-moz-transform: skew(0deg, -2.5deg);
		-o-transform: skew(0deg, -2.5deg);
		-ms-transform: skew(0deg, -2.5deg);
		-webkit-backface-visibility: hidden;
		backface-visibility: hidden;
		box-shadow: 0 -10px 19px -14px #bfbebe inset;
	}

	.MobileHeader_spacer__uyN5s {
		/* add gradient */
		display: block;
		height: 25px; /*50px*/
		width: 100%;
		background-color: white;
		position: fixed;
		top: 40px;
		z-index: 14;
		-webkit-transform: skew(0deg, -2.5deg);
		        transform: skew(0deg, -2.5deg);
	}
}

@media screen and (max-width: 320px) {
	.MobileHeader_container__3K_Oe {
		padding: 10px 20px;
	}

	.MobileHeader_mobileHeaderIcon__3COD7 > button.MobileHeader_backBtn__2vhR_ {
		left: 0px;
	}
}

.DesktopHeader_container__1bE4A {
	display: block;
}

.DesktopHeader_desktopLogo__1Ym7A {
	display: block;
	margin-left: auto;
	margin-right: auto;
	height: 55px;
	pointer-events: none;
}

/* TODO: mobile starts at 425px or 550px? */
@media screen and (max-width: 575px) {
	.DesktopHeader_container__1bE4A {
		display: none;
	}
}

.Home_homePage__3PbGe {
	position: relative;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
	text-align: center;
	font-family: 'caviarDreams', 'Arial', sans-serif;
	min-height: 63vh;
	/* padding-bottom: 150px; */
}

.Home_wrapper__3LREN p {
	padding: 5px 20px;
	max-width: 450px;
	color: var(--light-grey);
	letter-spacing: -0.51px;
	line-height: 26px;
	z-index: -1;
	outline: none;
}

.Home_wrapper__3LREN .Home_quoteToolLinks__3GWMl {
	margin-top: 55px;
	margin-bottom: 55px;
}

.Home_wrapper__3LREN a {
	text-decoration: none;
	color: var(--medium-grey);
	font-size: 27px;
	font-family: 'bradHITC', cursive;
	font-weight: 700;
}

a:hover {
	color: var(--dark-grey);
}

/* a:hover {
  color: var(--light-grey);
}

a:hover > span {
  color: var(--medium-grey);
} */

.Home_spacer__2dpmT {
	height: 15px;
}

.Home_lineSeparator__3ke9J {
	width: 180px;
}

@media all and (max-height: 600px) {
	.Home_wrapper__3LREN .Home_quoteToolLinks__3GWMl {
		margin-bottom: 100px;
	}
}

@media all and (max-width: 575px) {
	.Home_homePage__3PbGe {
		margin: auto;
		padding-bottom: 15px;
		margin-top: 130px;
	}

	.Home_wrapper__3LREN p {
		padding: 5px 50px;
	}

	.Home_wrapper__3LREN a {
		font-size: 24px;
	}

	.Home_lineSeparator__3ke9J {
		width: 245px;
	}
}

.Modal_overlay__70BmZ {
  position: fixed;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  overflow: auto;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(circle, #ffffff 0%, rgba(0, 0, 0, 0.5) 100%);
  z-index: 9999;
  /* cursor: pointer; */
}

.Modal_content__1gCCE {
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 2px 12px 2px rgba(50, 50, 50, 0.5);
  max-height: 655px;
  max-width: 436px;
  padding: 0.5rem;
  position: relative;
}

button.Modal_closeBtn__3lzZc {
  position: absolute;
  right: 5px;
  width: 40px;
  height: 40px;
  padding: 10px;
}

button.Modal_closeBtn__3lzZc:hover {
  cursor: default;
}

button.Modal_closeBtn__3lzZc.Modal_mobile__lcjT0 img {
  width: 9px;
  height: 9px;
}

.Modal_content__1gCCE.Modal_desktop__mGTX9 {
  padding: 1rem;
}

button.Modal_closeBtn__3lzZc.Modal_desktop__mGTX9 {
  right: 15px;
}

.StepsBeforeMeeting_meetingSteps__rukpo {
	box-sizing: border-box;
	margin-left: auto;
	margin-right: auto;
	max-width: 450px;
	padding: 25px 50px 15px;
}

.StepsBeforeMeeting_meetingSteps__rukpo h1 {
	font-family: 'caviarDreams', 'Arial', sans-serif;
	line-height: 28px;
	letter-spacing: -0.32px;
	margin-left: 62px;
	margin-bottom: 5px;
	font-size: 22px;
	color: var(--medium-grey);
	outline: none;
}

.StepsBeforeMeeting_meetingSteps__rukpo .StepsBeforeMeeting_step__1j1_E {
	display: -webkit-flex;
	display: flex;
	max-width: 500px;
	padding: 30px 0;
	font-family: 'handleeRegular', cursive;
	font-size: 17px;
	letter-spacing: 0.5px;
	line-height: 23px;
	color: #939393;
}

.StepsBeforeMeeting_meetingSteps__rukpo .StepsBeforeMeeting_circle__uejEk {
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
	width: 34px;
	height: 34px;
	margin: 5px 25px 0 0;
	border: 3px solid #939393;
	border-radius: 50%;
	text-align: center;
	color: #939393;
}

.StepsBeforeMeeting_ctaMessage__ce_52 {
	margin: auto;
	max-width: 330px;
	width: 100%;
}

.StepsBeforeMeeting_ctaMessage__ce_52 p {
	font-family: 'robotoLight';
	font-size: 15px;
	text-align: center;
}

.StepsBeforeMeeting_ctaBtns__1TADR {
	padding: 5px 0 25px;
}

.StepsBeforeMeeting_ctaBtns__1TADR .StepsBeforeMeeting_linkButton__2KFRo {
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-justify-content: center;
	        justify-content: center;
	padding: 5px;
	margin: 10px auto;
	border: 2px solid var(--medium-grey);
	border-radius: 3px;
	height: 50px;
	max-width: 330px;
	width: 100%;
	font-family: 'roboto', 'Helvetica', sans-serif;
	font-weight: bold;
	font-size: 16px;
}

.StepsBeforeMeeting_ctaBtns__1TADR .StepsBeforeMeeting_linkButton__2KFRo:visited {
	color: #242424;
}

.StepsBeforeMeeting_ctaBtns__1TADR .StepsBeforeMeeting_linkButton__2KFRo:hover,
.StepsBeforeMeeting_ctaBtns__1TADR .StepsBeforeMeeting_linkButton__2KFRo:focus {
	background-color: var(--orange);
	color: white;
	border-color: var(--orange);
}

.StepsBeforeMeeting_modalContentContainer___xEdU {
	margin: auto;
	width: 230px;
	height: 277px;
	font-family: 'robotoLight', cursive;
	font-size: 15px;
	font-weight: 300px;
	color: #787878;
	line-height: 13px;
	letter-spacing: -0.43px;
	text-align: center;
}

.StepsBeforeMeeting_modalContentContainer___xEdU img.StepsBeforeMeeting_envelope__642Zo {
	margin-top: 60px;
	margin-bottom: 25px;
	height: 62.82px;
	width: 77px;
}

.StepsBeforeMeeting_modalContentContainer___xEdU p {
	width: 80%;
	margin: auto;
}

.StepsBeforeMeeting_modalContentContainer___xEdU .StepsBeforeMeeting_depthLine__xJ9aH {
	margin: 35px 5px;
}

.StepsBeforeMeeting_modalContentContainer___xEdU .StepsBeforeMeeting_depthLine__xJ9aH hr {
	margin: 0;
	height: 1px;
	background-color: #d8d8d8;
	border: none;
}

.StepsBeforeMeeting_modalContentContainer___xEdU .StepsBeforeMeeting_depthLine__xJ9aH hr:first-of-type {
	background-color: #eeeeee;
}

.StepsBeforeMeeting_modalContentContainer___xEdU .StepsBeforeMeeting_depthLine__xJ9aH p {
	margin-top: 25px;
	font-family: 'roboto', 'Helvetica', sans-serif;
	color: #796868;
	font-size: 12px;
	font-weight: 100;
	letter-spacing: -0.35px;
	line-height: 14px;
}

@media screen and (min-width: 1261px) {
	.StepsBeforeMeeting_meetingSteps__rukpo.StepsBeforeMeeting_desktop__3BZ4H {
		margin-right: 0;
		margin-left: 0;
		padding: 0;
	}
	.StepsBeforeMeeting_ctaMessage__ce_52.StepsBeforeMeeting_desktop__3BZ4H,
	.StepsBeforeMeeting_ctaBtns__1TADR.StepsBeforeMeeting_desktop__3BZ4H {
		display: none;
	}
}

@media screen and (max-width: 575px) {
	.StepsBeforeMeeting_meetingSteps__rukpo {
		margin-top: 80px;
		padding-top: 20px;
	}

	.StepsBeforeMeeting_ctaBtns__1TADR {
		padding: 15px 0 80px;
	}
}

@media screen and (max-width: 425px) {
	.StepsBeforeMeeting_meetingSteps__rukpo h1 {
		margin-left: 0;
		margin-bottom: 15px;
		text-align: center;
	}

	.StepsBeforeMeeting_meetingSteps__rukpo .StepsBeforeMeeting_step__1j1_E {
		padding: 25px 0;
	}

	.StepsBeforeMeeting_meetingSteps__rukpo .StepsBeforeMeeting_circle__uejEk {
		width: 30px;
		height: 30px;
	}

	.StepsBeforeMeeting_ctaMessage__ce_52,
	.StepsBeforeMeeting_ctaBtns__1TADR .StepsBeforeMeeting_linkButton__2KFRo {
		width: 90%;
	}
}

@media screen and (max-width: 350px) {
	.StepsBeforeMeeting_meetingSteps__rukpo {
		padding: 20px 35px 15px;
	}

	.StepsBeforeMeeting_meetingSteps__rukpo h1 {
		margin-bottom: 10px;
		text-align: left;
	}

	.StepsBeforeMeeting_meetingSteps__rukpo .StepsBeforeMeeting_circle__uejEk {
		width: 25px;
		height: 25px;
		margin: 5px 20px 0 0;
		border-width: 2px;
	}
}

.Contact_contactPage__2cUDm {
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
	margin-top: 50px;
	min-height: 72vh;
	font-family: 'caviarDreams', 'Arial', sans-serif;
	color: var(--light-grey);
	padding-bottom: 84px;
	z-index: -1;
}

.Contact_wrapper__1VBrM {
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: space-evenly;
	        justify-content: space-evenly;
	width: 1350px;
	margin: auto;
}

.Contact_contactForm__3KcSw {
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	color: var(--medium-grey);
	font-family: 'roboto', 'Helvetica', sans-serif;
	font-weight: bold;
	font-size: 14px;
}

/* Change the autofill color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	box-shadow: 0 0 0 35px white inset;
	-webkit-text-fill-color: var(--dark-grey);
}

.Contact_textInputs__2ChGh {
	display: -webkit-flex;
	display: flex;
	margin-bottom: 20px;
}

/* input[type='text']::placeholder {
  font-family: 'roboto', 'Helvetica', sans-serif;
  font-weight: bold;
  font-size: 14px;
} */

input[type='text'],
input[type='email'] {
	border: none;
	border-bottom: 2px solid #c6c6c6;
	width: 100%;
	box-sizing: border-box;
	padding-left: 10px;
	padding-bottom: 15px;
	margin-bottom: 5px;
	font-family: 'roboto', 'Helvetica', sans-serif;
	font-weight: bold;
	font-size: 14px;
	color: var(--dark-grey);
}

input[type='text']:focus,
input[type='email']:focus {
	outline: none;
	border-bottom: 2px solid var(--orange);
}

input[type='text'].Contact_invalid__RdrrL:focus,
input[type='email'].Contact_invalid__RdrrL:focus {
	outline: none;
	border-bottom: 2px solid rgb(170, 2, 2);
}

.Contact_inputContainer__3rOBd {
	position: relative;
	width: 250px;
	margin-top: 5px;
}

.Contact_inputContainer__3rOBd:nth-child(1) {
	margin-right: 50px;
}

.Contact_inputContainer__3rOBd label {
	position: absolute;
	margin-left: 10px;
	color: #797575;
}

.Contact_emailInput__rzQTm .Contact_inputContainer__3rOBd {
	width: 100%;
}

.Contact_textAreaContainer__2u7oL {
	position: relative;
}

.Contact_textAreaContainer__2u7oL label {
	position: absolute;
	margin-left: 12px;
	margin-top: 55px;
	color: #797575;
}

.Contact_textArea__Qy5CK {
	box-sizing: border-box;
	width: 100%;
	height: 250px;
	padding: 10px;
	margin-top: 45px;
	border: 2px solid #c6c6c6;
	resize: none;
	font-family: 'roboto', 'Helvetica', sans-serif;
	font-weight: bold;
	font-size: 14px;
	color: var(--dark-grey);
}

textArea:focus {
	outline: none;
	border: 2px solid var(--orange);
}

textArea.Contact_invalid__RdrrL:focus {
	outline: none;
	border: 2px solid rgb(170, 2, 2);
}

.Contact_checkboxes__2jLnf div {
	color: var(--medium-grey);
	padding: 5px 0;
	font-family: 'roboto', 'Helvetica', sans-serif;
	font-size: 12px;
}

.Contact_checkboxes__2jLnf label {
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
}

input[type='checkbox']:focus {
	outline: 1px solid var(--orange);
}

input[type='checkbox'].Contact_invalid__RdrrL:focus {
	outline: 1px solid rgb(170, 2, 2);
}

.Contact_privacyPolicy__2m9sr:link,
.Contact_privacyPolicy__2m9sr:visited,
.Contact_privacyPolicy__2m9sr:hover {
	color: #2a2a2a;
}

.Contact_privacyPolicy__2m9sr:focus {
	color: var(--orange);
}

/* input[type='checkbox'] {
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  border-radius: 0px;
  border: 1px solid #979797;
} */

.Contact_separator__3b6rW {
	display: none;
}

.Contact_submitLine__24Wyc {
	display: block;
	margin: 30px auto;
	width: 350px;
	height: 2px;
	background-color: #c6c6c6;
}

input[type='submit'] {
	border: none;
	background-color: white;
	width: 100%;
	font-family: 'bradHITC', cursive;
	font-size: 25px;
	font-weight: 700;
	color: #4d4d4d;
}

input[type='submit']:hover {
	cursor: pointer;
}

input[type='submit']:focus {
	outline: none;
	color: var(--orange);
}

.Contact_divider__YaY2z {
	width: 1px;
	height: 625px;
	background-color: #979797;
}

.Contact_meetingSteps__eFOJz {
	max-width: 450px;
	margin-top: 30px;
}

/* h1 {
	margin-left: 62px;
	margin-bottom: 5px;
	font-size: 22px;
	color: var(--medium-grey);
} */

.Contact_error__2DMVd {
	font-family: 'roboto', 'Helvetica', sans-serif;
	font-size: 10px;
	color: rgb(170, 2, 2);
}

.Contact_emailInput__rzQTm .Contact_error__2DMVd {
	position: absolute;
}

.Contact_required__2qWTG {
	color: rgb(170, 2, 2);
}

.Contact_modalContentContainer__1SkTy {
	margin: auto;
	font-family: 'roboto', 'Helvetica', sans-serif;
	font-weight: bold;
	font-size: 18px;
	color: #787878;
	line-height: 23px;
	letter-spacing: -0.52px;
	text-align: center;
}

.Contact_modalContentContainer__1SkTy img.Contact_envelope__36PX5 {
	margin-top: 85px;
	margin-bottom: 40px;
}

.Contact_modalContentContainer__1SkTy p {
	width: 80%;
	margin: auto;
	outline: none;
}

.Contact_modalContentContainer__1SkTy .Contact_depthLine__3r-CR {
	margin: 54px 0;
}

.Contact_modalContentContainer__1SkTy .Contact_depthLine__3r-CR hr {
	margin: 0;
	height: 1px;
	background-color: #d8d8d8;
	border: none;
}
.Contact_modalContentContainer__1SkTy .Contact_depthLine__3r-CR hr:first-of-type {
	background-color: #eeeeee;
}

.Contact_modalContentContainer__1SkTy .Contact_depthLine__3r-CR p {
	margin-top: 25px;
	font-family: 'roboto', 'Helvetica', sans-serif;
	color: #787878;
	font-size: 15px;
	font-weight: 300;
	letter-spacing: -0.43px;
	line-height: 18px;
}

.Contact_modalContentContainer__1SkTy .Contact_linkButton__QnTgd {
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-justify-content: center;
	        justify-content: center;
	height: 72px;
	width: 380px;
	margin: auto;
	margin-bottom: 55px;
	background-color: #f8f8f8;
	border: none;
	font-family: 'roboto', 'Helvetica', sans-serif;
	font-weight: bold;
	color: #242424;
	font-size: 17px;
	letter-spacing: 0.11px;
	line-height: 20px;
	text-align: center;
}

.Contact_modalContentContainer__1SkTy .Contact_linkButton__QnTgd:visited {
	color: #242424;
}

.Contact_modalContentContainer__1SkTy .Contact_linkButton__QnTgd:hover,
.Contact_modalContentContainer__1SkTy .Contact_linkButton__QnTgd:focus {
	background-color: var(--orange);
	color: white;
	border-color: var(--orange);
}

.Contact_modalContentContainer__1SkTy .Contact_linkButton__QnTgd:first-of-type {
	margin-bottom: 23px;
}

.Contact_srOnly__3V2i1 {
	position: absolute;
	left: -10000px;
	top: auto;
	width: 1px;
	height: 1px;
	overflow: hidden;
}

@media screen and (max-width: 1260px) {
	.Contact_divider__YaY2z,
	.Contact_meetingSteps__eFOJz {
		display: none;
	}
}

@media screen and (max-width: 768px) {
	form {
		width: 90%;
		margin: auto;
	}

	.Contact_textArea__Qy5CK {
		margin-top: 60px;
	}

	.Contact_textAreaContainer__2u7oL label {
		margin-top: 70px;
	}

	.Contact_submitLine__24Wyc {
		width: inherit;
		margin-top: 60px;
	}
}

@media screen and (max-width: 640px) {
	form {
		width: 85%;
		margin: auto;
	}
}

@media screen and (max-width: 620px) {
	form {
		width: 75%;
		margin: auto;
	}
}

@media screen and (max-width: 575px) {
	.Contact_contactPage__2cUDm {
		margin-top: 120px;
	}

	.Contact_wrapper__1VBrM {
		max-width: 85%;
		min-height: 660px;
	}

	.Contact_contactForm__3KcSw {
		width: 100%;
		margin: 0;
	}

	form {
		width: 100%;
	}

	.Contact_textInputs__2ChGh {
		-webkit-flex-direction: column;
		        flex-direction: column;
		margin: 0;
	}

	.Contact_inputContainer__3rOBd {
		width: 100%;
		margin-top: 25px;
	}

	.Contact_inputContainer__3rOBd:nth-child(1) {
		margin-right: 0;
	}

	.Contact_textAreaContainer__2u7oL {
		margin-top: 20px;
	}

	.Contact_textAreaContainer__2u7oL label {
		margin-top: 22px;
	}

	input[type='text'],
	input[type='email'] {
		padding-bottom: 20px;
		font-family: 'roboto', 'Helvetica', sans-serif;
		font-stretch: condensed;
	}

	.Contact_textArea__Qy5CK {
		margin: 10px 0 25px;
		border: none;
		border-bottom: 2px solid #c6c6c6;
		font-family: 'roboto', 'Helvetica', sans-serif;
		font-stretch: condensed;
	}

	textArea:focus {
		outline: none;
		border: none;
		border-bottom: 2px solid var(--orange);
	}

	textArea.Contact_invalid__RdrrL:focus {
		outline: none;
		border: none;
		border-bottom: 2px solid rgb(170, 2, 2);
	}

	input[type='checkbox'] {
		margin-right: 15px;
	}

	label {
		font-family: 'roboto', 'Helvetica', sans-serif;
		font-stretch: condensed;
	}

	.Contact_endOfForm__1sMQK {
		display: -webkit-flex;
		display: flex;
		-webkit-justify-content: center;
		        justify-content: center;
		position: relative;
		left: 50%;
		width: 125%;
		bottom: -25px;
		padding: 5vw;
		padding-top: 40px;
		padding-bottom: 35px;
		-webkit-transform: skew(0deg, -2.5deg) translateX(-50%);
		        transform: skew(0deg, -2.5deg) translateX(-50%);
		-webkit-backface-visibility: hidden;
		backface-visibility: hidden;
		box-shadow: 0 -20px 12px -12px #e0dfdf78;
	}

	.Contact_endOfForm__1sMQK .Contact_mobileContainer__2_p2X {
		-webkit-transform: skew(0deg, 2.5deg);
		        transform: skew(0deg, 2.5deg);
		-webkit-backface-visibility: hidden;
		backface-visibility: hidden;
		width: 100%;
		box-sizing: border-box;
	}

	.Contact_mobileContainer__2_p2X .Contact_submitLine__24Wyc {
		display: none;
	}

	.Contact_mobileContainer__2_p2X .Contact_checkboxes__2jLnf {
		position: relative;
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: column;
		        flex-direction: column;
		-webkit-justify-content: left;
		        justify-content: left;
		padding: 0 50px;
	}

	.Contact_mobileContainer__2_p2X .Contact_checkboxes__2jLnf label {
		margin-bottom: 5px;
	}

	.Contact_mobileContainer__2_p2X .Contact_separator__3b6rW {
		display: block;
		margin-right: auto;
		margin-left: auto;
		margin-top: 30px;
		margin-bottom: 20px;
		width: 60%;
	}
}

.PageFlipFooter_pageFlipFooter__r7fB2 {
	position: absolute;
	bottom: 0;
	height: 252px;
	width: 100%;
	z-index: 1;
}

.PageFlipFooter_pageFlip__UqZa7 {
	position: relative;
	height: 100%;
}

.PageFlipFooter_pageFlip__UqZa7 img {
	position: absolute;
	right: 0;
	bottom: 0;
	max-width: 515px;
	width: 85%;
}

.PageFlipFooter_pageFlip__UqZa7 a {
	position: absolute;
	right: 2%;
	bottom: 2%;
}

.PageFlipFooter_question__2vEUI {
	display: inline-block;
	text-align: right;
	font-family: 'bradHITC', cursive;
	font-size: 17px;
	font-weight: 700;
	color: var(--dark-grey);
	margin-right: 20px;
	line-height: 1.7;
}

.PageFlipFooter_questionMark__3qhjt {
	font-family: 'bradHITC', cursive;
	font-size: 75px;
	font-weight: 700;
	color: var(--dark-grey);
}

@media screen and (max-height: 650px) and (max-width: 1024px) {
	.PageFlipFooter_pageFlip__UqZa7 img {
		max-width: 400px;
	}

	.PageFlipFooter_pageFlip__UqZa7 a {
		bottom: 0;
	}

	.PageFlipFooter_question__2vEUI {
		font-size: 16px;
		line-height: 1.5;
		margin-right: 15px;
	}

	.PageFlipFooter_questionMark__3qhjt {
		font-size: 65px;
	}
}

@media screen and (max-width: 850px) {
	.PageFlipFooter_pageFlip__UqZa7 img {
		max-width: 400px;
	}

	.PageFlipFooter_pageFlip__UqZa7 a {
		bottom: 0;
	}

	.PageFlipFooter_question__2vEUI {
		font-size: 16px;
		line-height: 1.5;
		margin-right: 15px;
	}

	.PageFlipFooter_questionMark__3qhjt {
		font-size: 65px;
	}
}

@media screen and (max-width: 575px) {
	.PageFlipFooter_pageFlipFooter__r7fB2 {
		display: none;
	}
}

.CornerFooter_cornerContainer__1Mr9G {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.CornerFooter_greyCorner__2oINv {
  position: relative;
  height: 100%;
}

.CornerFooter_greyCorner__2oINv img {
  position: absolute;
  right: 0;
  bottom: 0;
  max-width: 350px;
  width: 100%;
}

@media screen and (max-width: 1500px) {
  .CornerFooter_cornerContainer__1Mr9G {
    position: absolute;
    right: 0;
    bottom: 0;
    max-width: 250px;
    width: 100%;
  }
}

/* @media screen and (max-width: 1025px) { */
@media screen and (max-width: 1260px) {
  .CornerFooter_cornerContainer__1Mr9G {
    display: none;
  }
}

.Accordion_section__29OoX {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	margin: 20px 0px;
}

.Accordion_accordion__zq70M {
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
	-webkit-align-items: center;
	        align-items: center;
	background-color: transparent;
	padding: 5px;
}

.Accordion_accordion__zq70M:hover {
	cursor: pointer;
}

.Accordion_accordion__zq70M > .Accordion_title__2Nuhi {
	font-size: 30px;
	margin: 0;
	font-family: 'bradHITC', cursive;
	text-align: left;
	color: var(--medium-grey);
}

.Accordion_title__2Nuhi:hover {
	color: var(--dark-grey);
}

.Accordion_accordion__zq70M > .Accordion_icon__3nLy9 {
	width: 13px;
	height: 13px;
}

.Accordion_content__3PaJ0 {
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	overflow: hidden;
	transition: max-height 0.6s ease-in-out;
}

.Scrollable_scrollableWrapper__HFCGY {
	display: -webkit-flex;
	display: flex;
	padding: 0 20px;
	padding-right: 0;
}

.Scrollable_scrollableViewport__2wjyB {
	display: -webkit-flex;
	display: flex;
	width: 100%;
	overflow: hidden;
	position: relative;
	-ms-scroll-chaining: none;
	    overscroll-behavior: contain;
}

.Scrollable_scrollableContent__2mYAk {
	position: absolute;
	top: 0px;
	bottom: 0;
	left: 0;
	right: -40px;
	padding-right: 40px;
	overflow-x: hidden;
	overflow-y: scroll;
}

.Scrollable_scrollableContent__2mYAk.Scrollable_noScroll__2haHI {
	position: static;
	padding-right: unset;
	overflow-x: unset;
	overflow-y: unset;
}

.Scrollable_scrollbar__2iXmZ {
	display: -webkit-flex;
	display: flex;
	position: relative;
	right: 0;
	width: 20px;
	margin: 20px 0;
}

.Scrollable_scrollbar__2iXmZ.Scrollable_hidden__27mf9 {
	display: none;
}

.Scrollable_scrollbarTrack__3VITG {
	position: absolute;
	width: 1px;
	height: 100%;
	background-color: #ccc;
	left: 50%;
	-webkit-transform: translateX(-50%);
	        transform: translateX(-50%);
}

.Scrollable_scrollbarThumb__3_l3l {
	position: absolute;
	height: 30px;
	width: 8px;
	left: 50%;
	background-color: white;
	-webkit-transform: translateX(-50%);
	        transform: translateX(-50%);
	border-radius: 50px;
	box-shadow: 0px 2px 6px #ccc;
}

/* TODO: Position in the center of the page when there's enough space */
.DesktopQuoteTool_quoteTool__3ioPb {
	position: relative;
	display: grid;
	grid-template-columns: 0.8fr 1px 1.2fr 1px minmax(200px, 1fr);
	-webkit-align-items: center;
	        align-items: center;

	min-height: 63vh;
	margin: 0 auto;
	padding: 60px 15px;

	max-width: 1500px;

	font-family: 'caviarDreams', 'Arial', sans-serif;
}

/** Accordions and products under accordions  */

.DesktopQuoteTool_categories__1Yjcn {
	padding: 0 80px;
	padding-left: 30px;
	min-width: 200px;
}

.DesktopQuoteTool_categories__1Yjcn .DesktopQuoteTool_products__3Ew6z {
	font-family: 'caviarDreams', 'Arial', sans-serif;
	padding: 10px;
	max-width: 200px;
	text-align: right;
	font-size: 22px;
	font-weight: normal;
}

.DesktopQuoteTool_products__3Ew6z input {
	display: none;
}

.DesktopQuoteTool_products__3Ew6z .DesktopQuoteTool_productSelection__2UTVu {
	font-family: 'caviarDreams', 'Arial', sans-serif;
	display: block;
	margin: 10px;
	font-weight: 300;
	color: var(--divider-grey);
	white-space: nowrap;
	text-align: right;
	font-size: 22px;
}

.DesktopQuoteTool_products__3Ew6z input[type='checkbox']:checked + .DesktopQuoteTool_productSelection__2UTVu {
	color: var(--orange);
}

.DesktopQuoteTool_products__3Ew6z .DesktopQuoteTool_productSelection__2UTVu:hover,
.DesktopQuoteTool_products__3Ew6z .DesktopQuoteTool_productSelection__2UTVu:active {
	font-family: 'caviarDreams', 'Arial', sans-serif;
	color: var(--dark-grey);
}

/** Divider */

.DesktopQuoteTool_divider__1Buhf {
	-webkit-align-self: stretch;
	        align-self: stretch;
	width: 1px;
	min-width: 1px;
	background-color: var(--divider-grey);
}

/** Descriptions (before any product is selected) */

.DesktopQuoteTool_descriptions__1DJVe {
	position: relative;
	grid-column-start: 3;
	grid-column-end: 6;

	/* max-width: 850px; */
	padding-right: 75px;
	padding-left: 75px;

	color: var(--medium-grey);
	font-family: 'Handlee', sans-serif;
}

.DesktopQuoteTool_descriptions__1DJVe .DesktopQuoteTool_description__2fv3I {
	position: relative;
}

.DesktopQuoteTool_description__2fv3I.DesktopQuoteTool_basic__1E9MU {
	height: 280px;
	-webkit-transform: rotate(-1deg);
	        transform: rotate(-1deg);
}

.DesktopQuoteTool_description__2fv3I.DesktopQuoteTool_plus__KfEq4 {
	-webkit-transform: rotate(4deg);
	        transform: rotate(4deg);
	height: 230px;
}

.DesktopQuoteTool_description__2fv3I.DesktopQuoteTool_premium__O4rmu {
	-webkit-transform: rotate(-3deg);
	        transform: rotate(-3deg);
}

.DesktopQuoteTool_descriptions__1DJVe .DesktopQuoteTool_detailedContent__2pMFS {
	text-align: left;
	font-size: 22px;
}

.DesktopQuoteTool_detailedContent__2pMFS .DesktopQuoteTool_title__-Iew8 {
	display: inline;
	vertical-align: bottom;
	padding-right: 30px;

	font-size: 40px;
	color: var(--dark-grey);
}

.DesktopQuoteTool_detailedContent__2pMFS .DesktopQuoteTool_details__1DZ3b {
	display: inline;
	font-size: 18px;
	letter-spacing: 1px;
}

.DesktopQuoteTool_descriptions__1DJVe .DesktopQuoteTool_highlighted__3vYOr {
	color: var(--orange);
}

.DesktopQuoteTool_descriptions__1DJVe .DesktopQuoteTool_priceRange__HSeuO {
	margin-top: 25px;
	font-size: 18px;
	letter-spacing: 1px;
}

.DesktopQuoteTool_descriptions__1DJVe .DesktopQuoteTool_separator__zqHvk {
	padding: 0px 20px;
}

/** Add On Wrapper (Second Column) */

.DesktopQuoteTool_addOnWrapper__3MFU- {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	padding: 50px 80px;
	min-width: 290px;
	min-height: 800px;
	-webkit-justify-content: center;
	        justify-content: center;
}

.DesktopQuoteTool_addOnWrapper__3MFU- .DesktopQuoteTool_addOns__33UL6 {
	margin-bottom: 30px;
	padding: 0 10%;
	position: relative;
}

.DesktopQuoteTool_addOns__33UL6 .DesktopQuoteTool_curved__2A_fX {
	width: 120%;
	position: relative;
	top: -10px;
	left: -10%;
}

.DesktopQuoteTool_curved__2A_fX > img {
	width: 100%;
}

.DesktopQuoteTool_addOnWrapper__3MFU- .DesktopQuoteTool_quoteAmount__GmHOt {
	margin: 30px 0px;
	padding-left: 15%;

	color: var(--divider-grey);
	font-size: 23px;
	line-height: 1.5;
}

.DesktopQuoteTool_addOnWrapper__3MFU- .DesktopQuoteTool_disclaimer__r6Yy1 {
	margin: 20px 0px;
	color: var(--divider-grey);
	font-size: 19px;
	line-height: 25px;
}

.DesktopQuoteTool_addOnWrapper__3MFU- .DesktopQuoteTool_cta__3hesa {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-align-items: center;
	        align-items: center;
	margin-top: 40px;
}

.DesktopQuoteTool_addOnWrapper__3MFU- .DesktopQuoteTool_cta__3hesa img {
	max-width: 100%;
	display: block;
	-webkit-align-self: center;
	        align-self: center;
}

.DesktopQuoteTool_addOnWrapper__3MFU- .DesktopQuoteTool_cta__3hesa .DesktopQuoteTool_requestConsultation__4WXao {
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-justify-content: center;
	        justify-content: center;
	width: 100%;
	height: 80px;
	margin-top: 20px;
}

.DesktopQuoteTool_requestConsultation__4WXao > .DesktopQuoteTool_requestLink__6vNP8 {
	font-family: 'bradHITC', cursive;
	font-size: 26px;
	color: var(--medium-grey);
}

/** Add On Description (Third Column)  */

.DesktopQuoteTool_addOnDescriptions__1adKm {
	padding: 0 80px;
	padding-right: 30px;
}

.DesktopQuoteTool_addOnDescriptions__1adKm .DesktopQuoteTool_addOnTitle__3P2r7 {
	font-family: 'bradHITC', cursive;
	font-size: 30px;
	margin-bottom: 80px;
	color: var(--medium-grey);
}

.DesktopQuoteTool_addOnDescriptions__1adKm .DesktopQuoteTool_description__2fv3I {
	font-size: 22px;
	margin-bottom: 120px;
	color: var(--divider-grey);
	line-height: 30px;
}

.DesktopQuoteTool_addOnDescriptions__1adKm .DesktopQuoteTool_addOnIcon__tbVzj img {
	width: 100%;
}

.DesktopQuoteTool_hidden__zvB5m {
	display: none !important;
}

/** Breakpoints */

@media all and (max-width: 1300px) {
	.DesktopQuoteTool_categories__1Yjcn {
		padding: 0 30px;
	}

	.DesktopQuoteTool_descriptions__1DJVe {
		padding: 0 30px;
	}

	.DesktopQuoteTool_addOnWrapper__3MFU- {
		padding: 20px 30px;
	}

	.DesktopQuoteTool_addOnWrapper__3MFU- .DesktopQuoteTool_addOns__33UL6 {
		padding: 0 8%;
	}

	.DesktopQuoteTool_addOnDescriptions__1adKm {
		padding: 0 30px;
	}

	.DesktopQuoteTool_addOnWrapper__3MFU- .DesktopQuoteTool_quoteAmount__GmHOt {
		padding-left: 0;
		text-align: center;
	}

	.DesktopQuoteTool_addOnDescriptions__1adKm .DesktopQuoteTool_description__2fv3I {
		font-size: 19px;
		line-height: 25px;
	}
}

@media all and (max-width: 1110px) {
	.DesktopQuoteTool_addOnWrapper__3MFU- .DesktopQuoteTool_addOns__33UL6 {
		padding: 0 5%;
	}
}

.Checkbox_checkboxContainer__1lni0 {
	display: block;
	width: 100%;
	margin: 40px 0px;
}

.Checkbox_checkboxContainer__1lni0:first-of-type {
	margin-top: 0;
}

.Checkbox_checkboxContainer__1lni0:last-of-type {
	margin-bottom: 0;
}
.Checkbox_checkmark__1C71g {
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
}

.Checkbox_checkmark__1C71g > img {
	cursor: pointer;
}

.Checkbox_checkmark__1C71g > .Checkbox_text__3thK2 {
	font-size: 20px;
	padding-left: 30px;
	color: var(--medium-grey);
	font-family: 'caviarDreams', 'Arial', sans-serif;
}

@media screen and (max-width: 575px) {
	.Checkbox_checkmark__1C71g > img {
		width: 25px;
	}

	.Checkbox_checkmark__1C71g > .Checkbox_text__3thK2 {
		font-size: 18px;
	}
}

.MobileQuoteTool_quoteTool__3H21Q {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-align-items: center;
	        align-items: center;
	margin: 0 30px;
	-webkit-flex-grow: 1;
	        flex-grow: 1;
	width: calc(100% - 60px);
}

.MobileQuoteTool_hidden__2V1Q6 {
	display: none !important;
}

.MobileQuoteTool_nextButton__2EvPg {
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
	max-width: 550px;
	width: 100%;
	margin-top: auto;
	padding: 30px 0;
	border-top: 2px solid var(--light-divider-grey);
}

.MobileQuoteTool_next__2dZFG {
	font-size: 23px;
	font-family: 'bradHITC', cursive;
	color: var(--dark);
	font-weight: bold;
	line-height: 20px;
	max-width: 200px;
}

.MobileQuoteTool_requestConsultation__49qpE {
	font-size: 23px;
	font-family: 'bradHITC', cursive;
	color: var(--dark);
	font-weight: bold;
	line-height: 20px;
	padding: 20px;
}

/* Category Selections */

.MobileQuoteTool_categories__2gND- {
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: space-evenly;
	        justify-content: space-evenly;
	-webkit-align-items: center;
	        align-items: center;
	width: 100%;
	max-width: 550px;
	padding-bottom: 16px;
	border-bottom: 2px solid var(--light-divider-grey);
	margin: 36px 0 0;
}

.MobileQuoteTool_products__1SnyO {
	display: -webkit-flex;
	display: flex;
	/* justify-content: space-evenly; */
	-webkit-align-items: center;
	        align-items: center;
	width: 100%;
	max-width: 550px;
	overflow-x: scroll;
	padding-bottom: 16px;
	border-bottom: 2px solid var(--light-divider-grey);
	margin: 36px 0 0;
}

.MobileQuoteTool_categories__2gND- .MobileQuoteTool_hiddenRadioSelection__3bcqj input[type='radio'],
.MobileQuoteTool_products__1SnyO .MobileQuoteTool_hiddenRadioSelection__3bcqj input[type='radio'] {
	display: none;
}

.MobileQuoteTool_categories__2gND- .MobileQuoteTool_hiddenRadioSelection__3bcqj label,
.MobileQuoteTool_products__1SnyO .MobileQuoteTool_hiddenRadioSelection__3bcqj label {
	font-family: 'bradHITC', cursive;
	color: var(--medium-grey);
	cursor: pointer;
	font-size: 22px;
}

.MobileQuoteTool_categories__2gND- .MobileQuoteTool_hiddenRadioSelection__3bcqj input[type='radio']:checked + label,
.MobileQuoteTool_products__1SnyO .MobileQuoteTool_hiddenRadioSelection__3bcqj input[type='radio']:checked + label {
	color: var(--orange);
}

.MobileQuoteTool_products__1SnyO .MobileQuoteTool_hiddenRadioSelection__3bcqj {
	margin: 0 30px;
	white-space: nowrap;
}

.MobileQuoteTool_categories__2gND- .MobileQuoteTool_dotSeparator__1DPqu,
.MobileQuoteTool_products__1SnyO .MobileQuoteTool_dotSeparator__1DPqu {
	width: 3px;
	min-width: 3px;
	height: 3px;
	max-height: 3px;
	border-radius: 50%;
	background-color: var(--dark-grey);
}

/* Category Descriptions */

.MobileQuoteTool_descriptions__2bhYx {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-grow: 1;
	        flex-grow: 1;
	-webkit-align-items: center;
	        align-items: center;
	min-height: 60vh;
	max-width: 550px;
	padding: 0;
	font-family: 'Handlee';
	color: var(--medium-grey);
}

.MobileQuoteTool_descriptions__2bhYx .MobileQuoteTool_detailedContent__sQIhU {
	text-align: left;
	font-size: 22px;
}

.MobileQuoteTool_descriptions__2bhYx .MobileQuoteTool_description__14ZkH {
	position: relative;
	padding: 40px 0;
}

.MobileQuoteTool_detailedContent__sQIhU .MobileQuoteTool_title__2tE-K {
	display: inline;
	padding-right: 30px;
	font-size: 40px;
	vertical-align: bottom;
	color: var(--dark-grey);
}

.MobileQuoteTool_detailedContent__sQIhU .MobileQuoteTool_details__1FXsN {
	display: inline;
	font-size: 18px;
	letter-spacing: 1px;
	color: #787878;
}

.MobileQuoteTool_separator__2W91d {
	padding: 0px 20px;
	display: none;
}

.MobileQuoteTool_separator__2W91d + span {
	display: block;
}

.MobileQuoteTool_highlighted__3levL {
	color: var(--orange);
}

.MobileQuoteTool_priceRange__1KNn1 {
	margin-top: 25px;
	font-size: 18px;
	letter-spacing: 1px;
}

.MobileQuoteTool_priceRange__1KNn1 .MobileQuoteTool_highlighted__3levL {
	white-space: nowrap;
}

.MobileQuoteTool_basic__2K_lD {
	display: block;
}

.MobileQuoteTool_plus__2vJeQ,
.MobileQuoteTool_premium__2fOsO {
	/* display: none; */
}

.MobileQuoteTool_noSelection__PxM2q {
	font-family: inherit;
	font-size: 18px;
	width: 100%;
}

/* Products and selections */

.MobileQuoteTool_productsAndSelections__TYuVh {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-grow: 1;
	        flex-grow: 1;
	width: 100%;
	max-width: 550px;
}

.MobileQuoteTool_productsAndSelections__TYuVh .MobileQuoteTool_backButton__3THXc {
	position: fixed;
	top: 0;
	left: 75px;
	width: 100px;
}

.MobileQuoteTool_backButton__3THXc .MobileQuoteTool_backButtonImage__1lHbI {
	position: relative;
	width: 100%;
	z-index: 1;
}

.MobileQuoteTool_backButton__3THXc .MobileQuoteTool_backButtonText__26ghj {
	position: absolute;
	z-index: 2;
	top: 6px;
	left: 50%;
	-webkit-transform: translateX(-50%);
	        transform: translateX(-50%);
	outline: none;

	color: white;
	font-weight: bold;
	font-size: 16px;
	font-family: 'bradHITC', cursive;
}

/* Add on Checkboxes */

.MobileQuoteTool_addOnWrapper__2vZr_ {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	max-width: 550px;
	-webkit-flex-grow: 1;
	        flex-grow: 1;
}

.MobileQuoteTool_addOns__3PSEh {
	max-width: 250px;
	margin: 55px auto;
	/* padding: 55px; */
	font-family: 'caviarDreams', 'Arial', sans-serif;
	/* height: 100px; */
	padding-bottom: 55px;
	margin-bottom: 0;
	/* border-bottom: 2px solid var(--light-divider-grey); */
}

.MobileQuoteTool_curved__WX6V2 {
	display: block;
	width: 350px;
	position: relative;
	left: -50px;
	margin-top: -8px;
}

.MobileQuoteTool_curved__WX6V2 > img {
	width: 100%;
}

/* Add On Description */

.MobileQuoteTool_addOnDescriptions__2J-k8 {
	max-width: 550px;
	padding: 40px 0;
	border: 2px solid var(--light-divider-grey);
	border-left: none;
	border-right: none;
	border-bottom: none;
	/* border-top: none; */
}

.MobileQuoteTool_addOnDescriptions__2J-k8 .MobileQuoteTool_addOnTitle__swtA2 {
	font-size: 24px;
	line-height: 25px;
	letter-spacing: -0.29px;
	font-family: 'bradHITC', cursive;
	margin-bottom: 18px;
	font-weight: bold;
	color: var(--medium-grey);
}

.MobileQuoteTool_addOnDescriptions__2J-k8 .MobileQuoteTool_description__14ZkH {
	font-size: 14px;
	line-height: 30px;
	font-family: 'caviarDreams', 'Arial', sans-serif;

	color: var(--medium-grey);
}

/* Price range / Quote Amount */

.MobileQuoteTool_quoteAndDisclaimer__67LTv {
	margin: 0;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-flex-grow: 1;
	        flex-grow: 1;
	-webkit-justify-content: center;
	        justify-content: center;
	/* max-width: 450px; */
	padding: 40px 15px;
	-webkit-align-items: center;
	        align-items: center;
	border: 2px solid var(--light-divider-grey);
	border-left: none;
	border-right: none;
}

.MobileQuoteTool_quoteAmount__22h77 {
	color: var(--medium-grey);
	font-family: 'caviarDreams', 'Arial', sans-serif;
	text-align: center;
	font-size: 18px;
	margin-bottom: 16px;
	line-height: 21px;
}

.MobileQuoteTool_disclaimer__1Ru0D {
	text-align: center;
	color: var(--medium-grey);
	font-family: 'caviarDreams', 'Arial', sans-serif;
	line-height: 30px;
	max-width: 450px;
	font-size: 14px;
}

.MobileQuoteTool_cta__28zI_ {
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-justify-content: center;
	        justify-content: center;
	padding: 20px 0px;
	margin-top: auto;
}

.MobileQuoteTool_cta__28zI_ .MobileQuoteTool_requestConsultation__49qpE {
	font-size: 23px;
	font-family: 'bradHITC', cursive;
	color: var(--dark);
	font-weight: bold;
	line-height: 20px;
	padding: 20px;
}

@media all and (min-width: 600px) and (max-width: 991px) {
	.MobileQuoteTool_quoteTool__3H21Q {
		min-height: calc(100vh - 126px);
	}
}

@media screen and (max-width: 575px) {
	.MobileQuoteTool_backButton__3THXc {
		display: none;
	}

	.MobileQuoteTool_quoteTool__3H21Q {
		margin: 75px 25px 0;
		width: calc(100% - 50px);
	}

	.MobileQuoteTool_description__14ZkH .MobileQuoteTool_title__2tE-K {
		font-size: 30px;
	}

	.MobileQuoteTool_description__14ZkH .MobileQuoteTool_details__1FXsN,
	.MobileQuoteTool_description__14ZkH .MobileQuoteTool_priceRange__1KNn1 {
		font-size: 16px;
	}
}

@media screen and (min-width: 575px) {
	.MobileQuoteTool_addOns__3PSEh {
		max-width: 290px;
	}

	.MobileQuoteTool_curved__WX6V2 {
		left: -30px;
	}
}

@media screen and (max-width: 365px) {
	.MobileQuoteTool_curved__WX6V2 {
		width: 290px;
		left: -20px;
	}
}

.MonthlyPackages_monthlyPackages__3l1gt {
	margin: 0 50px;
}

.MonthlyPackages_monthlyPackages__3l1gt h1 {
	color: #767676;
	font-family: 'roboto', 'Helvetica', sans-serif;
	font-weight: bold;
	font-size: 27px;
	letter-spacing: -0.91px;
	line-height: 42px;
	margin: 0;
	outline: none;
}

.MonthlyPackages_carouselPackages__2smG- {
	background: linear-gradient(180deg, #fdfdfd 0%, #f3f3f3 100%);
}

.MonthlyPackages_dotCircle__25cLW {
	border: 2px solid var(--medium-grey);
	border-radius: 50px;
	width: 3px;
	height: 3px;
	background-color: transparent;
}

#MonthlyPackages_dots__KHGt2 button {
	background-color: transparent;
}

#MonthlyPackages_dots__KHGt2 button:focus {
	border: none;
}

#MonthlyPackages_dots__KHGt2 button .MonthlyPackages_dotCircle__25cLW.MonthlyPackages_dotSelected__2WH3h {
	border-color: var(--orange);
	background-color: var(--orange);
}

.MonthlyPackages_packages__1xVVI {
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	overflow: auto;
	padding: 46px;
	background: linear-gradient(180deg, #fdfdfd 0%, #f3f3f3 100%);
}

.MonthlyPackages_monthlyPackages__3l1gt .MonthlyPackages_disclaimer__Pb4Oi {
	color: #a2a2a2;
	font-family: 'roboto', 'Helvetica', sans-serif;
	font-stretch: condensed;
	font-size: 13px;
	letter-spacing: -0.38px;
	line-height: 18px;
	text-align: center;
}

@media all and (min-height: 1000px) {
	.MonthlyPackages_monthlyPackages__3l1gt {
		margin-top: 25px;
	}

	.MonthlyPackages_packages__1xVVI {
		height: 70vh;
	}

	.MonthlyPackages_carouselPackages__2smG- {
		display: -webkit-flex;
		display: flex;
		-webkit-align-items: center;
		        align-items: center;
		height: 900px;
	}
}
/* 
@media all and (min-height: 1000px) and (max-width: 768px) {
	.carouselPackages {
		height: 800px;
	}
}

@media all and (min-height: 1000px) and (min-width: 1024px) {
	.packages {
		height: 75vh;
	}
} */

@media all and (min-height: 1600px) {
	.MonthlyPackages_monthlyPackages__3l1gt {
		margin-top: 50px;
	}

	.MonthlyPackages_packages__1xVVI {
		height: 75vh;
	}
}

@media all and (max-width: 1229px) {
	.MonthlyPackages_monthlyPackages__3l1gt {
		margin: 0 30px;
	}
}

@media all and (max-width: 1149px) {
	.MonthlyPackages_monthlyPackages__3l1gt h1 {
		margin: 20px 0;
	}
}

@media all and (min-width: 1024px) {
	.MonthlyPackages_packages__1xVVI {
		-webkit-justify-content: center;
		        justify-content: center;
	}
}

@media all and (max-width: 1023px) {
	.MonthlyPackages_monthlyPackages__3l1gt {
		margin: 0 50px;
	}

	.MonthlyPackages_carouselPackages__2smG- {
		padding-top: 50px;
		padding-left: 0;
		padding-right: 0;
	}
}

@media all and (max-width: 575px) {
	.MonthlyPackages_monthlyPackages__3l1gt {
		margin: 80px 8px;
	}

	.MonthlyPackages_carouselPackages__2smG- {
		padding-top: 25px;
		padding-left: 0;
		padding-right: 0;
	}

	.MonthlyPackages_monthlyPackages__3l1gt h1 {
		display: none;
	}

	.MonthlyPackages_monthlyPackages__3l1gt .MonthlyPackages_disclaimer__Pb4Oi {
		position: absolute;
		bottom: 55px;
		left: 50%;
		-webkit-transform: translateX(-50%);
		        transform: translateX(-50%);
		width: 100%;
		margin: 0 auto;
		font-size: 11px;
	}
}

/* @media all and (max-height: 670px) and (max-width: 575px) {
	.carouselPackages {
		height: 585px;
	}
} */

.monthly-packages .swiper-container {
	/* height: 100%; */
	padding-top: 0;
	padding-bottom: 30px;
}

.monthly-packages .swiper-pagination.swiper-pagination-bullets {
	top: 195px;
	right: 50%;
	-webkit-transform: translate(50%);
	        transform: translate(50%);
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	        flex-direction: row;
}

.monthly-packages .swiper-slide {
	min-width: 190px;
}

@media all and (max-width: 1023px) {
	/*TODO: Find a fix for this style - shouldn't need left: -20px to center the slides*/
	.monthly-packages ul.swiper-wrapper {
		left: -20px;
	}
}

@media all and (max-width: 575px) {
	.monthly-packages .swiper-container {
		padding-bottom: 0px;
	}

	.monthly-packages .swiper-pagination.swiper-pagination-bullets {
		top: 85px;
	}

	.monthly-packages .swiper-pagination-bullet {
		width: 7px;
		height: 7px;
	}
}

/* @media all and (max-height: 670px) and (max-width: 575px) {
	.monthly-packages .swiper-pagination.swiper-pagination-bullets {
		top: 78px;
	}
} */

.Package_flexContainer__35Swl {
	padding: 0 12px;
}

.Package_packageContainer__2P265 {
	box-sizing: content-box;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-justify-content: center;
	        justify-content: center;
	max-width: 200px;
	padding: 20px;
	padding-top: 25px;
	background-color: white;
	border-radius: 25px;
	box-shadow: 0px 65px 34px -50px #d8d8d8;
}

.Package_flexContainer__35Swl:first-of-type {
	padding-left: 50px;
}

.Package_flexContainer__35Swl:last-of-type {
	padding-right: 50px;
}

.Package_packageContainer__2P265 .Package_packageName__1odzT {
	font-family: 'bradHITC', cursive;
	color: #4a4a4a;
	font-size: 22px;
	font-weight: bold;
	letter-spacing: -0.56px;
	line-height: 28px;
	text-transform: uppercase;
	text-align: center;
	margin: 0;
}

.Package_packageImg__1yarX {
	margin: 15px auto;
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-justify-content: center;
	        justify-content: center;
}

.Package_price__1_-UG {
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: flex-start;
	        align-items: flex-start;
	color: #4a4a4a;
	font-family: 'caviarDreams', 'Arial', sans-serif;
	font-weight: bold;
	font-size: 15px;
	letter-spacing: -0.38px;
	line-height: 19px;
}

.Package_price__1_-UG .Package_currency__2pMDe {
	text-transform: uppercase;
}

.Package_price__1_-UG .Package_amount__kxHy0 {
	padding: 5px;
	font-family: 'roboto', 'Helvetica', sans-serif;
	font-size: 34px;
	font-weight: bold;
	letter-spacing: -0.86px;
	line-height: 40px;
}

.Package_price__1_-UG .Package_frequency__UuD2O {
	position: relative;
	bottom: -20px;
}

.Package_divider__WC0Xv {
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-justify-content: center;
	        justify-content: center;
	margin-top: 20px;
	margin-bottom: 25px;
}

.Package_divider__WC0Xv img {
	width: 200px;
}

.Package_packageDetails__1krM7 {
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-justify-content: center;
	        justify-content: center;
	width: 80%;
	margin-left: auto;
	color: #9b9b9b;
	font-family: 'dosisSemiBold', Arial, Helvetica, sans-serif;
	font-size: 16px;
	letter-spacing: -0.43px;
	line-height: 21px;
}

.Package_packageDetails__1krM7 ul {
	list-style: none;
	padding-left: 0.5em;
	text-indent: -1.7em;
}

.Package_packageDetails__1krM7 ul li {
	padding-bottom: 10px;
}

.Package_packageDetails__1krM7 ul li:before {
	content: '✓';
	padding-right: 15px;
	font-weight: 600;
}

.Package_packageContainer__2P265 button {
	border: 2px solid var(--orange);
	padding: 13px;
	color: black;
	font-weight: bold;
	max-width: 180px;
	width: 100%;
	margin: 15px;
	margin-right: auto;
	margin-left: auto;
}

.Package_packageContainer__2P265 button:focus,
.Package_packageContainer__2P265 button:hover {
	background-color: var(--orange);
	color: white;
	font-weight: bold;
}

@media all and (max-width: 1194px) {
	.Package_divider__WC0Xv img {
		max-width: 190px;
	}
}

@media all and (max-width: 1154px) {
	.Package_divider__WC0Xv img {
		max-width: 170px;
	}
}

@media all and (max-width: 1069px) {
	.Package_flexContainer__35Swl {
		padding-left: 10px;
		padding-right: 10px;
	}
}

@media all and (max-width: 1049px) {
	.Package_flexContainer__35Swl {
		padding-left: 8px;
		padding-right: 8px;
	}
}

@media all and (max-width: 1034px) {
	.Package_flexContainer__35Swl {
		padding-left: 6px;
		padding-right: 6px;
	}
}

@media all and (min-width: 1024px) {
	.Package_flexContainer__35Swl:first-of-type {
		padding-left: 12px;
	}

	.Package_flexContainer__35Swl:last-of-type {
		padding-right: 12px;
	}
}

@media all and (max-width: 1023px) {
	.Package_flexContainer__35Swl {
		padding-left: 0;
		padding-right: 0;
		padding-bottom: 0;
	}

	.Package_flexContainer__35Swl:first-of-type {
		padding-left: 0;
	}

	.Package_flexContainer__35Swl:last-of-type {
		padding-right: 0;
	}

	.Package_packageContainer__2P265 {
		min-width: 190px;
	}

	.Package_divider__WC0Xv img {
		max-width: 190px;
	}
}

@media all and (max-width: 575px) {
	.Package_flexContainer__35Swl,
	.Package_flexContainer__35Swl:first-of-type,
	.Package_flexContainer__35Swl:last-of-type {
		padding: 0;
		padding-bottom: 34px;
	}
}

/* TODO: fix iphone 6/7/8 + carousel centering at 665px */
/* @media all and (max-height: 670px) and (max-width: 575px) {
	.packageContainer {
		min-width: 180px;
		padding-top: 13px;
		padding-bottom: 21px;
	}

	.packageContainer .packageName {
		font-size: 20px;
	}

	.packageImg {
		height: 100px;
	}

	.price {
		font-size: 12px;
	}

	.price .amount {
		font-size: 32px;
	}

	.price .frequency {
		font-size: 12px;
	}

	.divider {
		margin-top: 10px;
		margin-bottom: 20px;
	}

	.divider img {
		max-width: 180px;
	}

	.packageDetails {
		width: 90%;
		font-size: 14px;
	}

	.packageContainer button {
		padding: 11px;
		max-width: 170px;
	}
} */

.PortfolioItemDesktop_desktopItem__3xmvv {
	padding: 70px 0;
}

.PortfolioItemDesktop_desktopItem__3xmvv .PortfolioItemDesktop_content__1ruWT {
	position: absolute;
	top: 250px;
	left: 360px;
	width: 43%;
	margin: 25px auto;
	font-family: 'caviarDreams', 'Arial', sans-serif;
	font-weight: bold;
	z-index: 10;
}

.PortfolioItemDesktop_desktopItem__3xmvv .PortfolioItemDesktop_contentWrapper__3SjgR {
	width: 90%;
}

.PortfolioItemDesktop_desktopItem__3xmvv .PortfolioItemDesktop_contentWrapper__3SjgR h1 {
	text-transform: uppercase;
	color: var(--dark-grey);
	font-size: 28px;
	letter-spacing: -0.65px;
	line-height: 50px;
	margin: 0;
}

.PortfolioItemDesktop_desktopItem__3xmvv .PortfolioItemDesktop_contentWrapper__3SjgR p {
	font-family: 'handleeRegular', cursive;
	font-weight: normal;
	font-size: 18px;
	letter-spacing: 0.44px;
	line-height: 26px;
	margin: 5px 0 15px;
}

.PortfolioItemDesktop_desktopItem__3xmvv .PortfolioItemDesktop_portfolioLink__3H1sV {
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: flex-end;
	        justify-content: flex-end;
}

.PortfolioItemDesktop_desktopItem__3xmvv .PortfolioItemDesktop_portfolioLink__3H1sV button {
	font-family: 'caviarDreams', 'Arial', sans-serif;
	font-weight: bold;
	font-size: 17px;
	letter-spacing: -0.58px;
	line-height: 26px;
}

.PortfolioItemDesktop_desktopItem__3xmvv .PortfolioItemDesktop_portfolioLink__3H1sV button:hover {
	color: var(--orange);
}

.PortfolioItemDesktop_desktopItem__3xmvv .PortfolioItemDesktop_portfolioLink__3H1sV button:focus {
	color: var(--orange);
	border: none;
}

.PortfolioItemDesktop_desktopItem__3xmvv .PortfolioItemDesktop_collage__3SEW3 {
	height: 515px;
}

.PortfolioItemDesktop_desktopItem__3xmvv .PortfolioItemDesktop_collage__3SEW3 img {
	height: 162px;
	width: 288px;
	background-color: #dcdbdb;
}

.PortfolioItemDesktop_desktopItem__3xmvv .PortfolioItemDesktop_collage__3SEW3 img:nth-of-type(1),
.PortfolioItemDesktop_desktopItem__3xmvv .PortfolioItemDesktop_collage__3SEW3 img:nth-of-type(4) {
	position: absolute;
	bottom: 75px;
	height: 519.62px;
	width: 240px;
	background-color: #dcdbdb;
	-webkit-transform: rotate(6deg);
	        transform: rotate(6deg);
	box-shadow: 2px 3px 9px 0 rgba(150, 150, 150, 0.5);
}

.PortfolioItemDesktop_desktopItem__3xmvv .PortfolioItemDesktop_collage__3SEW3 img:nth-of-type(1) {
	left: 90px;
}

.PortfolioItemDesktop_desktopItem__3xmvv .PortfolioItemDesktop_collage__3SEW3 img:nth-of-type(4) {
	right: 75px;
}

.PortfolioItemDesktop_desktopItem__3xmvv .PortfolioItemDesktop_collage__3SEW3 img:nth-of-type(2) {
	position: absolute;
	bottom: 42px;
	left: 337px;
	-webkit-transform: rotate(3deg);
	        transform: rotate(3deg);
	z-index: 11;
	box-shadow: 2px 0 9px 0 rgba(134, 134, 134, 0.5);
}

.PortfolioItemDesktop_desktopItem__3xmvv .PortfolioItemDesktop_collage__3SEW3 img:nth-of-type(3) {
	position: absolute;
	bottom: 40px;
	right: 330px;
	-webkit-transform: rotate(11deg);
	        transform: rotate(11deg);
	z-index: 10;
	box-shadow: 0 2px 10px 0 rgba(34, 34, 34, 0.5);
}

.PortfolioItemDesktop_desktopItem__3xmvv .PortfolioItemDesktop_collage__3SEW3 img:nth-of-type(5) {
	position: absolute;
	top: 55px;
	right: 312px;
	-webkit-transform: rotate(14deg);
	        transform: rotate(14deg);
	box-shadow: 0 2px 7px 0 rgba(179, 179, 179, 0.5);
}

.PortfolioItemDesktop_desktopItem__3xmvv .PortfolioItemDesktop_collage__3SEW3 img:nth-of-type(6) {
	position: absolute;
	top: 60px;
	left: 347px;
	-webkit-transform: rotate(-11deg);
	        transform: rotate(-11deg);
	z-index: -1;
	box-shadow: 0 2px 8px 0 rgba(206, 206, 206, 0.5);
}

@media all and (max-width: 1536px) {
	.PortfolioItemDesktop_desktopItem__3xmvv .PortfolioItemDesktop_content__1ruWT {
		top: 212px;
		left: 260px;
		transition: none;
	}

	.PortfolioItemDesktop_desktopItem__3xmvv .PortfolioItemDesktop_contentWrapper__3SjgR h1 {
		font-size: 20px;
	}

	.PortfolioItemDesktop_desktopItem__3xmvv .PortfolioItemDesktop_contentWrapper__3SjgR p {
		width: 100%;
		font-size: 16px;
		line-height: 25px;
		margin-bottom: 15px;
	}

	.PortfolioItemDesktop_desktopItem__3xmvv .PortfolioItemDesktop_portfolioLink__3H1sV button {
		font-size: 16px;
	}

	.PortfolioItemDesktop_desktopItem__3xmvv .PortfolioItemDesktop_collage__3SEW3 img:nth-of-type(1) {
		height: 390.49px;
		width: 175px;
		bottom: 165px;
		left: 55px;
		transition: none;
	}

	.PortfolioItemDesktop_desktopItem__3xmvv .PortfolioItemDesktop_collage__3SEW3 img:nth-of-type(2) {
		height: 110.59px;
		width: 210px;
		bottom: 142px;
		left: 230px;
		transition: none;
	}

	.PortfolioItemDesktop_desktopItem__3xmvv .PortfolioItemDesktop_collage__3SEW3 img:nth-of-type(3) {
		height: 110.59px;
		width: 210px;
		bottom: 140px;
		right: 245px;
		transition: none;
	}

	.PortfolioItemDesktop_desktopItem__3xmvv .PortfolioItemDesktop_collage__3SEW3 img:nth-of-type(4) {
		height: 390.49px;
		width: 175px;
		bottom: 165px;
		right: 55px;
		transition: none;
	}

	.PortfolioItemDesktop_desktopItem__3xmvv .PortfolioItemDesktop_collage__3SEW3 img:nth-of-type(5) {
		height: 110.59px;
		width: 210px;
		top: 95px;
		right: 226px;
		transition: none;
	}

	.PortfolioItemDesktop_desktopItem__3xmvv .PortfolioItemDesktop_collage__3SEW3 img:nth-of-type(6) {
		height: 110.59px;
		width: 210px;
		top: 100px;
		left: 246px;
		transition: none;
	}
}

@media all and (max-width: 1095px) and (min-width: 991px) {
	.PortfolioItemDesktop_desktopItem__3xmvv .PortfolioItemDesktop_collage__3SEW3 img:nth-of-type(1) {
		left: 27px;
		/* transition: none; */
		transition: all 0.25s ease-in-out;
	}

	.PortfolioItemDesktop_desktopItem__3xmvv .PortfolioItemDesktop_collage__3SEW3 img:nth-of-type(2) {
		left: 200px;
		/* transition: none; */
		transition: all 0.25s ease-in-out;
	}

	.PortfolioItemDesktop_desktopItem__3xmvv .PortfolioItemDesktop_collage__3SEW3 img:nth-of-type(3) {
		right: 215px;
		/* transition: none; */
		transition: all 0.25s ease-in-out;
	}

	.PortfolioItemDesktop_desktopItem__3xmvv .PortfolioItemDesktop_collage__3SEW3 img:nth-of-type(4) {
		right: 28px;
		/* transition: none; */
		transition: all 0.25s ease-in-out;
	}

	.PortfolioItemDesktop_desktopItem__3xmvv .PortfolioItemDesktop_collage__3SEW3 img:nth-of-type(5) {
		right: 198px;
		/* transition: none; */
		transition: all 0.25s ease-in-out;
	}

	.PortfolioItemDesktop_desktopItem__3xmvv .PortfolioItemDesktop_collage__3SEW3 img:nth-of-type(6) {
		left: 215px;
		/* transition: none; */
		transition: all 0.25s ease-in-out;
	}

	.PortfolioItemDesktop_desktopItem__3xmvv .PortfolioItemDesktop_content__1ruWT {
		width: 44%;
		left: 237px;
		/* transition: none; */
		transition: all 0.25s ease-in-out;
	}
}

@media all and (max-width: 991px) {
	.PortfolioItemDesktop_desktopItem__3xmvv .PortfolioItemDesktop_content__1ruWT {
		top: 265px;
		left: 170px;
		width: 40%;
	}

	.PortfolioItemDesktop_desktopItem__3xmvv .PortfolioItemDesktop_contentWrapper__3SjgR h1 {
		font-size: 20px;
	}

	.PortfolioItemDesktop_desktopItem__3xmvv .PortfolioItemDesktop_contentWrapper__3SjgR p {
		width: 100%;
		font-size: 18px;
		line-height: 25px;
		margin-left: 25px;
		margin-bottom: 25px;
	}

	.PortfolioItemDesktop_desktopItem__3xmvv .PortfolioItemDesktop_portfolioLink__3H1sV button {
		font-size: 16px;
	}

	.PortfolioItemDesktop_desktopItem__3xmvv .PortfolioItemDesktop_collage__3SEW3 {
		height: 600px;
	}

	.PortfolioItemDesktop_desktopItem__3xmvv .PortfolioItemDesktop_collage__3SEW3 img:nth-of-type(1) {
		display: none;
		transition: all 0.25s ease-in-out;
	}

	.PortfolioItemDesktop_desktopItem__3xmvv .PortfolioItemDesktop_collage__3SEW3 img:nth-of-type(2) {
		-webkit-transform: rotate(-3deg);
		        transform: rotate(-3deg);
		bottom: 70px;
		left: 235px;
		z-index: 10;
		transition: all 0.25s ease-in-out;
		box-shadow: none;
	}

	.PortfolioItemDesktop_desktopItem__3xmvv .PortfolioItemDesktop_collage__3SEW3 img:nth-of-type(3) {
		-webkit-transform: rotate(-5deg);
		        transform: rotate(-5deg);
		bottom: 70px;
		right: 251px;
		transition: all 0.25s ease-in-out;
	}

	.PortfolioItemDesktop_desktopItem__3xmvv .PortfolioItemDesktop_collage__3SEW3 img:nth-of-type(4) {
		-webkit-transform: rotate(1deg);
		        transform: rotate(1deg);
		height: 360.49px;
		top: 200px;
		right: 157px;
		z-index: 15;
		transition: all 0.25s ease-in-out;
	}

	.PortfolioItemDesktop_desktopItem__3xmvv .PortfolioItemDesktop_collage__3SEW3 img:nth-of-type(5) {
		-webkit-transform: rotate(-8deg);
		        transform: rotate(-8deg);
		top: 92px;
		right: 203px;
		transition: all 0.25s ease-in-out;
	}

	.PortfolioItemDesktop_desktopItem__3xmvv .PortfolioItemDesktop_collage__3SEW3 img:nth-of-type(6) {
		-webkit-transform: rotate(-2deg);
		        transform: rotate(-2deg);
		top: 80px;
		left: 291px;
		transition: all 0.25s ease-in-out;
	}
}

@media all and (max-width: 768px) {
	.PortfolioItemDesktop_desktopItem__3xmvv .PortfolioItemDesktop_content__1ruWT {
		left: 165px;
	}

	.PortfolioItemDesktop_desktopItem__3xmvv .PortfolioItemDesktop_collage__3SEW3 img:nth-of-type(2) {
		left: 215px;
	}

	.PortfolioItemDesktop_desktopItem__3xmvv .PortfolioItemDesktop_collage__3SEW3 img:nth-of-type(3) {
		right: 271px;
	}

	.PortfolioItemDesktop_desktopItem__3xmvv .PortfolioItemDesktop_collage__3SEW3 img:nth-of-type(4) {
		right: 177px;
	}

	.PortfolioItemDesktop_desktopItem__3xmvv .PortfolioItemDesktop_collage__3SEW3 img:nth-of-type(5) {
		right: 223px;
	}

	.PortfolioItemDesktop_desktopItem__3xmvv .PortfolioItemDesktop_collage__3SEW3 img:nth-of-type(6) {
		left: 271px;
	}
}

@media all and (max-width: 690px) {
	.PortfolioItemDesktop_desktopItem__3xmvv .PortfolioItemDesktop_content__1ruWT {
		left: 200px;
		top: 250px;
	}

	.PortfolioItemDesktop_desktopItem__3xmvv .PortfolioItemDesktop_contentWrapper__3SjgR h1 {
		font-size: 18px;
	}

	.PortfolioItemDesktop_desktopItem__3xmvv .PortfolioItemDesktop_contentWrapper__3SjgR p {
		font-size: 16px;
		line-height: 24px;
		margin-left: 20px;
	}

	.PortfolioItemDesktop_desktopItem__3xmvv .PortfolioItemDesktop_portfolioLink__3H1sV button {
		margin-right: 35px;
	}

	.PortfolioItemDesktop_desktopItem__3xmvv .PortfolioItemDesktop_collage__3SEW3 img:nth-of-type(2) {
		height: 100.59px;
		width: 195px;
		bottom: 95px;
		left: 225px;
	}

	.PortfolioItemDesktop_desktopItem__3xmvv .PortfolioItemDesktop_collage__3SEW3 img:nth-of-type(3) {
		height: 100.59px;
		width: 195px;
		right: 295px;
		bottom: 95px;
	}

	.PortfolioItemDesktop_desktopItem__3xmvv .PortfolioItemDesktop_collage__3SEW3 img:nth-of-type(4) {
		height: 345.59px;
		width: 160px;
		right: 205px;
	}

	.PortfolioItemDesktop_desktopItem__3xmvv .PortfolioItemDesktop_collage__3SEW3 img:nth-of-type(5) {
		height: 100.59px;
		width: 195px;
		top: 95px;
		right: 250px;
	}

	.PortfolioItemDesktop_desktopItem__3xmvv .PortfolioItemDesktop_collage__3SEW3 img:nth-of-type(6) {
		height: 100.59px;
		width: 195px;
		top: 80px;
		left: 290px;
	}
}

.PortfolioItemMobile_mobileItem__jsXkk {
	padding: 20px;
}

.PortfolioItemMobile_mobileItem__jsXkk .PortfolioItemMobile_projectLink__7z4A3 {
	width: 100%;
}

.PortfolioItemMobile_mobileItem__jsXkk .PortfolioItemMobile_projectLink__7z4A3:focus {
	border: none;
}

.PortfolioItemMobile_mobileItem__jsXkk .PortfolioItemMobile_collage__N4KIp {
	margin: 32px 0;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
	position: relative;
}

.PortfolioItemMobile_mobileItem__jsXkk .PortfolioItemMobile_collage__N4KIp img {
	/* height: 305.45px;
  width: 140px; */
	height: 325.45px;
	width: 160px;
	background: lightgray;
	border-radius: 5%;
}

.PortfolioItemMobile_mobileItem__jsXkk .PortfolioItemMobile_collage__N4KIp img:nth-child(1) {
	position: absolute;
	left: 55px;
}

.PortfolioItemMobile_mobileItem__jsXkk .PortfolioItemMobile_collage__N4KIp img:nth-child(2) {
	-webkit-transform: rotate(-3deg);
	        transform: rotate(-3deg);
	z-index: 1;
}

.PortfolioItemMobile_mobileItem__jsXkk .PortfolioItemMobile_collage__N4KIp img:nth-child(3) {
	-webkit-transform: rotate((1deg));
	        transform: rotate((1deg));
	position: absolute;
	right: 55px;
}

.PortfolioItemMobile_mobileItem__jsXkk .PortfolioItemMobile_content__22qWE {
	padding: 0 55px 25px;
}

.PortfolioItemMobile_mobileItem__jsXkk .PortfolioItemMobile_content__22qWE h1 {
	margin: 0;
	color: #242424;
	font-family: 'caviarDreams', 'Arial', sans-serif;
	font-weight: bold;
	font-size: 15px;
	letter-spacing: -0.22px;
	line-height: 19px;
	text-transform: uppercase;
}

.PortfolioItemMobile_mobileItem__jsXkk .PortfolioItemMobile_content__22qWE p {
	color: #767676;
	font-family: 'roboto', 'Helvetica', sans-serif;
	font-size: 15px;
	letter-spacing: -0.18px;
	line-height: 25px;
}

.PortfolioItemMobile_mobileItem__jsXkk .PortfolioItemMobile_spacer__13t3o:nth-of-type(1) {
	height: 7px;
	width: 100vw;
	-webkit-transform: scaleY(1);
	        transform: scaleY(1);
	background: linear-gradient(180deg, rgba(238, 238, 238, 0) 0%, #e4e4e4 100%);
}

.PortfolioItemMobile_mobileItem__jsXkk .PortfolioItemMobile_spacer__13t3o:nth-of-type(2) {
	height: 7px;
	width: 100vw;
	-webkit-transform: rotate(-1deg);
	        transform: rotate(-1deg);
	background: linear-gradient(180deg, rgba(238, 238, 238, 0) 0%, #dedede 100%);
}

@media all and (max-width: 550px) {
	.PortfolioItemMobile_mobileItem__jsXkk .PortfolioItemMobile_collage__N4KIp img:nth-child(1) {
		left: 50px;
	}

	.PortfolioItemMobile_mobileItem__jsXkk .PortfolioItemMobile_collage__N4KIp img:nth-child(3) {
		right: 50px;
	}

	.PortfolioItemMobile_mobileItem__jsXkk .PortfolioItemMobile_content__22qWE {
		padding: 0 50px 25px;
	}
}

@media all and (max-width: 525px) {
	.PortfolioItemMobile_mobileItem__jsXkk .PortfolioItemMobile_collage__N4KIp img:nth-child(1) {
		left: 40px;
	}

	.PortfolioItemMobile_mobileItem__jsXkk .PortfolioItemMobile_collage__N4KIp img:nth-child(3) {
		right: 40px;
	}

	.PortfolioItemMobile_mobileItem__jsXkk .PortfolioItemMobile_content__22qWE {
		padding: 0 40px 25px;
	}
}

@media all and (max-width: 500px) {
	.PortfolioItemMobile_mobileItem__jsXkk .PortfolioItemMobile_collage__N4KIp img:nth-child(1) {
		left: 25px;
	}

	.PortfolioItemMobile_mobileItem__jsXkk .PortfolioItemMobile_collage__N4KIp img:nth-child(3) {
		right: 25px;
	}

	.PortfolioItemMobile_mobileItem__jsXkk .PortfolioItemMobile_content__22qWE {
		padding: 0 25px 25px;
	}
}

.Portfolio_portfolioContainer__2yE-P {
	font-family: 'caviarDreams', 'Arial', sans-serif;
	font-weight: bold;
	margin: 0;
	padding-top: 50px;
}

.Portfolio_portfolioContainer__2yE-P:focus {
	outline: none;
}

/* .portfolioContainer ul.options {
	display: inline-flex;
	padding: 0;
	margin: 40px 0 0 150px;
}

.portfolioContainer .options > li {
	list-style-type: none;
	padding-right: 50px;
	font-size: 20px;
	letter-spacing: -0.32px;
	line-height: 28px;
}

.portfolioContainer .options > li a {
	text-decoration: none;
}

.portfolioContainer .options > li a:active,
.portfolioContainer .options > li a:visited {
	color: var(--medium-grey);
}

.portfolioContainer .options > li a:hover {
	color: var(--dark-grey);
}

.portfolioContainer .options > li a:focus {
	color: var(--orange);
} */

.Portfolio_mobilePortfolioItems__2pCKJ {
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-flex-direction: column;
	        flex-direction: column;
}

@media all and (min-height: 1200px) {
	.Portfolio_portfolioContainer__2yE-P {
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: column;
		        flex-direction: column;
	}

	/* .portfolioContainer ul.options {
		margin-top: 100px;
	} */

	.Portfolio_desktopPortfolioItems__3F2Tq {
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: column;
		        flex-direction: column;
		-webkit-align-items: center;
		        align-items: center;
		height: 830px;
	}
}

/* @media all and (min-width: 3840px) {
	.portfolioContainer ul.options {
		margin-left: 350px;
	}
} */

@media all and (min-width: 3840px) and (min-height: 2160px) {
	/* .portfolioContainer ul.options {
		margin-left: 350px;
		margin-top: 200px;
	} */

	.Portfolio_desktopPortfolioItems__3F2Tq {
		height: 1000px;
	}
}

/* @media all and (max-width: 1536px) {
	.portfolioContainer ul.options {
		margin-left: 100px;
	}

	.portfolioContainer .options > li {
		font-size: 18px;
	}
} */

@media all and (max-width: 991px) {
	.Portfolio_portfolioContainer__2yE-P {
		display: -webkit-flex;
		display: flex;
		-webkit-justify-content: center;
		        justify-content: center;
		padding-top: 0;
	}

	/* .portfolioContainer ul.options {
		display: flex;
		justify-content: center;
		margin: 40px 0 0 0;
	}

	.portfolioContainer .options > li:last-of-type {
		padding: 0;
	} */
}

@media all and (max-width: 575px) {
	.Portfolio_portfolioContainer__2yE-P {
		margin-top: 100px;
	}

	/* .portfolioContainer ul.options {
		margin-bottom: 10px;
	}

	.portfolioContainer .options > li {
		color: #242424;
		font-family: 'caviarDreams', 'Arial', sans-serif;
		font-weight: bold;
		font-size: 15px;
		letter-spacing: -0.19px;
		line-height: 17px;
	} */
}

/* @media all and (max-width: 500px) {
	.portfolioContainer ul.options {
		display: flex;
		justify-content: flex-start;
		margin-left: 35px;
	}

	.portfolioContainer .options > li {
		padding-right: 40px;
	}

	.portfolioContainer .options > li:last-of-type {
		padding: 0;
	}
} */

.desktop-portfolio-items {
	width: 1250px;
	margin-right: auto;
	margin-left: auto;
}

.desktop-portfolio-items .swiper-container {
	overflow: visible;
	width: 100%;
}

.desktop-portfolio-items .swiper-pagination.swiper-pagination-bullets {
	-webkit-flex-direction: row;
	        flex-direction: row;
	top: auto;
	bottom: 35px;
	left: 45%;
}

@media all and (min-width: 992px) {
	.desktop-portfolio-items .swiper-pagination.swiper-pagination-bullets {
		display: none;
	}
}

@media all and (max-width: 1536px) {
	.desktop-portfolio-items {
		width: 900px;
	}
}

@media all and (max-width: 1095px) and (min-width: 991px) {
	.desktop-portfolio-items {
		width: 840px;
	}

	.desktop-portfolio-items .swiper-slide {
		margin-right: 5px;
	}
}

.ItemDesktopView_itemCarouselDesktop__HvaGZ {
	max-width: 854px;
	padding: 20px;
	margin-right: auto;
	margin-left: auto;
	border-radius: 5px;
	background-color: #ffffff;
	box-shadow: 0 2px 11px 4px #f5f5f5;
	position: relative;
}

/* swiper */
.ItemDesktopView_itemCarouselDesktop__HvaGZ .ItemDesktopView_thumbnails__2V_rB {
	position: relative;
}

.ItemDesktopView_itemCarouselDesktop__HvaGZ .ItemDesktopView_thumbImgOuter__1i3Qi {
	width: 100%;
}

.ItemDesktopView_itemCarouselDesktop__HvaGZ .ItemDesktopView_thumbImgInner__1mkBW {
	width: 100%;
	height: 100%;
	object-fit: cover;
	overflow: hidden;
}

.ItemDesktopView_itemCarouselDesktop__HvaGZ .ItemDesktopView_slideContainer__14eXC {
	width: 100%;
}

.ItemDesktopView_itemCarouselDesktop__HvaGZ .ItemDesktopView_slideImg__2n4nD {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.ItemDesktopView_itemCarouselDesktop__HvaGZ .ItemDesktopView_thumbnails__2V_rB p {
	margin: 0;
	margin-top: 8px;
	color: #787878;
	text-align: center;
	font-family: 'roboto', 'Helvetica', sans-serif;
	font-size: 17px;
	letter-spacing: -0.48px;
	line-height: 23px;
}

@media all and (max-width: 1279px) {
	.ItemDesktopView_itemCarouselDesktop__HvaGZ {
		padding: 0;
	}

	.ItemDesktopView_itemCarouselDesktop__HvaGZ .ItemDesktopView_thumbnails__2V_rB {
		display: none;
	}

	/* display pagination */
}

@media all and (max-width: 991px) {
	.ItemDesktopView_itemCarouselDesktop__HvaGZ {
		max-width: none;
	}
}

.item-carousel-desktop .swiper-container .swiper-pagination.swiper-pagination-bullets {
	display: none;
}

@media all and (max-width: 1279px) {
	.item-carousel-desktop .swiper-container .swiper-pagination.swiper-pagination-bullets {
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: row;
		        flex-direction: row;
		top: unset;
		bottom: -30px;
		right: 50%;
		-webkit-transform: translate(50%);
		        transform: translate(50%);
	}
}

@media all and (max-width: 575px) {
	.item-carousel-desktop .swiper-container .swiper-pagination .swiper-pagination-bullet {
		width: 8px;
		height: 8px;
	}
}

.ItemMobileView_itemCarouselMobile__3zjYT {
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	position: relative;
	-webkit-flex-grow: 1;
	        flex-grow: 1;
	height: calc(100% + 200px);
}

img.ItemMobileView_iPhoneNotch__3UxLo {
	z-index: 10;
	position: absolute;
	top: 1.8%;
	width: 175px;
}

.ItemMobileView_itemCarouselMobile__3zjYT .ItemMobileView_iPhoneLayer1__39Xmy {
	position: absolute;
	-webkit-filter: drop-shadow(0 5px 30px #aeaeae);
	        filter: drop-shadow(0 5px 30px #aeaeae);
	height: 100%;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	width: 100%;
}

.ItemMobileView_itemCarouselMobile__3zjYT .ItemMobileView_iPhoneLayer2__91Wa1 {
	position: absolute;
	height: 100%;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	width: 100%;
}

.ItemMobileView_iPhoneLayer1__39Xmy > img,
.ItemMobileView_iPhoneLayer2__91Wa1 > img {
	height: 100%;
}

.ItemMobileView_itemCarouselMobile__3zjYT .ItemMobileView_mainImgOuter__3v8Lv {
	height: 100%;
}

.ItemMobileView_itemCarouselMobile__3zjYT .ItemMobileView_mainImgInner__1DR7P {
	height: 100%;
}

@media all and (max-width: 1326px) {
	.ItemMobileView_itemCarouselMobile__3zjYT img.ItemMobileView_iPhoneNotch__3UxLo {
		width: 170px;
	}
}

@media all and (max-width: 1279px) {
	.ItemMobileView_itemCarouselMobile__3zjYT {
		height: 550px;
	}

	.ItemMobileView_itemCarouselMobile__3zjYT img.ItemMobileView_iPhoneNotch__3UxLo {
		width: 145px;
	}

	.ItemMobileView_itemCarouselMobile__3zjYT .ItemMobileView_iPhoneLayer1__39Xmy {
		-webkit-filter: drop-shadow(10px 15px 15px #aeaeae);
		        filter: drop-shadow(10px 15px 15px #aeaeae);
	}
}

@media all and (max-width: 685px) {
	.ItemMobileView_itemCarouselMobile__3zjYT {
		height: 115vw;
	}

	.ItemMobileView_itemCarouselMobile__3zjYT img.ItemMobileView_iPhoneNotch__3UxLo {
		width: 30vw;
	}
}

@media all and (max-width: 575px) {
	.ItemMobileView_itemCarouselMobile__3zjYT {
		height: 130vw;
	}

	.ItemMobileView_itemCarouselMobile__3zjYT img.ItemMobileView_iPhoneNotch__3UxLo {
		width: 33vw;
	}
}

.item-carousel-mobile .swiper-container {
	position: static;
	padding: 0;
	margin: 0;
	border-radius: 11% / 5%;
	height: 100%;
}

.item-carousel-mobile .swiper-wrapper {
	margin: 0;
}

.item-carousel-mobile .swiper-slide img {
	height: 100%;
	width: 100%;
	object-fit: cover;
}

.item-carousel-mobile .swiper-button-prev {
	position: absolute;
	top: 50%;
	left: -90px;
}

.item-carousel-mobile .swiper-button-next {
	position: absolute;
	top: 50%;
	right: -90px;
}

.item-carousel-mobile .swiper-pagination.swiper-pagination-bullets {
	top: 22%;
	right: -65px;
}

.item-carousel-mobile .swiper-pagination-bullet {
	width: 12px;
	height: 12px;
	background: transparent;
	border: 3px solid black;
	opacity: 0.3;
}

.item-carousel-mobile .swiper-pagination-bullet-active {
	background-color: var(--orange);
	opacity: 1;
	border: 3px solid var(--orange);
}

@media all and (max-width: 1279px) and (min-width: 686px) {
	.item-carousel-mobile .swiper-container .swiper-pagination.swiper-pagination-bullets {
		-webkit-flex-direction: row;
		        flex-direction: row;
		top: auto;
		bottom: 0;
		right: 0;
		width: 241px;
		background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
		-webkit-justify-content: center;
		        justify-content: center;
		padding: 9px 0;
		border-bottom-right-radius: 25px;
		border-bottom-left-radius: 25px;
	}

	.item-carousel-mobile .swiper-container .swiper-pagination .swiper-pagination-bullet {
		width: 8px;
		height: 8px;
		background-color: white;
		border: none;
	}

	.item-carousel-mobile .swiper-button-prev,
	.item-carousel-mobile .swiper-button-next {
		display: none;
	}
}

@media all and (max-width: 685px) {
	.item-carousel-mobile .swiper-container .swiper-pagination.swiper-pagination-bullets {
		-webkit-flex-direction: row;
		        flex-direction: row;
		top: auto;
		bottom: -65px;
		right: 50%;
		-webkit-transform: translate(50%);
		        transform: translate(50%);
	}

	.item-carousel-mobile .swiper-container .swiper-pagination .swiper-pagination-bullet {
		width: 10px;
		height: 10px;
		border: 2px solid black;
	}

	.item-carousel-mobile .swiper-container .swiper-pagination .swiper-pagination-bullet-active {
		border: 2px solid var(--orange);
	}

	.item-carousel-mobile .swiper-button-prev {
		left: -60px;
	}

	.item-carousel-mobile .swiper-button-next {
		right: -60px;
	}
}

@media all and (max-width: 575px) {
	.item-carousel-mobile .swiper-container .swiper-pagination .swiper-pagination-bullet {
		width: 8px;
		height: 8px;
	}

	.item-carousel-mobile .swiper-container .swiper-pagination.swiper-pagination-bullets {
		bottom: -55px;
	}
}

.ItemDetails_itemDetailsOuter__1QH-I {
	position: relative;
	margin: 0 65px;
}

.ItemDetails_itemDetailsOuter__1QH-I .ItemDetails_heading__22vCC {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	position: absolute;
	top: -24px;
}

.ItemDetails_itemDetailsOuter__1QH-I .ItemDetails_heading__22vCC h1 {
	font-family: 'caviarDreams', 'Arial', sans-serif;
	font-weight: bold;
	font-size: clamp(15px, 0.994vw, 20px);
	color: #767676;
	letter-spacing: -0.61px;
	text-transform: uppercase;
	margin-bottom: 25px;
}

.ItemDetails_itemViewButtons__Qu2-Z {
	display: -webkit-flex;
	display: flex;
	/* margin-bottom: 40px; */
}

.ItemDetails_itemViewButtons__Qu2-Z button {
	padding: 10px 20px;
	color: #646464;
	font-family: 'bradHITC', cursive;
	font-size: clamp(15px, 1.04vw, 20px);
	font-weight: bold;
	letter-spacing: -0.32px;
	text-transform: uppercase;
	border: 2px solid transparent;
	margin-right: 30px;
}

.ItemDetails_itemViewButtons__Qu2-Z button:focus,
.ItemDetails_itemViewButtons__Qu2-Z button.ItemDetails_selected__19Kr5 {
	box-sizing: border-box;
	border: 2px dashed #202020;
	border-radius: 4px;
}

.ItemDetails_itemViewButtons__Qu2-Z button img {
	display: inline-block;
	vertical-align: middle;
}

.ItemDetails_itemViewButtons__Qu2-Z button img.ItemDetails_mobileIcon__VaY9a {
	height: 24px;
}
.ItemDetails_itemViewButtons__Qu2-Z button img.ItemDetails_desktopIcon__2p6jP {
	height: 21px;
}

.ItemDetails_itemViewButtons__Qu2-Z p {
	display: inline;
	padding-left: 20px;
}

.ItemDetails_itemDetailsInner__1n7xQ {
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: space-evenly;
	        justify-content: space-evenly;
	position: inherit;
	top: 115px;
	padding-bottom: 30px;
	padding-right: 25px;
	max-height: 800px;
}

.ItemDetails_itemDetailsInner__1n7xQ.ItemDetails_mobile__39cor {
	margin-bottom: 55px;
}

.ItemDetails_itemDetailsInner__1n7xQ .ItemDetails_content__25bJN {
	position: relative;
	top: 0px;
	width: 40%;
	margin-right: 5%;
	font-family: 'caviarDreams', 'Arial', sans-serif;
	font-weight: bold;
	font-size: clamp(15px, 0.994vw, 20px);
	color: #767676;
	letter-spacing: -0.61px;
}

.ItemDetails_itemDetailsOuter__1QH-I .ItemDetails_heading__22vCC h1,
.ItemDetails_itemDetailsInner__1n7xQ h2 {
	color: #767676;
	font-family: 'roboto', 'Helvetica', sans-serif;
	font-weight: bold;
	font-size: clamp(15px, 1.46vw, 28px);
	letter-spacing: -0.91px;
}

.ItemDetails_itemDetailsInner__1n7xQ h2 {
	font-size: clamp(20px, 2.24vw, 43px);
	letter-spacing: -1.39px;
	margin-top: 20px;
	margin-bottom: 20px;
}

.ItemDetails_itemDetailsInner__1n7xQ .ItemDetails_content__25bJN > h2 {
	display: block;
}

.ItemDetails_itemDetailsInner__1n7xQ .ItemDetails_description__QKxPT {
	margin-left: 80px;
	margin-bottom: 80px;
}

.ItemDetails_itemDetailsInner__1n7xQ .ItemDetails_description__QKxPT .ItemDetails_text__Tlb2N {
	margin: 35px 0 25px 0;
	max-height: 225px;
	min-height: 150px;
	height: 210px;
	overflow-y: auto;
	padding-right: 40px;
}

.ItemDetails_itemDetailsInner__1n7xQ .ItemDetails_description__QKxPT .ItemDetails_mobileH2__2uwBX {
	display: none;
}

.ItemDetails_itemDetailsInner__1n7xQ .ItemDetails_description__QKxPT .ItemDetails_text__Tlb2N .ItemDetails_packageDetails__2j1Hm {
	display: none;
	margin-bottom: 25px;
}

.ItemDetails_itemDetailsInner__1n7xQ .ItemDetails_description__QKxPT .ItemDetails_packageDetails__2j1Hm {
	display: block;
}

.ItemDetails_itemDetailsInner__1n7xQ .ItemDetails_description__QKxPT p {
	font-family: 'caviarDreams', 'Arial', sans-serif;
	font-weight: normal;
	line-height: 26px;
}

.ItemDetails_itemDetailsInner__1n7xQ .ItemDetails_packageDetails__2j1Hm div {
	margin-bottom: 15px;
}

.ItemDetails_itemDetailsInner__1n7xQ .ItemDetails_consultationBtn__NXfWB {
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
}

.ItemDetails_itemDetailsInner__1n7xQ .ItemDetails_consultationBtn__NXfWB > button {
	height: 55px;
	max-width: 380px;
	min-width: 150px;
	width: 19.8vw;
	border-radius: 3px;
	background-color: #e4a500;
	font-family: 'roboto', 'Helvetica', sans-serif;
	font-size: clamp(15px, 1.04vw, 20px);
	color: #ffffff;
	letter-spacing: -0.33px;
}

.ItemDetails_itemDetailsInner__1n7xQ .ItemDetails_imgCarouselDesktop__1VDeS {
	position: relative;
	top: -75px;
	width: 55%;
}

.ItemDetails_itemDetailsInner__1n7xQ .ItemDetails_imgCarouselMobile__VIHcc {
	position: relative;
	top: -165px;
	right: -60px;
	width: 55%;
	/* margin-right: 40px; */
}

@media all and (min-width: 1920px) {
	.ItemDetails_itemDetailsOuter__1QH-I {
		max-width: 1800px;
	}
}

@media all and (max-width: 1820px) {
	.ItemDetails_itemDetailsInner__1n7xQ .ItemDetails_description__QKxPT .ItemDetails_text__Tlb2N {
		height: 200px;
	}
}

@media all and (max-width: 1750px) {
	.ItemDetails_itemDetailsInner__1n7xQ .ItemDetails_description__QKxPT .ItemDetails_text__Tlb2N {
		height: 187px;
	}
}

@media all and (max-width: 1700px) {
	.ItemDetails_itemDetailsInner__1n7xQ .ItemDetails_description__QKxPT .ItemDetails_text__Tlb2N {
		height: 180px;
	}
}

@media all and (max-width: 1650px) {
	.ItemDetails_itemDetailsInner__1n7xQ .ItemDetails_description__QKxPT {
		margin-bottom: 70px;
	}
}

@media all and (max-width: 1600px) {
	.ItemDetails_itemDetailsInner__1n7xQ .ItemDetails_description__QKxPT {
		margin-left: 50px;
		margin-bottom: 60px;
	}
}

@media all and (max-width: 1550px) {
	.ItemDetails_itemDetailsInner__1n7xQ .ItemDetails_description__QKxPT .ItemDetails_text__Tlb2N {
		margin: 25px 0 25px 0;
	}
}

@media all and (max-width: 1500px) {
	.ItemDetails_itemDetailsInner__1n7xQ .ItemDetails_description__QKxPT .ItemDetails_text__Tlb2N {
		margin: 50px 0 70px 0;
		height: 210px;
	}

	.ItemDetails_itemDetailsInner__1n7xQ .ItemDetails_description__QKxPT .ItemDetails_text__Tlb2N .ItemDetails_packageDetails__2j1Hm {
		display: block;
	}

	.ItemDetails_itemDetailsInner__1n7xQ .ItemDetails_description__QKxPT .ItemDetails_packageDetails__2j1Hm {
		display: none;
	}
}

@media all and (max-width: 1450px) {
	.ItemDetails_itemDetailsInner__1n7xQ .ItemDetails_description__QKxPT .ItemDetails_text__Tlb2N {
		margin: 48px 0 62px 0;
	}
}

@media all and (max-width: 1420px) {
	.ItemDetails_itemDetailsInner__1n7xQ {
		top: 95px;
	}

	.ItemDetails_itemDetailsInner__1n7xQ .ItemDetails_imgCarouselDesktop__1VDeS {
		top: -55px;
	}

	.ItemDetails_itemDetailsInner__1n7xQ .ItemDetails_description__QKxPT .ItemDetails_text__Tlb2N {
		margin-bottom: 55px;
	}
}

@media all and (max-width: 1400px) {
	.ItemDetails_itemDetailsInner__1n7xQ .ItemDetails_description__QKxPT {
		margin-bottom: 50px;
	}
	.ItemDetails_itemDetailsInner__1n7xQ .ItemDetails_description__QKxPT .ItemDetails_text__Tlb2N {
		margin-bottom: 40px;
	}
}

@media all and (max-width: 1370px) {
	.ItemDetails_itemViewButtons__Qu2-Z button {
		margin-right: 20px;
	}

	.ItemDetails_itemViewButtons__Qu2-Z p {
		padding-left: 12px;
	}

	.ItemDetails_itemDetailsInner__1n7xQ .ItemDetails_description__QKxPT {
		margin-bottom: 50px;
	}
}

@media all and (max-width: 1320px) {
	.ItemDetails_itemDetailsInner__1n7xQ .ItemDetails_description__QKxPT .ItemDetails_text__Tlb2N {
		margin-top: 35px;
	}
}

@media all and (max-width: 1300px) {
	.ItemDetails_itemDetailsInner__1n7xQ .ItemDetails_description__QKxPT {
		margin-bottom: 45px;
	}

	.ItemDetails_itemDetailsInner__1n7xQ .ItemDetails_description__QKxPT .ItemDetails_text__Tlb2N {
		margin-top: 30px;
	}
}

@media all and (max-width: 1279px) {
	/* resize to tablet view */
	.ItemDetails_itemDetailsOuter__1QH-I {
		margin: auto;
		max-width: 854px;
	}

	.ItemDetails_itemDetailsOuter__1QH-I .ItemDetails_heading__22vCC {
		-webkit-flex-direction: row;
		        flex-direction: row;
		-webkit-justify-content: space-between;
		        justify-content: space-between;
		-webkit-align-items: center;
		        align-items: center;
		position: static;
		width: 100%;
		margin: 20px 0 40px;
	}

	.ItemDetails_itemDetailsOuter__1QH-I.ItemDetails_mobile__39cor .ItemDetails_heading__22vCC {
		margin: 20px 0 25px;
	}

	.ItemDetails_itemDetailsOuter__1QH-I .ItemDetails_heading__22vCC h1 {
		margin: 0;
		font-size: 20px;
		/* font-size: clamp(20px, 2vw, 20px); */
	}

	.ItemDetails_itemViewButtons__Qu2-Z button {
		padding: 10px 10px;
		font-size: 17px;
		border: none;
		border-bottom: 1px dashed transparent;
	}

	.ItemDetails_itemViewButtons__Qu2-Z button:last-of-type {
		margin-right: 0;
	}

	.ItemDetails_itemViewButtons__Qu2-Z button:focus,
	.ItemDetails_itemViewButtons__Qu2-Z button.ItemDetails_selected__19Kr5 {
		border: none;
		border-bottom: 1px dashed #202020;
		border-radius: 0;
	}

	.ItemDetails_itemDetailsInner__1n7xQ {
		/* top: 230px; */
		-webkit-flex-direction: column-reverse;
		        flex-direction: column-reverse;
		-webkit-justify-content: flex-end;
		        justify-content: flex-end;
		-webkit-align-items: center;
		        align-items: center;
		position: static;
		margin-top: 20px;
		max-height: none;
		padding: 0;
	}

	.ItemDetails_itemDetailsInner__1n7xQ.ItemDetails_mobile__39cor {
		-webkit-flex-direction: row;
		        flex-direction: row;
		margin-top: 35px;
		/* margin-bottom: 0; */
	}

	.ItemDetails_itemDetailsInner__1n7xQ .ItemDetails_content__25bJN {
		width: 100%;
		margin-right: auto;
		margin-left: auto;
		text-align: center;
		margin: 40px 0;
	}

	.ItemDetails_itemDetailsInner__1n7xQ.ItemDetails_mobile__39cor .ItemDetails_content__25bJN {
		width: 45%;
		margin: 0;
		margin-right: 10vw;
	}

	.ItemDetails_itemDetailsInner__1n7xQ .ItemDetails_content__25bJN > h2 {
		display: none;
	}

	.ItemDetails_itemDetailsInner__1n7xQ .ItemDetails_description__QKxPT {
		margin: 0;
	}

	.ItemDetails_itemDetailsInner__1n7xQ.ItemDetails_mobile__39cor .ItemDetails_description__QKxPT {
		text-align: left;
	}

	.ItemDetails_itemDetailsInner__1n7xQ .ItemDetails_description__QKxPT .ItemDetails_text__Tlb2N {
		padding: 0;
	}

	.ItemDetails_itemDetailsInner__1n7xQ .ItemDetails_description__QKxPT .ItemDetails_mobileH2__2uwBX {
		display: inline;
	}

	.ItemDetails_itemDetailsInner__1n7xQ h2 {
		font-size: 35px;
		/* font-size: clamp(30px, 3vw, 43px); */
	}

	.ItemDetails_itemDetailsInner__1n7xQ .ItemDetails_description__QKxPT .ItemDetails_text__Tlb2N .ItemDetails_packageDetails__2j1Hm {
		font-size: 17px;
	}

	/* .itemDetailsInner .description .text .packageDetails {
		width: 30%;
		margin: auto;
		text-align: left;
	}

	.itemDetailsInner .description .text .packageDetails div {
		display: flex;
		justify-content: space-between;
		align-items: center;
		text-align: left;
	} */

	.ItemDetails_itemDetailsInner__1n7xQ .ItemDetails_description__QKxPT .ItemDetails_text__Tlb2N {
		height: auto;
		max-height: none;
		min-height: none;
		overflow-y: unset;
	}

	.ItemDetails_itemDetailsInner__1n7xQ .ItemDetails_description__QKxPT p {
		text-align: left;
		font-size: 16px;
	}

	.ItemDetails_itemDetailsInner__1n7xQ .ItemDetails_consultationBtn__NXfWB {
		margin-bottom: 25px;
	}

	.ItemDetails_itemDetailsInner__1n7xQ .ItemDetails_consultationBtn__NXfWB > button {
		height: 50px;
		max-width: none;
		min-width: 275px;
		width: 600px;
	}

	.ItemDetails_itemDetailsInner__1n7xQ .ItemDetails_imgCarouselDesktop__1VDeS {
		width: 100%;
		position: static;
	}

	.ItemDetails_itemDetailsInner__1n7xQ .ItemDetails_imgCarouselMobile__VIHcc {
		top: 0;
		right: 0;
	}

	/* .consultationBtnMobile {
		display: flex;
		justify-content: center;
	}

	.consultationBtnMobile > button {
		height: 55px;
		max-width: 380px;
		min-width: 150px;
		width: 19.8vw;
		border-radius: 3px;
		background-color: #e4a500;
		font-family: 'roboto', 'Helvetica', sans-serif;
		font-size: clamp(15px, 1.04vw, 20px);
		color: #ffffff;
		letter-spacing: -0.33px;
	} */
}

@media all and (max-width: 950px) {
	.ItemDetails_itemDetailsOuter__1QH-I {
		margin: 0 25px;
	}

	.ItemDetails_itemDetailsOuter__1QH-I .ItemDetails_heading__22vCC h1 {
		font-size: clamp(16px, 2.56vw, 20px);
	}

	.ItemDetails_itemViewButtons__Qu2-Z button {
		font-size: clamp(14px, 2vw, 17px);
	}

	.ItemDetails_itemDetailsInner__1n7xQ h2 {
		font-size: clamp(22px, 3.69vw, 43px);
	}

	.ItemDetails_itemDetailsInner__1n7xQ .ItemDetails_description__QKxPT .ItemDetails_text__Tlb2N .ItemDetails_packageDetails__2j1Hm {
		font-size: clamp(16px, 1.8vw, 17px);
	}

	.ItemDetails_itemDetailsInner__1n7xQ .ItemDetails_description__QKxPT p {
		font-size: clamp(15px, 1.8vw, 16px);
	}

	.ItemDetails_itemDetailsInner__1n7xQ .ItemDetails_consultationBtn__NXfWB > button {
		width: clamp(275px, 60vw, 600px);
	}
}

@media all and (max-width: 890px) {
	.ItemDetails_itemDetailsInner__1n7xQ.ItemDetails_mobile__39cor .ItemDetails_content__25bJN {
		margin-right: 8vw;
	}
}

@media all and (max-width: 750px) {
	.ItemDetails_itemDetailsInner__1n7xQ.ItemDetails_mobile__39cor .ItemDetails_content__25bJN {
		margin-right: 25px;
	}
}

@media all and (max-width: 700px) {
	.ItemDetails_itemViewButtons__Qu2-Z button:first-of-type {
		margin-right: 5px;
	}
}

@media all and (max-width: 685px) {
	.ItemDetails_itemDetailsInner__1n7xQ.ItemDetails_mobile__39cor {
		-webkit-flex-direction: column-reverse;
		        flex-direction: column-reverse;
	}

	.ItemDetails_itemDetailsInner__1n7xQ.ItemDetails_mobile__39cor .ItemDetails_content__25bJN {
		margin: 0;
		margin-top: 65px;
		width: 90%;
	}

	.ItemDetails_itemDetailsInner__1n7xQ .ItemDetails_content__25bJN > h2 {
		display: block;
	}

	.ItemDetails_itemDetailsInner__1n7xQ.ItemDetails_mobile__39cor .ItemDetails_description__QKxPT {
		text-align: center;
	}

	.ItemDetails_itemDetailsInner__1n7xQ .ItemDetails_description__QKxPT .ItemDetails_mobileH2__2uwBX {
		display: none;
	}

	.ItemDetails_itemDetailsInner__1n7xQ .ItemDetails_description__QKxPT .ItemDetails_text__Tlb2N {
		margin-bottom: 30px;
	}

	.ItemDetails_itemDetailsInner__1n7xQ .ItemDetails_description__QKxPT .ItemDetails_text__Tlb2N .ItemDetails_packageDetails__2j1Hm {
		display: none;
	}

	.ItemDetails_itemDetailsInner__1n7xQ .ItemDetails_description__QKxPT .ItemDetails_packageDetails__2j1Hm {
		display: block;
		margin-bottom: 30px;
	}

	.ItemDetails_itemDetailsInner__1n7xQ .ItemDetails_consultationBtn__NXfWB > button {
		width: clamp(275px, 83vw, 600px);
	}

	.ItemDetails_itemDetailsInner__1n7xQ .ItemDetails_imgCarouselMobile__VIHcc {
		width: 100%;
	}
}

@media all and (max-width: 575px) {
	.ItemDetails_itemDetailsOuter__1QH-I {
		margin: 100px 25px 70px;
	}

	.ItemDetails_itemDetailsOuter__1QH-I .ItemDetails_heading__22vCC {
		margin-bottom: 20px;
	}

	.ItemDetails_itemDetailsOuter__1QH-I .ItemDetails_heading__22vCC h1 {
		text-transform: capitalize;
		color: #242424;
	}

	.ItemDetails_itemViewButtons__Qu2-Z p {
		display: none;
	}

	.ItemDetails_itemViewButtons__Qu2-Z button {
		color: var(--light-grey);
	}

	.ItemDetails_itemViewButtons__Qu2-Z button:focus,
	.ItemDetails_itemViewButtons__Qu2-Z button.ItemDetails_selected__19Kr5 {
		border: none;
	}

	.ItemDetails_itemViewButtons__Qu2-Z button:first-of-type {
		margin-right: 0;
	}

	.ItemDetails_itemViewButtons__Qu2-Z button:first-of-type::after {
		content: '•';
		margin-left: 20px;
	}

	.ItemDetails_itemDetailsInner__1n7xQ .ItemDetails_content__25bJN {
		margin-bottom: 10px;
	}

	.ItemDetails_itemDetailsInner__1n7xQ .ItemDetails_description__QKxPT p {
		text-align: center;
	}

	.ItemDetails_itemDetailsInner__1n7xQ .ItemDetails_consultationBtn__NXfWB button {
		min-width: 271px;
	}
}

@media all and (max-width: 350px) {
	.ItemDetails_itemDetailsOuter__1QH-I .ItemDetails_heading__22vCC h1 {
		font-size: 16px;
	}

	.ItemDetails_itemViewButtons__Qu2-Z button:first-of-type {
		padding: 5px;
	}

	.ItemDetails_itemViewButtons__Qu2-Z button:first-of-type::after {
		margin-left: 15px;
	}

	.ItemDetails_itemViewButtons__Qu2-Z button img.ItemDetails_mobileIcon__VaY9a {
		height: 20px;
	}

	.ItemDetails_itemViewButtons__Qu2-Z button img.ItemDetails_desktopIcon__2p6jP {
		height: 18px;
	}
}

.CompanyIntro_companyIntroContainer__2JfKk {
	position: relative;
	font-family: 'handleeRegular', cursive;
	padding: 0 100px;
	width: 100vw;
}

.CompanyIntro_companyIntroContainer__2JfKk .CompanyIntro_intro__n4pHy {
	position: absolute;
	top: 0;
	left: 90px;
}

.CompanyIntro_companyIntroContainer__2JfKk .CompanyIntro_intro__n4pHy h1 {
	font-size: 20px;
	text-transform: uppercase;
}

.CompanyIntro_smallPostit__1XRPo {
	height: 45px;
	width: 225px;
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-justify-content: center;
	        justify-content: center;
	position: absolute;
	right: -12px;
	border-radius: 44px 0 0 0;
	background: linear-gradient(150.81deg, #be920e 0%, #e4a500 100%);
	background-repeat: no-repeat;
	background-size: 225px 48px;
	font-size: 14px;
	font-weight: 700;
	text-transform: uppercase;
}

.CompanyIntro_smallPostit__1XRPo.CompanyIntro_first__2L_4d {
	top: 85px;
}

.CompanyIntro_smallPostit__1XRPo.CompanyIntro_second__1dQWe {
	top: 150px;
}

.CompanyIntro_companyIntroContainer__2JfKk .CompanyIntro_sketch__16uFf {
	height: 450px;
	margin-right: auto;
	margin-left: auto;
}

.CompanyIntro_companyIntroContainer__2JfKk .CompanyIntro_sketch__16uFf img {
	height: 100%;
	width: 100%;
	object-fit: contain;
}

.CompanyIntro_companyIntroContainer__2JfKk .CompanyIntro_content__3NRY0 {
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-justify-content: space-evenly;
	        justify-content: space-evenly;
	max-width: 1500px;
	margin-right: auto;
	margin-left: auto;
}

.CompanyIntro_largePostit__JepJM {
	height: 322px;
	width: 322px;
	background-repeat: no-repeat;
	background-size: 322px;
	padding: 40px;
	margin-right: auto;
	margin-left: auto;
}

.CompanyIntro_largePostit__JepJM h2 {
	padding-left: 15px;
}

.CompanyIntro_largePostit__JepJM .CompanyIntro_postitText__1PbtX {
	font-size: 18px;
	letter-spacing: 0.7px;
	color: white;
	line-height: 28px;
	padding-top: 8px;
	margin-bottom: 55px;
}

.CompanyIntro_largePostit__JepJM a {
	font-family: 'roboto', 'Helvetica', sans-serif;
	font-weight: bold;
	color: #000000;
	font-size: 16px;
	letter-spacing: 0.64px;
	text-align: right;
	margin-right: 12px;
}

.CompanyIntro_companyIntroContainer__2JfKk .CompanyIntro_values__--4Au * {
	-webkit-transform: rotate(2deg);
	        transform: rotate(2deg);
}

.CompanyIntro_companyIntroContainer__2JfKk .CompanyIntro_mission__3lJ6z * {
	-webkit-transform: rotate(2deg);
	        transform: rotate(2deg);
}

.CompanyIntro_companyIntroContainer__2JfKk .CompanyIntro_vision__Z-uMl * {
	-webkit-transform: rotate(-2deg);
	        transform: rotate(-2deg);
}

@media all and (min-width: 2000px) {
	.CompanyIntro_companyIntroContainer__2JfKk {
		min-width: 1500px;
	}
}

@media all and (max-width: 1500px) {
	.CompanyIntro_companyIntroContainer__2JfKk .CompanyIntro_intro__n4pHy h1 {
		font-size: 18px;
	}

	.CompanyIntro_smallPostit__1XRPo {
		height: 40px;
		width: 220px;
		right: -19px;
		background-size: 220px 43px;
		font-size: 12px;
	}

	.CompanyIntro_smallPostit__1XRPo.CompanyIntro_second__1dQWe {
		top: 130px;
	}

	.CompanyIntro_largePostit__JepJM {
		height: 275px;
		width: 275px;
		background-size: 275px;
		padding: 30px;
	}

	.CompanyIntro_largePostit__JepJM h2 {
		font-size: 22px;
	}

	.CompanyIntro_largePostit__JepJM .CompanyIntro_postitText__1PbtX {
		font-size: 16px;
		padding-top: 0px;
		margin-bottom: 40px;
	}

	.CompanyIntro_largePostit__JepJM a {
		font-size: 14px;
	}
}

@media all and (max-width: 1124px) {
	.CompanyIntro_companyIntroContainer__2JfKk .CompanyIntro_intro__n4pHy {
		left: 70px;
	}

	.CompanyIntro_companyIntroContainer__2JfKk .CompanyIntro_intro__n4pHy h1 {
		font-size: 17px;
	}

	.CompanyIntro_largePostit__JepJM {
		height: 260px;
		width: 260px;
		background-size: 260px;
		padding: 25px;
	}

	.CompanyIntro_largePostit__JepJM h2 {
		padding-left: 10px;
		font-size: 20px;
	}

	.CompanyIntro_largePostit__JepJM .CompanyIntro_postitText__1PbtX {
		font-size: 14px;
	}

	.CompanyIntro_largePostit__JepJM a {
		font-size: 13px;
	}
}

@media all and (max-width: 1040px) {
	.CompanyIntro_largePostit__JepJM {
		height: 250px;
		width: 250px;
		background-size: 250px;
		padding: 25px;
	}

	.CompanyIntro_largePostit__JepJM .CompanyIntro_postitText__1PbtX {
		margin-bottom: 35px;
	}
}

@media all and (max-width: 991px) {
	.CompanyIntro_companyIntroContainer__2JfKk {
		padding: 0 50px;
	}

	.CompanyIntro_smallPostit__1XRPo.CompanyIntro_first__2L_4d {
		top: 265px;
		right: -20px;
	}

	.CompanyIntro_smallPostit__1XRPo.CompanyIntro_second__1dQWe {
		top: 248px;
		left: -20px;
		-webkit-transform: scaleX(-1);
		        transform: scaleX(-1);
	}

	.CompanyIntro_smallPostit__1XRPo.CompanyIntro_second__1dQWe .CompanyIntro_squadText__FEFmK {
		-webkit-transform: scaleX(-1);
		        transform: scaleX(-1);
	}

	.CompanyIntro_companyIntroContainer__2JfKk .CompanyIntro_sketch__16uFf {
		height: 275px;
		margin-bottom: 55px;
	}

	.CompanyIntro_companyIntroContainer__2JfKk .CompanyIntro_intro__n4pHy {
		position: relative;
		top: auto;
		left: auto;
		text-align: center;
	}

	.CompanyIntro_companyIntroContainer__2JfKk .CompanyIntro_carousel__VKprM {
		width: 400px;
		margin-top: 20px;
		margin-right: auto;
		margin-left: auto;
	}

	.CompanyIntro_largePostit__JepJM {
		height: 322px;
		width: 322px;
		background-size: 322px;
		padding: 40px;
	}

	.CompanyIntro_largePostit__JepJM h2 {
		padding-left: 15px;
	}

	.CompanyIntro_largePostit__JepJM .CompanyIntro_postitText__1PbtX {
		font-size: 18px;
		letter-spacing: 0.7px;
		color: white;
		line-height: 28px;
		padding-top: 8px;
		margin-bottom: 70px;
	}

	.CompanyIntro_largePostit__JepJM a {
		font-size: 16px;
		letter-spacing: 0.64px;
		margin-right: 12px;
	}
}

@media all and (max-width: 575px) {
	.CompanyIntro_companyIntroContainer__2JfKk {
		padding: 100px 50px 50px;
	}

	.CompanyIntro_companyIntroContainer__2JfKk .CompanyIntro_sketch__16uFf {
		height: 250px;
		margin-bottom: 90px;
	}

	.CompanyIntro_smallPostit__1XRPo.CompanyIntro_first__2L_4d {
		top: 360px;
	}

	.CompanyIntro_smallPostit__1XRPo.CompanyIntro_second__1dQWe {
		top: 370px;
	}

	.CompanyIntro_companyIntroContainer__2JfKk .CompanyIntro_mobileSeparator__2eA_W {
		position: absolute;
		left: 0;
		width: 100%;
		height: 11px;
		-webkit-transform: scaleY(-1);
		        transform: scaleY(-1);
		background: linear-gradient(180deg, rgba(238, 238, 238, 0) 0%, rgba(216, 216, 216, 0.54) 100%);
	}

	.CompanyIntro_companyIntroContainer__2JfKk .CompanyIntro_carousel__VKprM {
		margin-top: 50px;
	}

	.CompanyIntro_companyIntroContainer__2JfKk .CompanyIntro_carousel__VKprM h2 {
		font-size: 24px;
		padding-left: 10px;
	}

	.CompanyIntro_companyIntroContainer__2JfKk .CompanyIntro_carousel__VKprM .CompanyIntro_postitText__1PbtX {
		padding-top: 8px;
		padding-left: 25px;
		margin-bottom: 50px;
		font-size: 18px;
		letter-spacing: 0.58px;
		line-height: 35px;
	}

	.CompanyIntro_companyIntroContainer__2JfKk .CompanyIntro_carousel__VKprM a {
		font-family: 'roboto', 'Helvetica', sans-serif;
		font-weight: bold;
		color: var(--orange);
		font-size: 16px;
		letter-spacing: 0.64px;
		text-align: right;
		margin-right: 12px;
	}
}

@media all and (max-width: 500px) {
	.CompanyIntro_companyIntroContainer__2JfKk .CompanyIntro_carousel__VKprM {
		width: 350px;
	}
}

@media all and (max-width: 425px) {
	.CompanyIntro_companyIntroContainer__2JfKk {
		padding: 100px 25px 50px;
	}

	.CompanyIntro_companyIntroContainer__2JfKk .CompanyIntro_carousel__VKprM {
		width: 300px;
	}
}

@media all and (max-width: 375px) {
	.CompanyIntro_smallPostit__1XRPo.CompanyIntro_first__2L_4d {
		right: -40px;
	}

	.CompanyIntro_smallPostit__1XRPo.CompanyIntro_second__1dQWe {
		left: -40px;
	}
}

@media all and (max-width: 350px) {
	.CompanyIntro_companyIntroContainer__2JfKk .CompanyIntro_carousel__VKprM {
		width: 250px;
	}
}

@media all and (min-height: 900px) {
	.CompanyIntro_companyIntroContainer__2JfKk .CompanyIntro_carousel__VKprM {
		margin-top: 55px;
	}
}

.company-intro-container .swiper-wrapper {
	margin-bottom: 0;
}

.company-intro-container .swiper-pagination.swiper-pagination-bullets {
	-webkit-flex-direction: row;
	        flex-direction: row;
	top: 500px;
	right: 50%;
	-webkit-transform: translate(50%);
	        transform: translate(50%);
}

.company-intro-container .swiper-slide {
	padding: 0 25px;
}

@media all and (min-width: 576px) {
	.company-intro-container .swiper-container {
		padding-top: 15px;
		overflow: visible;
	}
	.company-intro-container .swiper-pagination.swiper-pagination-bullets {
		top: 370px;
	}
}

@media all and (max-width: 575px) {
	.company-intro-container .swiper-slide {
		padding: 0;
	}
}

@media all and (min-height: 900px) and (min-width: 576px) {
	.company-intro-container .swiper-pagination.swiper-pagination-bullets {
		top: 385px;
	}
}

/* !Wrapper Container for page */

.OurTeam_outerContainer__1kw_1 {
	min-height: calc(100vh - 126px); /* Removed this for layout */
	display: -webkit-flex;
	display: flex;
}

/* !Page Cutout */

.OurTeam_pageCutout__2mYbK {
	position: fixed;
	top: -20%;
	bottom: -20%;
	left: -200px;
	width: calc(50% + 220px);
	-webkit-transform: rotate(-4deg);
	        transform: rotate(-4deg);
	box-shadow: 6px 0px 12px var(--light-divider-grey);
}

/* !Holds the buttons and circles for the team memebers */

.OurTeam_teamMembers__SHQg5 {
	-webkit-transform: rotate(-4deg);
	        transform: rotate(-4deg);
	-webkit-transform-origin: center;
	        transform-origin: center;
	width: 50%;
	position: relative;
	display: grid;
	grid-template-columns: 50% 50%;
	margin-top: -126px;
	min-height: calc(100vh - 126px);
}

/* !Left and right columns */

.OurTeam_leftColumn__3Imek,
.OurTeam_rightColumn__11W1A {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
}

.OurTeam_leftColumn__3Imek {
	-webkit-justify-content: center;
	        justify-content: center;
}

.OurTeam_rightColumn__11W1A {
	-webkit-justify-content: space-between;
	        justify-content: space-between;
}

/* !Each column has a member card that has a bunch of info */

.OurTeam_memberCard__2INu8 {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-align-items: center;
	        align-items: center;
	padding: 30px 15px;
}

.OurTeam_memberCard__2INu8 .OurTeam_title__1bb5X {
	font-family: 'bradHITC', cursive;
	font-weight: bold;
	font-size: 30px;
	line-height: 38px;
	color: #000000;
	text-align: center;
}

.OurTeam_memberCard__2INu8 .OurTeam_name__3ckeg {
	font-family: 'bradHITC', cursive;
	font-size: 25px;
	color: var(--medium-grey);
	margin-bottom: 8px;
	text-align: center;
}

.OurTeam_memberCard__2INu8 .OurTeam_icon__2XlZu {
	width: 191px;
	height: 191px;
	border: none !important;
	outline: none !important;
	padding: 0;
}

.OurTeam_icon__2XlZu > img {
	width: 100%;
	height: 100%;
	background: palevioletred;
	border: min(3vw, 15px) solid var(--divider-grey);
	border-radius: 50%;
	box-shadow: 0px 2px 4px #00000080;
}

.OurTeam_memberCard__2INu8 .OurTeam_icon__2XlZu img:hover {
	border-color: var(--orange);
}

.OurTeam_memberCard__2INu8 .OurTeam_icon__2XlZu.OurTeam_active__3OTSQ img {
	border-color: var(--orange);
}

.OurTeam_memberCard__2INu8 .OurTeam_position__3JJEi {
	font-family: 'caviarDreams', 'Arial', sans-serif;
	font-size: 20px;
	color: var(--medium-grey);
	margin-top: 15px;
}

.OurTeam_lineToCard__1HW6Q {
	position: absolute;
	background-color: var(--divider-grey);
}

/* !Middle Connecting line between the cards */

.OurTeam_middleConnectingLine__6L9Ms {
	position: absolute;
	height: 90%;
	top: 5%;
	width: 20px;
	background-color: var(--divider-grey);
	left: 50%;
	-webkit-transform: translate(-50%);
	        transform: translate(-50%);
}

/* !Member info on the right */

.OurTeam_memberInfoAndDescriptions__1iKdk {
	width: 50%;
	padding: 0px 60px;
}

/* !Member info on the right of the page */

.OurTeam_memberInfo__2ZOvT {
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
}

.OurTeam_memberImage__LKTsY {
	height: 250px;
}

.OurTeam_memberImage__LKTsY img {
	height: 100%;
	width: 100%;
	object-fit: contain;
}

/* !Member status and name are disabled on desktop */

.OurTeam_memberStatus__3Bee2,
.OurTeam_memberName__2239v {
	display: none;
}

/* !Description text */

.OurTeam_descriptions__2naog {
	position: relative;
}

.OurTeam_descriptionText__jVqnw {
	font-size: clamp(20px, 1.5vw, 25px);
	color: var(--divider-grey);
	margin: 70px 0;
	padding: 0 70px;
}

/* !Socials */

.OurTeam_socialLinks__2CMrl {
	position: fixed;
	top: 126px;
	right: 0;
	padding: 21px 14px;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	background-color: var(--orange);
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
}

.OurTeam_socialLinks__2CMrl .OurTeam_icon__2XlZu:not(:first-of-type):not(:last-of-type) {
	padding: 38px 0;
}

.OurTeam_socialLinks__2CMrl .OurTeam_icon__2XlZu > a {
	display: -webkit-flex;
	display: flex;
	width: 17px;
	height: 17px;
}

.OurTeam_socialLinks__2CMrl .OurTeam_icon__2XlZu img {
	width: 100%;
	height: 100%;
}

.OurTeam_hide__2eo8X {
	display: none;
}

/* !Changing the padding for descriptions */

@media screen and (max-width: 1350px) {
	.OurTeam_descriptionText__jVqnw {
		padding: 0 30px;
	}
}

/* !Tablet and below screens */

@media screen and (max-width: 1100px) {
	.OurTeam_pageCutout__2mYbK {
		display: none;
	}

	.OurTeam_teamMembers__SHQg5 {
		width: 100%;
	}

	.OurTeam_memberInfoAndDescriptions__1iKdk {
		width: 100%;
		padding: 0px 40px;
	}

	.OurTeam_memberImage__LKTsY {
		width: 50%;
	}

	.OurTeam_memberName__2239v {
		display: block;
		font-size: clamp(20px, 7.2vw, 32px);
		color: var(--dark-grey);
		font-family: 'bradHITC', cursive;
		font-weight: bold;
		width: 50%;
		padding-right: 40px;
	}

	.OurTeam_memberStatus__3Bee2 {
		display: block;
		margin-top: 100px;
		padding: 0 30px;
	}

	.OurTeam_memberFounder__24gkz {
		font-size: clamp(21px, 5vw, 26px);
		color: var(--dark-grey);
		font-family: 'bradHITC', cursive;
		font-weight: bold;
	}

	.OurTeam_memberPosition__Mbfm- {
		font-family: 'caviarDreams', 'Arial', sans-serif;
		font-size: 16px;
	}

	.OurTeam_descriptionsCutout__3zMoy {
		position: absolute;
		width: 120vw;
		height: 100%;
		left: -50px;
		-webkit-transform: rotate(-3deg) translateY(30px);
		        transform: rotate(-3deg) translateY(30px);
		box-shadow: 0px -6px 12px var(--light-divider-grey);
	}

	.OurTeam_descriptionText__jVqnw:first-of-type {
		margin-top: 40px;
	}

	.OurTeam_hideView__2Ub1b {
		display: none;
	}
}

/* !Small tablet and mobile only */

@media screen and (max-width: 575px) {
	.OurTeam_outerContainer__1kw_1 {
		min-height: calc(100vh - 50px);
	}

	.OurTeam_teamMembers__SHQg5 {
		margin-top: 60px;
		margin-bottom: 50px;
		/* min-height: calc(100vh - 50px); */
	}

	.OurTeam_memberCard__2INu8 .OurTeam_title__1bb5X {
		font-size: max(20px, 6vw);
	}

	.OurTeam_memberCard__2INu8 .OurTeam_name__3ckeg {
		font-size: max(18px, 5vw);
	}

	.OurTeam_memberCard__2INu8 .OurTeam_icon__2XlZu {
		width: 30vw;
		height: 30vw;
	}

	.OurTeam_memberInfoAndDescriptions__1iKdk {
		margin-top: 90px;
		width: 100%;
		padding: 0px 16px;
	}

	.OurTeam_memberStatus__3Bee2 {
		padding: 0 16px;
	}

	.OurTeam_descriptionText__jVqnw {
		padding: 0 16px;
	}

	.OurTeam_memberImage__LKTsY {
		height: 200px;
		width: 50%;
	}
}

/* General swiper styles applied to all swipers used throughout the project */

.swiper-container {
	position: static;
}

ul.swiper-wrapper {
	padding-left: 0;
	margin: 0;
	padding-bottom: 18px;
}

li.swiper-slide {
	list-style-type: none;
}

.swiper-container-thumbs li.swiper-slide > div > div {
	opacity: 0.7;
}

.swiper-container-thumbs li.swiper-slide-thumb-active > div > div {
	opacity: 1;
}

li.swiper-slide-thumb-active > p {
	color: black !important;
}

.swiper-button-prev,
.swiper-button-next {
	color: black;
}

.swiper-button-prev {
	position: absolute;
	top: 40%;
	left: -60px;
}

.swiper-button-next {
	position: absolute;
	top: 40%;
	right: -60px;
}

.swiper-button-next:after,
.swiper-container-rtl .swiper-button-prev:after {
	font-size: 22px;
	font-weight: 900;
}

.swiper-button-prev:after,
.swiper-container-rtl .swiper-button-next:after {
	font-size: 22px;
	font-weight: 900;
}

.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
	margin: 4px 4px;
}

.swiper-pagination.swiper-pagination-bullets {
	top: 170px;
	right: 120px;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
}

.swiper-pagination-bullet {
	width: 10px;
	height: 10px;
	background: transparent;
	border: 2px solid black;
	opacity: 0.3;
}

.swiper-pagination-bullet-active {
	background-color: var(--orange);
	opacity: 1;
	border: 2px solid var(--orange);
}

.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-container-horizontal > .swiper-pagination-bullets {
	bottom: auto;
	left: auto;
	width: auto;
}

@media all and (max-width: 1279px) {
	ul.swiper-wrapper {
		padding-bottom: 0;
	}
}

/*** CSS VARIABLES ***/
:root {
	/* Font Colours */
	--light-grey: #adadad;
	--medium-grey: #646464;
	--dark-grey: #363636;
	--dark: #242424;

	--divider-grey: #979797;
	--light-divider-grey: rgba(151, 151, 151, 0.15);

	/* Accent Colour*/
	--orange: #e4a500;
}

/*** SCREEN SIZES ***/
/* 
  mobile: 320px to 575px
  phablet: 576px to 767px
  tablet: 768px to 991px
  laptop: 992px to 1199px
  desktop and above: 1200px+
*/

/*** FONTS ***/
@font-face {
	src: url(/static/media/CaviarDreams.3670aa49.ttf);
	font-family: 'caviarDreams';
	font-weight: normal;
	font-style: normal;
}
@font-face {
	src: url(/static/media/CaviarDreams_Bold.65ab651c.ttf);
	font-family: 'caviarDreams';
	font-weight: bold;
	font-style: normal;
}
@font-face {
	src: url(/static/media/CaviarDreams_Italic.e67f5c38.ttf);
	font-family: 'caviarDreams';
	font-weight: normal;
	font-style: italic;
}
@font-face {
	src: url(/static/media/Roboto-Condensed.8f7b8dc7.ttf);
	font-family: 'roboto';
	font-weight: normal;
	font-stretch: condensed;
}
@font-face {
	src: url(/static/media/Roboto-Bold.36b5bab5.ttf);
	font-family: 'roboto';
	font-weight: bold;
	font-stretch: normal;
}
@font-face {
	src: url(/static/media/Roboto-Regular.5673da52.ttf);
	font-family: 'roboto';
	font-weight: normal;
	font-stretch: normal;
}
@font-face {
	src: url(/static/media/BRADHITC.0252223e.TTF);
	font-family: 'bradHITC';
}
@font-face {
	src: url(/static/media/Dosis-Regular.808d3433.ttf);
	font-family: 'dosisRegular';
}
@font-face {
	src: url(/static/media/Dosis-SemiBold.050d8579.ttf);
	font-family: 'dosisSemiBold';
}
@font-face {
	src: url(/static/media/Handlee-Regular.27f813ca.ttf);
	font-family: 'handleeRegular';
}
/* @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@700&display=swap'); */

