.section {
	display: flex;
	flex-direction: column;
	margin: 20px 0px;
}

.accordion {
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: transparent;
	padding: 5px;
}

.accordion:hover {
	cursor: pointer;
}

.accordion > .title {
	font-size: 30px;
	margin: 0;
	font-family: 'bradHITC', cursive;
	text-align: left;
	color: var(--medium-grey);
}

.title:hover {
	color: var(--dark-grey);
}

.accordion > .icon {
	width: 13px;
	height: 13px;
}

.content {
	display: flex;
	justify-content: center;
	overflow: hidden;
	transition: max-height 0.6s ease-in-out;
}
