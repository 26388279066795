/*** CSS VARIABLES ***/
:root {
	/* Font Colours */
	--light-grey: #adadad;
	--medium-grey: #646464;
	--dark-grey: #363636;
	--dark: #242424;

	--divider-grey: #979797;
	--light-divider-grey: rgba(151, 151, 151, 0.15);

	/* Accent Colour*/
	--orange: #e4a500;
}

/*** SCREEN SIZES ***/
/* 
  mobile: 320px to 575px
  phablet: 576px to 767px
  tablet: 768px to 991px
  laptop: 992px to 1199px
  desktop and above: 1200px+
*/

/*** FONTS ***/
@font-face {
	src: url('./assets/fonts/CaviarDreams.ttf');
	font-family: 'caviarDreams';
	font-weight: normal;
	font-style: normal;
}
@font-face {
	src: url('./assets/fonts/CaviarDreams_Bold.ttf');
	font-family: 'caviarDreams';
	font-weight: bold;
	font-style: normal;
}
@font-face {
	src: url('./assets/fonts/CaviarDreams_Italic.ttf');
	font-family: 'caviarDreams';
	font-weight: normal;
	font-style: italic;
}
@font-face {
	src: url('./assets/fonts/Roboto-Condensed.ttf');
	font-family: 'roboto';
	font-weight: normal;
	font-stretch: condensed;
}
@font-face {
	src: url('./assets/fonts/Roboto-Bold.ttf');
	font-family: 'roboto';
	font-weight: bold;
	font-stretch: normal;
}
@font-face {
	src: url('./assets/fonts/Roboto-Regular.ttf');
	font-family: 'roboto';
	font-weight: normal;
	font-stretch: normal;
}
@font-face {
	src: url('./assets/fonts/BRADHITC.TTF');
	font-family: 'bradHITC';
}
@font-face {
	src: url('./assets/fonts/Dosis-Regular.ttf');
	font-family: 'dosisRegular';
}
@font-face {
	src: url('./assets/fonts/Dosis-SemiBold.ttf');
	font-family: 'dosisSemiBold';
}
@font-face {
	src: url('./assets/fonts/Handlee-Regular.ttf');
	font-family: 'handleeRegular';
}
@import url('https://fonts.googleapis.com/css2?family=Handlee&display=swap');
/* @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@700&display=swap'); */
