/* !Wrapper Container for page */

.outerContainer {
	min-height: calc(100vh - 126px); /* Removed this for layout */
	display: flex;
}

/* !Page Cutout */

.pageCutout {
	position: fixed;
	top: -20%;
	bottom: -20%;
	left: -200px;
	width: calc(50% + 220px);
	transform: rotate(-4deg);
	box-shadow: 6px 0px 12px var(--light-divider-grey);
}

/* !Holds the buttons and circles for the team memebers */

.teamMembers {
	transform: rotate(-4deg);
	transform-origin: center;
	width: 50%;
	position: relative;
	display: grid;
	grid-template-columns: 50% 50%;
	margin-top: -126px;
	min-height: calc(100vh - 126px);
}

/* !Left and right columns */

.leftColumn,
.rightColumn {
	display: flex;
	flex-direction: column;
}

.leftColumn {
	justify-content: center;
}

.rightColumn {
	justify-content: space-between;
}

/* !Each column has a member card that has a bunch of info */

.memberCard {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 30px 15px;
}

.memberCard .title {
	font-family: 'bradHITC', cursive;
	font-weight: bold;
	font-size: 30px;
	line-height: 38px;
	color: #000000;
	text-align: center;
}

.memberCard .name {
	font-family: 'bradHITC', cursive;
	font-size: 25px;
	color: var(--medium-grey);
	margin-bottom: 8px;
	text-align: center;
}

.memberCard .icon {
	width: 191px;
	height: 191px;
	border: none !important;
	outline: none !important;
	padding: 0;
}

.icon > img {
	width: 100%;
	height: 100%;
	background: palevioletred;
	border: min(3vw, 15px) solid var(--divider-grey);
	border-radius: 50%;
	box-shadow: 0px 2px 4px #00000080;
}

.memberCard .icon img:hover {
	border-color: var(--orange);
}

.memberCard .icon.active img {
	border-color: var(--orange);
}

.memberCard .position {
	font-family: 'caviarDreams', 'Arial', sans-serif;
	font-size: 20px;
	color: var(--medium-grey);
	margin-top: 15px;
}

.lineToCard {
	position: absolute;
	background-color: var(--divider-grey);
}

/* !Middle Connecting line between the cards */

.middleConnectingLine {
	position: absolute;
	height: 90%;
	top: 5%;
	width: 20px;
	background-color: var(--divider-grey);
	left: 50%;
	transform: translate(-50%);
}

/* !Member info on the right */

.memberInfoAndDescriptions {
	width: 50%;
	padding: 0px 60px;
}

/* !Member info on the right of the page */

.memberInfo {
	display: flex;
	align-items: center;
}

.memberImage {
	height: 250px;
}

.memberImage img {
	height: 100%;
	width: 100%;
	object-fit: contain;
}

/* !Member status and name are disabled on desktop */

.memberStatus,
.memberName {
	display: none;
}

/* !Description text */

.descriptions {
	position: relative;
}

.descriptionText {
	font-size: clamp(20px, 1.5vw, 25px);
	color: var(--divider-grey);
	margin: 70px 0;
	padding: 0 70px;
}

/* !Socials */

.socialLinks {
	position: fixed;
	top: 126px;
	right: 0;
	padding: 21px 14px;
	display: flex;
	flex-direction: column;
	background-color: var(--orange);
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
}

.socialLinks .icon:not(:first-of-type):not(:last-of-type) {
	padding: 38px 0;
}

.socialLinks .icon > a {
	display: flex;
	width: 17px;
	height: 17px;
}

.socialLinks .icon img {
	width: 100%;
	height: 100%;
}

.hide {
	display: none;
}

/* !Changing the padding for descriptions */

@media screen and (max-width: 1350px) {
	.descriptionText {
		padding: 0 30px;
	}
}

/* !Tablet and below screens */

@media screen and (max-width: 1100px) {
	.pageCutout {
		display: none;
	}

	.teamMembers {
		width: 100%;
	}

	.memberInfoAndDescriptions {
		width: 100%;
		padding: 0px 40px;
	}

	.memberImage {
		width: 50%;
	}

	.memberName {
		display: block;
		font-size: clamp(20px, 7.2vw, 32px);
		color: var(--dark-grey);
		font-family: 'bradHITC', cursive;
		font-weight: bold;
		width: 50%;
		padding-right: 40px;
	}

	.memberStatus {
		display: block;
		margin-top: 100px;
		padding: 0 30px;
	}

	.memberFounder {
		font-size: clamp(21px, 5vw, 26px);
		color: var(--dark-grey);
		font-family: 'bradHITC', cursive;
		font-weight: bold;
	}

	.memberPosition {
		font-family: 'caviarDreams', 'Arial', sans-serif;
		font-size: 16px;
	}

	.descriptionsCutout {
		position: absolute;
		width: 120vw;
		height: 100%;
		left: -50px;
		transform: rotate(-3deg) translateY(30px);
		box-shadow: 0px -6px 12px var(--light-divider-grey);
	}

	.descriptionText:first-of-type {
		margin-top: 40px;
	}

	.hideView {
		display: none;
	}
}

/* !Small tablet and mobile only */

@media screen and (max-width: 575px) {
	.outerContainer {
		min-height: calc(100vh - 50px);
	}

	.teamMembers {
		margin-top: 60px;
		margin-bottom: 50px;
		/* min-height: calc(100vh - 50px); */
	}

	.memberCard .title {
		font-size: max(20px, 6vw);
	}

	.memberCard .name {
		font-size: max(18px, 5vw);
	}

	.memberCard .icon {
		width: 30vw;
		height: 30vw;
	}

	.memberInfoAndDescriptions {
		margin-top: 90px;
		width: 100%;
		padding: 0px 16px;
	}

	.memberStatus {
		padding: 0 16px;
	}

	.descriptionText {
		padding: 0 16px;
	}

	.memberImage {
		height: 200px;
		width: 50%;
	}
}
