.scrollableWrapper {
	display: flex;
	padding: 0 20px;
	padding-right: 0;
}

.scrollableViewport {
	display: flex;
	width: 100%;
	overflow: hidden;
	position: relative;
	overscroll-behavior: contain;
}

.scrollableContent {
	position: absolute;
	top: 0px;
	bottom: 0;
	left: 0;
	right: -40px;
	padding-right: 40px;
	overflow-x: hidden;
	overflow-y: scroll;
}

.scrollableContent.noScroll {
	position: static;
	padding-right: unset;
	overflow-x: unset;
	overflow-y: unset;
}

.scrollbar {
	display: flex;
	position: relative;
	right: 0;
	width: 20px;
	margin: 20px 0;
}

.scrollbar.hidden {
	display: none;
}

.scrollbarTrack {
	position: absolute;
	width: 1px;
	height: 100%;
	background-color: #ccc;
	left: 50%;
	transform: translateX(-50%);
}

.scrollbarThumb {
	position: absolute;
	height: 30px;
	width: 8px;
	left: 50%;
	background-color: white;
	transform: translateX(-50%);
	border-radius: 50px;
	box-shadow: 0px 2px 6px #ccc;
}
