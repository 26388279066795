/* General swiper styles applied to all swipers used throughout the project */

.swiper-container {
	position: static;
}

ul.swiper-wrapper {
	padding-left: 0;
	margin: 0;
	padding-bottom: 18px;
}

li.swiper-slide {
	list-style-type: none;
}

.swiper-container-thumbs li.swiper-slide > div > div {
	opacity: 0.7;
}

.swiper-container-thumbs li.swiper-slide-thumb-active > div > div {
	opacity: 1;
}

li.swiper-slide-thumb-active > p {
	color: black !important;
}

.swiper-button-prev,
.swiper-button-next {
	color: black;
}

.swiper-button-prev {
	position: absolute;
	top: 40%;
	left: -60px;
}

.swiper-button-next {
	position: absolute;
	top: 40%;
	right: -60px;
}

.swiper-button-next:after,
.swiper-container-rtl .swiper-button-prev:after {
	font-size: 22px;
	font-weight: 900;
}

.swiper-button-prev:after,
.swiper-container-rtl .swiper-button-next:after {
	font-size: 22px;
	font-weight: 900;
}

.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
	margin: 4px 4px;
}

.swiper-pagination.swiper-pagination-bullets {
	top: 170px;
	right: 120px;
	display: flex;
	flex-direction: column;
}

.swiper-pagination-bullet {
	width: 10px;
	height: 10px;
	background: transparent;
	border: 2px solid black;
	opacity: 0.3;
}

.swiper-pagination-bullet-active {
	background-color: var(--orange);
	opacity: 1;
	border: 2px solid var(--orange);
}

.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-container-horizontal > .swiper-pagination-bullets {
	bottom: auto;
	left: auto;
	width: auto;
}

@media all and (max-width: 1279px) {
	ul.swiper-wrapper {
		padding-bottom: 0;
	}
}
