.company-intro-container .swiper-wrapper {
	margin-bottom: 0;
}

.company-intro-container .swiper-pagination.swiper-pagination-bullets {
	flex-direction: row;
	top: 500px;
	right: 50%;
	transform: translate(50%);
}

.company-intro-container .swiper-slide {
	padding: 0 25px;
}

@media all and (min-width: 576px) {
	.company-intro-container .swiper-container {
		padding-top: 15px;
		overflow: visible;
	}
	.company-intro-container .swiper-pagination.swiper-pagination-bullets {
		top: 370px;
	}
}

@media all and (max-width: 575px) {
	.company-intro-container .swiper-slide {
		padding: 0;
	}
}

@media all and (min-height: 900px) and (min-width: 576px) {
	.company-intro-container .swiper-pagination.swiper-pagination-bullets {
		top: 385px;
	}
}
