.item-carousel-desktop .swiper-container .swiper-pagination.swiper-pagination-bullets {
	display: none;
}

@media all and (max-width: 1279px) {
	.item-carousel-desktop .swiper-container .swiper-pagination.swiper-pagination-bullets {
		display: flex;
		flex-direction: row;
		top: unset;
		bottom: -30px;
		right: 50%;
		transform: translate(50%);
	}
}

@media all and (max-width: 575px) {
	.item-carousel-desktop .swiper-container .swiper-pagination .swiper-pagination-bullet {
		width: 8px;
		height: 8px;
	}
}
