.checkboxContainer {
	display: block;
	width: 100%;
	margin: 40px 0px;
}

.checkboxContainer:first-of-type {
	margin-top: 0;
}

.checkboxContainer:last-of-type {
	margin-bottom: 0;
}
.checkmark {
	display: flex;
	align-items: center;
}

.checkmark > img {
	cursor: pointer;
}

.checkmark > .text {
	font-size: 20px;
	padding-left: 30px;
	color: var(--medium-grey);
	font-family: 'caviarDreams', 'Arial', sans-serif;
}

@media screen and (max-width: 575px) {
	.checkmark > img {
		width: 25px;
	}

	.checkmark > .text {
		font-size: 18px;
	}
}
