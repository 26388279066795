.companyIntroContainer {
	position: relative;
	font-family: 'handleeRegular', cursive;
	padding: 0 100px;
	width: 100vw;
}

.companyIntroContainer .intro {
	position: absolute;
	top: 0;
	left: 90px;
}

.companyIntroContainer .intro h1 {
	font-size: 20px;
	text-transform: uppercase;
}

.smallPostit {
	height: 45px;
	width: 225px;
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	right: -12px;
	border-radius: 44px 0 0 0;
	background: linear-gradient(150.81deg, #be920e 0%, #e4a500 100%);
	background-repeat: no-repeat;
	background-size: 225px 48px;
	font-size: 14px;
	font-weight: 700;
	text-transform: uppercase;
}

.smallPostit.first {
	top: 85px;
}

.smallPostit.second {
	top: 150px;
}

.companyIntroContainer .sketch {
	height: 450px;
	margin-right: auto;
	margin-left: auto;
}

.companyIntroContainer .sketch img {
	height: 100%;
	width: 100%;
	object-fit: contain;
}

.companyIntroContainer .content {
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	max-width: 1500px;
	margin-right: auto;
	margin-left: auto;
}

.largePostit {
	height: 322px;
	width: 322px;
	background-repeat: no-repeat;
	background-size: 322px;
	padding: 40px;
	margin-right: auto;
	margin-left: auto;
}

.largePostit h2 {
	padding-left: 15px;
}

.largePostit .postitText {
	font-size: 18px;
	letter-spacing: 0.7px;
	color: white;
	line-height: 28px;
	padding-top: 8px;
	margin-bottom: 55px;
}

.largePostit a {
	font-family: 'roboto', 'Helvetica', sans-serif;
	font-weight: bold;
	color: #000000;
	font-size: 16px;
	letter-spacing: 0.64px;
	text-align: right;
	margin-right: 12px;
}

.companyIntroContainer .values * {
	transform: rotate(2deg);
}

.companyIntroContainer .mission * {
	transform: rotate(2deg);
}

.companyIntroContainer .vision * {
	transform: rotate(-2deg);
}

@media all and (min-width: 2000px) {
	.companyIntroContainer {
		min-width: 1500px;
	}
}

@media all and (max-width: 1500px) {
	.companyIntroContainer .intro h1 {
		font-size: 18px;
	}

	.smallPostit {
		height: 40px;
		width: 220px;
		right: -19px;
		background-size: 220px 43px;
		font-size: 12px;
	}

	.smallPostit.second {
		top: 130px;
	}

	.largePostit {
		height: 275px;
		width: 275px;
		background-size: 275px;
		padding: 30px;
	}

	.largePostit h2 {
		font-size: 22px;
	}

	.largePostit .postitText {
		font-size: 16px;
		padding-top: 0px;
		margin-bottom: 40px;
	}

	.largePostit a {
		font-size: 14px;
	}
}

@media all and (max-width: 1124px) {
	.companyIntroContainer .intro {
		left: 70px;
	}

	.companyIntroContainer .intro h1 {
		font-size: 17px;
	}

	.largePostit {
		height: 260px;
		width: 260px;
		background-size: 260px;
		padding: 25px;
	}

	.largePostit h2 {
		padding-left: 10px;
		font-size: 20px;
	}

	.largePostit .postitText {
		font-size: 14px;
	}

	.largePostit a {
		font-size: 13px;
	}
}

@media all and (max-width: 1040px) {
	.largePostit {
		height: 250px;
		width: 250px;
		background-size: 250px;
		padding: 25px;
	}

	.largePostit .postitText {
		margin-bottom: 35px;
	}
}

@media all and (max-width: 991px) {
	.companyIntroContainer {
		padding: 0 50px;
	}

	.smallPostit.first {
		top: 265px;
		right: -20px;
	}

	.smallPostit.second {
		top: 248px;
		left: -20px;
		transform: scaleX(-1);
	}

	.smallPostit.second .squadText {
		transform: scaleX(-1);
	}

	.companyIntroContainer .sketch {
		height: 275px;
		margin-bottom: 55px;
	}

	.companyIntroContainer .intro {
		position: relative;
		top: auto;
		left: auto;
		text-align: center;
	}

	.companyIntroContainer .carousel {
		width: 400px;
		margin-top: 20px;
		margin-right: auto;
		margin-left: auto;
	}

	.largePostit {
		height: 322px;
		width: 322px;
		background-size: 322px;
		padding: 40px;
	}

	.largePostit h2 {
		padding-left: 15px;
	}

	.largePostit .postitText {
		font-size: 18px;
		letter-spacing: 0.7px;
		color: white;
		line-height: 28px;
		padding-top: 8px;
		margin-bottom: 70px;
	}

	.largePostit a {
		font-size: 16px;
		letter-spacing: 0.64px;
		margin-right: 12px;
	}
}

@media all and (max-width: 575px) {
	.companyIntroContainer {
		padding: 100px 50px 50px;
	}

	.companyIntroContainer .sketch {
		height: 250px;
		margin-bottom: 90px;
	}

	.smallPostit.first {
		top: 360px;
	}

	.smallPostit.second {
		top: 370px;
	}

	.companyIntroContainer .mobileSeparator {
		position: absolute;
		left: 0;
		width: 100%;
		height: 11px;
		transform: scaleY(-1);
		background: linear-gradient(180deg, rgba(238, 238, 238, 0) 0%, rgba(216, 216, 216, 0.54) 100%);
	}

	.companyIntroContainer .carousel {
		margin-top: 50px;
	}

	.companyIntroContainer .carousel h2 {
		font-size: 24px;
		padding-left: 10px;
	}

	.companyIntroContainer .carousel .postitText {
		padding-top: 8px;
		padding-left: 25px;
		margin-bottom: 50px;
		font-size: 18px;
		letter-spacing: 0.58px;
		line-height: 35px;
	}

	.companyIntroContainer .carousel a {
		font-family: 'roboto', 'Helvetica', sans-serif;
		font-weight: bold;
		color: var(--orange);
		font-size: 16px;
		letter-spacing: 0.64px;
		text-align: right;
		margin-right: 12px;
	}
}

@media all and (max-width: 500px) {
	.companyIntroContainer .carousel {
		width: 350px;
	}
}

@media all and (max-width: 425px) {
	.companyIntroContainer {
		padding: 100px 25px 50px;
	}

	.companyIntroContainer .carousel {
		width: 300px;
	}
}

@media all and (max-width: 375px) {
	.smallPostit.first {
		right: -40px;
	}

	.smallPostit.second {
		left: -40px;
	}
}

@media all and (max-width: 350px) {
	.companyIntroContainer .carousel {
		width: 250px;
	}
}

@media all and (min-height: 900px) {
	.companyIntroContainer .carousel {
		margin-top: 55px;
	}
}
